<script setup>
import { ref } from 'vue'
import Payment from '../payment/payment.vue'

const activeName = ref('payment')
</script>

<template>
  <div class="electricity">
    <el-tabs v-model="activeName">
      <!-- <el-tab-pane label="实时电费" name="demo"></el-tab-pane> -->
      <el-tab-pane label="用户交费记录" name="payment"> </el-tab-pane>
    </el-tabs>

    <Transition appear mode="out-in" name="el-fade-in-linear">
      <!-- <div v-if="activeName === 'demo'"></div> -->
      <Payment></Payment>
    </Transition>
  </div>
</template>

<style>
.electricity .el-tabs__header {
  padding-left: 36px;
  padding-right: 36px;
  height: 70px;
  display: flex;
  align-items: center;
  margin: 0;
}
</style>
