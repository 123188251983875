import {api} from "@/api";

// 获取数据监测列表
export const getList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/equipment/getDataMonitorPageV2',
        method: 'POST',
        data: data
    })
}


// 获取数据监测列表--本地
export const getListLocal = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/equipmentLocal/getDataMonitorPage',
        method: 'POST',
        data: data
    })
}
// 获取数据监测列表--本地--直连设备
export const getListLocalLink = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/equipmentLocal/getDataMonitorPageWithDirect',
        method: 'POST',
        data: data
    })
}


// 保存分组
export const saveWithEquipmentApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/productShowDict/saveWithEquipment',
        method: 'POST',
        data: data
    })
}

// 保存分组--直连设备
export const saveWithEquipmentLinkApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/productShowDict/saveWithDirect',
        method: 'POST',
        data: data
    })
}


