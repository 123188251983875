<template>
  <div class="powerStationItemDevice">
    <div class="headBox">
      <el-button :icon="Plus" type="primary" @click="addClick">新建设备</el-button>
      <div class="rightOpera">
        <p :class="activeLi===1?'active':''" @click="activeClick(1)">卡片</p>
        <p :class="activeLi===2?'active':''" @click="activeClick(2)">列表</p>
      </div>
    </div>
    <div v-if="activeLi===1" class="contentBox">
      <div v-for="(item,index) in dataList" :key="index"
           :style="{width:itemNum===3?'calc(33.333% - 38.66666px)':'calc(25% - 39.5px)',marginRight:(index+1)%itemNum===0?'0':'10px'}"
           class="contItem">
        <div class="head">
          <p class="name">{{ item.name }}</p>
          <el-icon style="cursor: pointer" @click="lookEquipmentDetail(item.id)">
            <ArrowRight/>
          </el-icon>
        </div>
        <div class="centerBox">
          <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/powerStation/dataItem.png">
          <div class="statusBox">
            <p>状态</p>
            <p :class="item.status==1?'online':'offline'">{{ item.status == 1 ? '在线' : '离线' }}</p>
          </div>
          <div class="warnBox">
            <p>告警</p>
            <p>{{ item.warnNum }}</p>
          </div>
        </div>
        <div class="mainBox">
          <div class="mainItem">
            <p>
              <span class="val">{{ item.val1 }}</span>
              <span class="unit">A</span>
            </p>
            <p>电流</p>
          </div>
          <div class="mainItem">
            <p>
              <span class="val">{{ item.val2 }}</span>
              <span class="unit">V</span>
            </p>
            <p>电压(V)</p>
          </div>
          <div class="mainItem">
            <p>
              <span class="val">{{ item.val3 }}</span>
              <span class="unit">kwh</span>
            </p>
            <p>交流侧总有功率</p>
          </div>
          <div class="mainItem">
            <p>
              <span class="val">{{ item.val4 }}</span>
              <span class="unit">kwh</span>
            </p>
            <p>直流输出功率</p>
          </div>
        </div>
      </div>
    </div>
    <div v-if="activeLi===2" class="contentBoxCopy">
      <!--搜索-->
      <div class="searchBox">
        <div style="display: flex; width: calc(100% - 136px);flex-wrap: wrap">
          <div class="liBox">
            <span>电站名称:</span>
            <el-input v-model="name" clearable placeholder="请输入电站名称"></el-input>
          </div>
          <div class="liBox">
            <span>产品型号:</span>
            <el-input v-model="productModule" clearable placeholder="请输入产品型号"></el-input>
          </div>
          <div class="liBox">
            <span>电站地址:</span>
            <el-input v-model="address" clearable placeholder="请输入电站地址"></el-input>
          </div>
          <div class="liBox">
            <span>状态:</span>
            <el-select v-model="status" placeholder="请选择状态">
              <el-option>全部</el-option>
            </el-select>
          </div>
          <div class="liBox">
            <span>处理进度:</span>
            <el-select v-model="progress">
              <el-option>已处理</el-option>
              <el-option>未处理</el-option>
            </el-select>
          </div>
          <div class="liBox">
            <span>设备类型:</span>
            <el-select v-model="type">
              <el-option>网管设备</el-option>
              <el-option>直连设备</el-option>
            </el-select>
          </div>
        </div>
        <div class="liBox" style="width: 136px;margin-right: 24px;align-items: start">
          <el-button class="searchButton" type="primary" @click="searchFun">查询</el-button>
          <el-button class="searchButton" @click="resetFun">重置</el-button>
        </div>
      </div>
      <!--数据列表内容-->
      <div class="contain">
        <!--列表-->
        <el-table v-loading="loading" :data="tableData" style="width: 100%;">
          <template #empty>
            <el-empty class="emptyClass" description="暂无数据信息"
                      image="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
              <p>您可以在此页面新建设备信息</p>
            </el-empty>
          </template>
          <el-table-column label="名称" prop="name"></el-table-column>
          <el-table-column label="产品型号" prop="memberName"/>
          <el-table-column label="协议类型" prop="customerName"></el-table-column>
          <el-table-column label="电站地址" prop="beforeContent">
            <template #default="scope">
              <p class="cont">{{ scope.row.beforeContent }}</p>
              <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/powerStation/positionIcon.png"
                   style="width: 14px;height: 14px;">
            </template>
          </el-table-column>
          <el-table-column label="创建人" prop="createPeople">
            <template #default="scope">
              <div class="createPeopleBox">
                <img alt="" src="../../../../assets/img/user.png" style="width: 24px;height: 24px;">
                <span>{{ scope.row.createPeople }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="状态" prop="status">
            <template #default="scope">
              <div :class="scope.row.status == 1?'online':'offline'" class="statusPox">
                <p class="dotOnClass"></p>
                <p>{{ scope.row.status == 1 ? '在线' : '离线' }}</p>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="报警数量" prop="customerName"></el-table-column>
          <el-table-column label="处理进度" prop="progress">
            <template #default="scope">
              <p class="cont">{{ scope.row.progress }}</p>
            </template>
          </el-table-column>
          <el-table-column label="网关" prop="customerName"></el-table-column>
          <el-table-column label="设备类型" prop="customerName"></el-table-column>
          <el-table-column fixed="right" label="操作" width="150px">
            <template #default="scope">
              <div class="opacity">
                <el-tooltip content="编辑">
                  <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/powerStation/operaIcon8.png"
                       @click="addClick(scope.row.id)">
                </el-tooltip>
                <el-tooltip content="设备数据">
                  <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/powerStation/operaIcon3.png"
                       @click="lookEquipmentDetail(scope.row.id,'1')">
                </el-tooltip>
                <el-popover popper-class="moreOpera2" trigger="hover">
                  <div class="listPox">
                    <p @click="lookEquipmentDetail(scope.row.id,'2')">报警</p>
                    <p @click="delClick(scope.row.id)">删除</p>
                  </div>
                  <template #reference>
                    <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/powerStation/operaIcon9.png">
                  </template>
                </el-popover>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pag">
          <el-pagination v-show="tableData.length > 0" v-model:page-size="pageSize" :page-sizes="[10, 20, 50, 100]"
                         :total="total" background
                         layout="total,sizes,prev, pager, next" @size-change="handleSizeChange"
                         @current-change="pageChange"/>
        </div>
      </div>
    </div>
    <!--编辑设备弹窗-->
    <el-dialog v-model="dialogFormVisible" :close-on-click-modal="false" title="编辑设备" width="460">
      <div class="liPox">
        <span>设备名称：</span>
        <el-input v-model="formData.equipmentName"></el-input>
      </div>
      <div class="liPox">
        <span>关联设备：</span>
        <el-select v-model="formData.associationEquipment" :teleported="false" class="planBox">
          <el-option v-for="(item,index) in equipmentList" :key="index" :label="item.name"
                     :value="item.value"></el-option>
        </el-select>
      </div>
      <template #footer>
				<span class="dialog-footer">
        <el-button class="cancle" @click="dialogFormVisible=false">取消</el-button>
          <el-button class="confrim" type="primary" @click="save()">保存</el-button>
      </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {h, onMounted, reactive, ref} from "vue"
import {Plus, ArrowRight} from '@element-plus/icons-vue'
import {useRouter} from "vue-router/dist/vue-router";
import {ElMessage, ElMessageBox} from "element-plus";

let router = useRouter()
let activeLi = ref(1)
const activeClick = function (idx) {
  activeLi.value = idx
}


// 数据列表
let dataList = ref([
  {
    name: '逆变器1',
    status: 1,
    warnNum: 48,
    val1: 12,
    val2: 12,
    val3: 12,
    val4: 12,
  },
  {
    name: '逆变器1',
    status: 1,
    warnNum: 48,
    val1: 12,
    val2: 12,
    val3: 12,
    val4: 12,
  },
  {
    name: '逆变器1',
    status: 0,
    warnNum: 48,
    val1: 12,
    val2: 12,
    val3: 12,
    val4: 12,
  },
  {
    name: '逆变器1',
    status: 1,
    warnNum: 48,
    val1: 12,
    val2: 12,
    val3: 12,
    val4: 12,
  },
  {
    name: '逆变器1',
    status: 1,
    warnNum: 48,
    val1: 12,
    val2: 12,
    val3: 12,
    val4: 12,
  },
  {
    name: '逆变器1',
    status: 1,
    warnNum: 48,
    val1: 12,
    val2: 12,
    val3: 12,
    val4: 12,
  }
])


// 点击查看设备详情
const lookEquipmentDetail = function (id) {
  router.push({
    path: '/admin/powerStationEquipment',
    query: {
      id: id
    }
  })
}


// 列表-----------------------------------------------------------------
// 分页
let pageNumber = ref(1)
let pageSize = ref(20)
let total = ref()
// 更改每页多少
const handleSizeChange = function (e) {
  pageSize.value = e
  getListFun()
}
// 跳转到某页
const pageChange = function (e) {
  pageNumber.value = e
  getListFun()
}
// 搜索条件
let name = ref()
let productModule = ref()
let status = ref()
let address = ref()
let progress = ref()
let type = ref()
// 参数表格数据
let tableData = ref([{}, {}, {}, {}, {}])
// 获取列表
let loading = ref(false)
const getListFun = function () {
  // loading.value = true
  // getLogApi(qs.stringify({
  //   pageNumber: pageNumber.value,
  //   pageSize: pageSize.value,
  //   name: name.value,
  //   productModule: productModule.value,
  //   status: status.value,
  //   address: address.value,
  //   progress: progress.value,
  //   type: type.value,
  // })).then(res => {
  //   loading.value = false
  //   if (res.data.code === 0) {
  //     tableData.value = res.data.result.list
  //     total.value = Number(res.data.result.total)
  //   } else {
  //     ElMessage({
  //       message: res.data.message,
  //       type: 'error'
  //     })
  //   }
  // }).catch(err => {
  //   console.log(err);
  // })
}
// 查询
const searchFun = function () {
  pageNumber.value = 1
  getListFun()
}
// 重置
const resetFun = function () {
  pageNumber.value = 1
  pageSize.value = 20
  name.value = ''
  productModule.value = null
  status.value = ''
  address.value = ''
  progress.value = ''
  type.value = ''
  getListFun()
}


// 编辑设备-----------------------------------------------------------------
let dialogFormVisible = ref(false) //新增设备弹窗
let equipmentList = ref([]) //关联设备列表
// 新增设备表单数据
let formData = ref({
  equipmentName: '',
  associationEquipment: '',
})
// 点击编辑设备
const addClick = function (id) {
  dialogFormVisible.value = true
}
// 点击保存
const save = function () {

}


// 点击删除-----------------------------------------------------------------------------------------
const delClick = function (id) {
  ElMessageBox.confirm('', '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '删除设备',
    message: h('p', null, [
      h('img', {
        src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
        style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
      }, ''),
      h('span', {style: 'color:#595959;'}, '请确认是否要删除此设备，删除后数据不可恢复'),
    ])
  }).then(() => {
    // delSearchListApi(id).then(res => {
    //   if (res.data.code === 200) {
    //     getSearchListFun()
    //     ElMessage({
    //       type: 'success',
    //       message: '删除成功'
    //     });
    //   } else {
    //     ElMessage({
    //       type: 'error',
    //       message: res.data.message
    //     })
    //   }
    // }).catch(err => {
    //   console.log(err);
    // })
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '已取消删除'
    });
  });
}


// 控制一排三个还是四个
let itemNum = ref(3)
onMounted(() => {
  if (window.innerWidth > 1800) {
    itemNum.value = 4
  } else {
    itemNum.value = 3
  }
  window.addEventListener('resize', () => {
    if (window.innerWidth > 1800) {
      itemNum.value = 4
    } else {
      itemNum.value = 3
    }
  });
});

</script>

<style lang="less" scoped>
.powerStationItemDevice {
  width: 100%;
  height: 100%;

  .headBox {
    width: calc(100% - 32px);
    height: 64px;
    background: #FFFFFF;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.09);
    border-radius: 6px;
    padding: 0 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    .rightOpera {
      width: 134px;
      height: 32px;
      background: rgba(22, 93, 255, 0.1);
      border-radius: 3px;
      display: flex;
      justify-content: center;
      align-items: center;

      p {
        width: 64px;
        height: 26px;
        cursor: pointer;
        color: #999999;
        text-align: center;
        line-height: 26px;
      }

      .active {
        background: #165DFF;
        border-radius: 3px;
        color: #FFFFFF;
      }
    }
  }

  //卡片
  .contentBox {
    height: calc(100% - 74px);
    overflow: auto;
    display: flex;
    flex-wrap: wrap;

    .contItem {
      width: calc(25% - 39.5px);
      height: 258px;
      background: #FFFFFF;
      box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.09);
      border-radius: 6px;
      padding: 16px;
      margin-bottom: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-right: 10px;


      .head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 600;
      }

      .centerBox {
        width: 90%;
        height: 80px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        img {
          width: 80px;
          height: 80px;
        }

        .statusBox, .warnBox {
          height: 80px;
          margin-left: 24px;

          p:nth-child(1) {
            font-weight: 600;
            position: relative;
            margin-top: 10px;
          }

          p:nth-child(1)::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 28px;
            height: 4px;
            background: #3A97FE;
          }
        }

        .statusBox {
          p:nth-child(2) {
            width: 40px;
            height: 24px;
            border-radius: 4px;
            text-align: center;
            line-height: 24px;
            font-size: 12px;
            color: #fff;
            margin-top: 13px;
          }

          .online {
            background: #67C23A;
          }

          .offline {
            background: #999999;
          }
        }

        .warnBox {
          p:nth-child(2) {
            font-weight: 500;
            font-size: 20px;
            color: #FF1111;
            margin-top: 11px;
          }
        }
      }

      .mainBox {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .mainItem {
          width: calc(50% - 5px);
          height: 64px;
          background: rgba(22, 93, 255, 0.05);
          box-shadow: inset 0px 0px 20px 0px rgba(22, 93, 255, 0.1);
          border-radius: 3px;
          text-align: center;
          margin-top: 10px;

          .val {
            font-size: 24px;
            margin-right: 5px;

          }

          p:nth-child(1) {
            font-weight: 600;
          }

          p:nth-child(2) {
            font-size: 12px;
            color: #535F7F;
          }
        }
      }
    }

    .contItem:nth-child(4n) {
      margin-right: 0;
    }
  }

  //列表
  .contentBoxCopy {
    width: 100%;
    height: calc(100% - 74px);
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.09);
    overflow: auto;
    //搜索
    .searchBox {
      padding: 24px 24px 8px;
      width: calc(100% - 48px);
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;

      .liBox {
        margin-bottom: 16px;
        margin-right: 24px;
        display: flex;
        align-items: center;

        > p {
          margin-bottom: 8px;
        }

        > span {
          display: inline-block;
          width: 60px;
          margin-right: 8px;
        }

        .el-select, .el-input {
          width: 220px;
        }
      }
    }

    //列表内容
    .contain {
      width: calc(100% - 48px);
      padding: 0 24px 24px;
      overflow: auto;

      //表格
      .el-table {
        color: rgba(3, 10, 26, 0.85);
        width: calc(100% - 20px);
        overflow: auto;

        th, td {
          padding: 0;
        }

        .cell {
          padding: 14px 16px;

          .btn {
            display: inline-block;
            color: #165DFF;
            margin-right: 14px;
            cursor: pointer;
          }

          .statusBox {
            p:nth-child(2) {
              width: 40px;
              height: 24px;
              border-radius: 4px;
              text-align: center;
              line-height: 24px;
              font-size: 12px;
              color: #fff;
              margin-top: 13px;
            }

            .online {
              background: #67C23A;
            }

            .offline {
              background: #999999;
            }
          }

          .cont {
            display: -webkit-box; //对象作为弹性伸缩盒子模型显示
            overflow: hidden; //溢出隐藏
            -webkit-box-orient: vertical; //设置伸缩盒子对象的子元素的排列方式
            -webkit-line-clamp: 1; //设置 块元素包含的文本行数
            cursor: pointer;
          }

          .opacity {
            img {
              width: 24px;
              height: 24px;
              margin-right: 16px;
              cursor: pointer;
            }
          }
        }
      }

      //分页
      .pag {
        width: 100%;
        height: 32px;
        padding: 16px 0 0 0;

        .el-pagination {
          float: right;
          margin-right: 12px;
        }
      }
    }
  }


  .el-dialog {
    .liPox {
      display: flex;
      align-items: center;
      margin-bottom: 16px;

      span {
        display: inline-block;
        width: 90px;
      }
    }
  }
}
</style>
