//导入axios
import axios from 'axios'
import {ElMessage} from "element-plus";
import {handleError, nextTick} from "vue";
// import {useRouter} from 'vue-router';
import router from '../router/index'

// let router = useRouter()
let a = window.location.href.split('/')
let b = `${a[0]}/${a[1]}/${a[2]}`
export let httpHost = b + ':9000/'

// 导出用
export let http = window.location.origin == 'https://www.380v.com' ? 'https://interface.380v.com/' : 'https://v3testapi.380v.com/'  //线上或测试
// export let http = httpHost   //本地
// export let http = 'http://192.168.3.20:9000/'   //本地 得力
// export let http = 'http://10.192.3.20:9000/'   //本地 得力

// 导出用2
export let http2 = 'https://interface.380v.com/'   //线上
// export let http2 = httpHost   //本地
// export let http2 = 'http://192.168.3.20:9000/'   //本地 得力
// export let http2 = 'http://10.192.3.20:9000/'   //本地 得力


export const api = axios.create({
    timeout: 50000,
    // baseURL: 'http://192.168.1.103:9000/' //测试
    // baseURL: 'http://cloud.380v.com:9000/' //正式
    // baseURL: 'https://interface.380v.com/' //正式
    // baseURL: 'http://192.168.3.109:9000/' //本地
    // baseURL: 'http://v3.380v.com:9001/' //本地
    // baseURL: 'http://36.153.8.3:9000/' //本地
    // baseURL: 'http://192.168.3.74:9000/' //本地
    // baseURL: 'http://192.168.1.151:9000/' //本地  南通惠生
    // baseURL: 'http://v3.380v.com:5090/' //本地  南通惠生
    // baseURL: 'http://192.168.3.20:9000/' //本地  得力
    // baseURL: 'http://10.192.3.20:9000/' //本地  得力
    // baseURL: httpHost //本地

    // 线上或测试
    baseURL: window.location.origin == 'https://www.380v.com' ? 'https://interface.380v.com/' : 'https://v3testapi.380v.com/'
})

export const api2 = axios.create({
    timeout: 50000,
    baseURL: 'https://restapi.amap.com'
})

/*export const api3 = axios.create({
    timeout: 50000,
    // baseURL: 'http://192.168.3.253:8085/'
    baseURL: 'https://docs.emqx.com/'
})*/
// 请求拦截器
api.interceptors.request.use(function (config) {
    return config
}, function (error) {
    ElMessage({
        message: error,
        type: 'error'
    })
    return Promise.reject(error)
})

// 响应拦截器
api.interceptors.response.use(function (response) {
    if (response.data.code === 400) {
        nextTick(() => {
            router.push('/login')
        })
    }
    if (response.data.code !== 0 && response.data.code !== 200 && response.data.code) {
        ElMessage({
            message: response.data.message ? response.data.message : response.data.msg,
            type: 'error'
        })
    } else {
        return response
    }
}, function (error) {
    ElMessage({
        message: error.message ? error.message : error.msg,
        type: 'error'
    })
    // handleError(error)
    return Promise.reject(error)
})
export const api3 = axios.create({
    // baseURL: '/api3',
    // baseURL: b,  //本地
    baseURL: window.location.origin == 'https://www.380v.com' ? 'https://www.380v.com' : 'https://v3test.380v.com/',
    // baseURL: 'http://192.168.3.59/',  //本地
    // baseURL: 'https://www.380v.com',
    // baseURL: 'http://v3test.380v.com/',
    headers: {
        Authorization: 'Bearer ' + window.localStorage.getItem('token'),
    },
    timeout: 50000,
})
// 重试次数
const maxRetries = 3;
let retries = 0;
api3.interceptors.response.use(
    response => response, // 直接返回成功的响应
    async error => {
        const originalRequest = error.config;

        // 检查是否超过最大重试次数，如果是，抛出错误
        if (retries >= maxRetries) {
            return Promise.reject(error);
        }
        // 增加重试次数
        retries++;

        // 检查是否已经重试过（避免无限循环）
        if (error.response && error.response.status === 401) {
            originalRequest._retry = true; // 标记请求已重试
            try {
                // 尝试重新登录
                const loginResponse = await api3.post('/api/login', {
                    name: "admin",
                    pass: "Zuoke@0801"
                });

                // 更新token并设置到请求头
                const newToken = loginResponse.data.token;
                window.localStorage.setItem('token', newToken);
                api3.defaults.headers.Authorization = `Bearer ${newToken}`;
                originalRequest.headers.Authorization = `Bearer ${newToken}`;

                // 重试原始请求
                return api3(originalRequest);
            } catch (loginError) {
                // 登录失败处理
                retries = 0
                console.error('自动登录失败:', loginError);
                // 可以在这里弹窗提示用户登录失败
                // ElMessage({ message: '登录失败，请手动登录', type: 'error' });

                // 抛出错误，以便外部可以捕获并处理
                throw loginError;
            }
        }

        // 处理非401错误
        // 这里可以根据具体需求处理错误，比如弹出错误消息
        // ElMessage({ message: error.response.data.message, type: 'error' });
        ElMessage({
            message: error.message ? error.message : error.msg,
            type: 'error'
        })
        // 返回错误，让上层能继续处理
        return Promise.reject(error);
    }
);
axios.defaults.proxy = {
    host: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/',
    // port: 80,
    // protocol: 'http'
};
