import { api } from '@/api'

// 查询计费清单列表
export function listCharge(query) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/billing/charge/page',
    method: 'get',
    params: query,
  })
}

// 查询计费清单详细
export function getCharge(id) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/billing/charge/detail/' + id,
    method: 'get',
  })
}

// 新增计费清单
export function addCharge(data) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/billing/charge/save',
    method: 'post',
    data: data,
  })
}

// 修改计费清单
export function updateCharge(data) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/billing/charge/update',
    method: 'post',
    data: data,
  })
}

// 删除计费清单
export function delCharge(id) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/billing/charge/remove/' + id,
    method: 'post',
  })
}

// 查询计费清单项目列表
export function listChargeProject(query) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/billing/charge/project',
    method: 'get',
    params: query,
  })
}

// 查询计费清单设备列表
export function listChargeEquipment(query) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/billing/charge/equipment',
    method: 'get',
    params: query,
  })
}

// 绑定设备
export function bindEquipment(data) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/billing/charge/bind',
    method: 'post',
    data: data,
  })
}

// 解绑设备
export function unbindEquipment(data) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/billing/charge/unbind',
    method: 'post',
    data: data,
  })
}

// 查询计费清单列表
export function getChargeList(query) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/billing/charge/list',
    method: 'get',
    params: query,
  })
}

// 解绑租户
export function unbindTenant(data) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/billing/charge/unbindTenant',
    method: 'post',
    data: data,
  })
}

// 生成结算清单
export function generateCharge(data) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/billing/charge/generate',
    method: 'post',
    data: data,
  })
}

export function cutoffCharge(data) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/billing/charge/cutoff',
    method: 'post',
    data: data,
  })
}

export function confirmCharge(id) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/billing/charge/confirm/' + id,
    method: 'post',
  })
}

export function startCharge(id) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/billing/charge/start/' + id,
    method: 'post',
  })
}

export function getTenantCharge(id) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: `/zouk/billing/tenant/charge/${id}`,
    method: 'get',
  })
}

export function tenantPayment(id) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: `/zouk/billing/tenant/payment/${id}`,
    method: 'post',
  })
}
