<template>
  <div class="compoundQueryLocal">
    <div class="title">
      <p>
        <span>复合查询</span>
      </p>
      <el-button type="primary" @click="searchPlan">查询管理</el-button>
    </div>
    <div class="content">
      <!--搜索-->
      <div class="searchBox">
        <div class="liBox">
          <span>查询方案： </span>
          <el-select v-model="plan" @change="planChange">
            <el-option v-for="(item,index) in planList" :key="index" :label="item.queryName"
                       :value="item.id"></el-option>
          </el-select>
          <span style="margin-left: 50px">采样频率： </span>
          <el-input v-model="frequency" :disabled="true" placeholder="请输入采样频率" type="number">
            <!--            <template #suffix>-->
            <!--              小时-->
            <!--            </template>-->
            <template #append>
              <el-select v-model="timeUnit" style="width: 90px;">
                <el-option label="分钟" value="m"/>
                <el-option label="小时" value="h"/>
                <el-option label="天" value="d"/>
                <el-option label="月" value="n"/>
                <el-option label="年" value="y"/>
              </el-select>
            </template>
          </el-input>
        </div>
        <div class="liBox">
          <span>日期： </span>
          <el-select v-model="timeFlag" clearable @change="timeFlagSelect">
            <el-option label="自定义时间段" value="-1"></el-option>
            <el-option label="近1小时" value="1H"></el-option>
            <el-option label="近12小时" value="12H"></el-option>
            <el-option label="近一天" value="1D"></el-option>
            <el-option label="近3天" value="3D"></el-option>
            <el-option label="近1周" value="1W"></el-option>
            <el-option label="本周" value="THIS_WEEK"></el-option>
            <el-option label="本月" value="1M"></el-option>
            <el-option label="本季" value="1Q"></el-option>
          </el-select>
          <el-date-picker v-model="dateTime" end-placeholder="结束时间" popper-class="date-style"
                          range-separator="→"
                          start-placeholder="开始时间" style="transform: translateY(3px);margin-left: 15px"
                          type="datetimerange"
                          value-format="YYYY-MM-DD HH:mm:ss">
          </el-date-picker>
          <el-checkbox-group v-model="yom" :max="1" :min="0"
                             style="display: inline-block;margin-left: 20px;transform: translateY(3px);">
            <el-checkbox :label="1">同比</el-checkbox>
            <el-checkbox :label="2">环比</el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="liBox liCopy">
          <div v-for="(item,index) in equipmentArr" :key="index" class="contentItem">
            <div v-if="equipmentArr.length!==1" style="display: flex;align-items: center">
              <el-icon class="el-icon-delete delFilter"
                       style="color: #FE4761;font-size: 16px;cursor: pointer;"
                       @click="delCompound(index)">
                <Delete/>
              </el-icon>
            </div>
            <div>
              <div>
                <el-input v-model="item.name" placeholder="请输入复合指标名称" style="width: 160px"></el-input>
                <div class="contTime">
                  <el-radio-group v-model="item.chatType">
                    <el-radio :label="0" size="small">柱状</el-radio>
                    <el-radio :label="1" size="small">折线</el-radio>
                    <br/>
                    <el-radio :label="2" size="small">面积</el-radio>
                    <el-radio :label="3" size="small">点</el-radio>
                  </el-radio-group>
                </div>
                <el-checkbox-group v-model="item.valueType">
                  <el-checkbox :label="0">最大值</el-checkbox>
                  <el-checkbox :label="1">最小值</el-checkbox>
                </el-checkbox-group>
              </div>
              <p>=</p>
            </div>
            <div v-for="(item_child,index_child) in item.data" :key="index_child"
                 :style="{alignItems:item_child.type===''?'center':'start'}" class="contItemBox">
              <div v-if="index_child!==0" class="symbol">
                <p
                    :class="item.formula.substring(2*index_child-1,2*index_child)==='+'?'activeSymbol':''"
                    @click="symbolClick(index,index_child,'+')">+</p>
                <p
                    :class="item.formula.substring(2*index_child-1,2*index_child)==='-'?'activeSymbol':''"
                    @click="symbolClick(index,index_child,'-')">-</p>
                <p
                    :class="item.formula.substring(2*index_child-1,2*index_child)==='×'||item.formula.substring(2*index_child-1,2*index_child)==='*'?'activeSymbol':''"
                    @click="symbolClick(index,index_child,'*')">×</p>
                <p
                    :class="item.formula.substring(2*index_child-1,2*index_child)==='÷'||item.formula.substring(2*index_child-1,2*index_child)==='/'?'activeSymbol':''"
                    @click="symbolClick(index,index_child,'/')">÷</p>
              </div>
              <div class="contItem">
                <el-select v-model="item_child.type" clearable placeholder="请选择参数类型">
                  <el-option :value="1" label="指标"/>
                  <el-option :value="0" label="数值"/>
                </el-select>
                <div v-if="item_child.type!==''" class="contBox">
                  <el-icon v-if="item.data.length>1" class="delIcon" @click="delControl(index,index_child)">
                    <CircleClose/>
                  </el-icon>
                  <el-select v-show="item_child.type==1" ref="elSelectTable" v-model="item_child.typeName"
                             :filter-method="filterMethod" :popper-append-to-body="false"
                             class="select-unitName select-option-father" clearable filterable
                             popper-class="select_report_and_type">
                    <el-option :value="dataItemList">
                      <el-tree
                          ref="treeRef"
                          :expand-on-click-node=true
                          :filter-node-method="filterNode"
                          :load="treeList"
                          :props="props"
                          highlight-current
                          lazy
                          @node-click="clickUnitTreeNodeChild($event,index,index_child)"
                      />
                    </el-option>
                  </el-select>
                  <div class="center">
                    <el-input v-if="item_child.type==0" v-model="item_child.val" class="val" placeholder="请输入"
                              type="number"
                    />
                    <p v-if="item_child.type==1">{{ item_child.deviceName }}</p>
                    <p v-if="item_child.type==1">{{ item_child.typeName }}</p>
                  </div>
                  <p v-if="item_child.type==1" class="unit">{{ item_child.unit }}</p>
                </div>
                <div v-if="item_child.type==1" class="contTime">
                  <el-checkbox v-model="item_child.difference" label="差值" size="large"/>
                </div>
              </div>
            </div>
            <div>
              <el-icon class="addIcon" @click="addControl(index)">
                <CirclePlus/>
              </el-icon>
            </div>
          </div>
        </div>
        <div class="liBox">
          <el-button style="margin-bottom: 0;width: 160px" type="primary" @click="addCompound">添加复合指标
          </el-button>
        </div>
        <div class="liBox">
          <el-button class="searchButton" type="primary" @click="searchFun">查询</el-button>
          <el-button type="success" @click="exportFun">导出</el-button>
          <el-button class="searchButton resetButton" @click="resetFun">重置</el-button>
        </div>
      </div>
      <!--搜索的内容-->
      <div class="contain">
        <el-empty v-if="echartsDataX.length===0"
                  class="emptyClass" description="暂无数据信息"
                  image="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png"/>
        <!--图表-->
        <div v-show="echartsDataX.length>0" class="chart"></div>
      </div>
    </div>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {h, nextTick, onMounted, reactive, ref} from "vue"
import './compoundQuery.less'
import {CloseBold, ZoomIn, Plus, Delete, CirclePlus, CircleClose} from '@element-plus/icons-vue'
import {CascaderProps, ElLoading, ElMessage, ElMessageBox} from 'element-plus'
import * as echarts from 'echarts';
import {useRouter} from 'vue-router';
import qs from "qs";
import {getQueryListApi, getQueryDetailApi, chatSearchApi, exportComDataApi} from '@/api/modules/compoundQuery'
import moment from 'moment'
import {getTreeListApi} from "@/api/modules/control";
import letterList from "@/assets/js/letterList";

let router = useRouter()


// 点击跳转查询管理
const searchPlan = function () {
  router.push('/admin/compoundQueryPlanLocal')
}

// 搜索---------------------------------------------------------------------------------------
let plan = ref()   //选择的搜索方案
let timeFlag = ref()   //日期快捷选择
let dateTime = ref()   //搜索日期
let frequency = ref(1)   //频率
let timeUnit = ref('h')   //频率单位
let yom = ref([])  //同环比
let equipmentArr = ref([
  {
    formula: 'A',
    name: '',
    chatType: '',
    valueType: [],
    data: [{
      val: "",
      type: '',
      unit: "",
      typeId: '',
      typeName: "",
      replaceStr: "A",
      difference: '',
      deviceId: '',
      deviceName: '',
    }]
  },
])    //搜索的设备

// 日期快捷选择事件
const timeFlagSelect = function () {
  if (timeFlag.value === '-1') {
    dateTime.value = []
  } else if (timeFlag.value === '1H') {
    dateTime.value = [moment().subtract(1, "hours").format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (timeFlag.value === '12H') {
    dateTime.value = [moment().subtract(12, "hours").format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (timeFlag.value === '1D') {
    dateTime.value = [moment().subtract(1, "days").format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (timeFlag.value === '3D') {
    dateTime.value = [moment().subtract(3, "days").format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (timeFlag.value === '1W') {
    dateTime.value = [moment().subtract(1, "weeks").format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (timeFlag.value === 'THIS_WEEK') {
    dateTime.value = [moment().startOf('isoWeek').format('YYYY-MM-DD HH:mm:ss'), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (timeFlag.value === '1M') {
    dateTime.value = [moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (timeFlag.value === '1Q') {
    dateTime.value = [moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).startOf('quarter').format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
    // dateTime.value = [moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).startOf('quarter').format("YYYY-MM-DD HH:mm:ss"), moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).endOf('quarter').format("YYYY-MM-DD HH:mm:ss")]
  }
}


// 获取图表数据
let echartsData = ref([])
let echartsDataX = ref([])
const getEchartsDataFun = async function () {
  echartsDataX.value = []
  echartsData.value = []
  let data = {
    startTime: dateTime.value ? dateTime.value[0] : '',
    endTime: dateTime.value ? dateTime.value[1] : '',
    frequency: Number(frequency.value),
    timeUnit: timeUnit.value,
    compositeIndexList: JSON.parse(JSON.stringify(equipmentArr.value)),
    yom: yom.value[0]
  }
  // 处理差值
  for (let i = 0; i < data.compositeIndexList.length; i++) {
    // 处理最大最小值
    data.compositeIndexList[i].valueType = data.compositeIndexList[i].valueType.join()
    for (let j = 0; j < data.compositeIndexList[i].data.length; j++) {
      data.compositeIndexList[i].data[j].difference = data.compositeIndexList[i].data[j].difference ? 1 : 0
      data.compositeIndexList[i].data[j].val = Number(data.compositeIndexList[i].data[j].val)
    }
  }
  // 计算同环比时间
  if (yom.value && yom.value[0] == 1) {
    data.lastStartTime = moment(data.startTime).subtract(1, 'years').format("YYYY-MM-DD HH:mm:ss")
    data.lastEndTime = moment(data.endTime).subtract(1, 'years').format("YYYY-MM-DD HH:mm:ss")
  } else if (yom.value && yom.value[0] == 2) {
    if (timeFlag.value === '1M') {
      data.lastStartTime = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD HH:mm:ss')
      data.lastEndTime = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD HH:mm:ss')
    } else if (timeFlag.value === '1Q') {
      data.lastStartTime = moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).subtract(1, 'quarter').startOf('quarter').format("YYYY-MM-DD HH:mm:ss")
      data.lastEndTime = moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).subtract(1, 'quarter').endOf('quarter').format("YYYY-MM-DD HH:mm:ss")
    } else {
      let timeLength = moment(data.endTime).diff(data.startTime, 'second')
      data.lastStartTime = moment(data.startTime).subtract(timeLength, 'second').format('YYYY-MM-DD HH:mm:ss')
      data.lastEndTime = moment(data.endTime).subtract(timeLength, 'second').format('YYYY-MM-DD HH:mm:ss')
    }
  }
  let flag = false
  for (let i = 0; i < data.compositeIndexList.length; i++) {
    if (!data.compositeIndexList[i].name || !data.compositeIndexList[i].formula || data.compositeIndexList[i].chatType === '' || (data.compositeIndexList[i].data.length === 0)) {
      flag = true
    } else {
      for (let j = 0; j < data.compositeIndexList[i].data.length; j++) {
        if (data.compositeIndexList[i].data[j].type === '') {
          flag = true
        }
      }
    }
  }
  // 处理符号和字母
  for (let i = 0; i < data.compositeIndexList.length; i++) {
    for (let j = 0; j < data.compositeIndexList[i].data.length; j++) {
      data.compositeIndexList[i].data[j].replaceStr = letterList()[j].name
    }
    data.compositeIndexList[i].formula = letterList()[0].name + data.compositeIndexList[i].formula.substr(1);
    for (let j = 0; j < data.compositeIndexList[i].formula.length; j++) {
      if (j % 2 == 0 && j !== 0) {
        data.compositeIndexList[i].formula = data.compositeIndexList[i].formula.substr(0, j) + letterList()[j / 2].name + data.compositeIndexList[i].formula.substr(j + 1);
      }
    }
  }
  if (data.frequency && data.timeUnit && !flag) {
    openFullScreen()
    await chatSearchApi(data).then(res => {
      loadingAdd.value.close()
      if (res.data.code === 0) {
        echartsDataX.value = res.data.data.xAxis
        echartsData.value = res.data.data.yAxis
        for (let i = 0; i < echartsData.value.length; i++) {
          echartsData.value[i].name = echartsData.value[i].deviceName + '-' + echartsData.value[i].titleName
          echartsData.value[i].sampling = 'lttb'
          for (let j = 0; j < echartsData.value[i].data.length; j++) {
            echartsData.value[i].data[j] = Number(echartsData.value[i].data[j]).toFixed(2)
          }
          // 处理最大值最小值
          echartsData.value[i].markPoint = {
            data: []
          }
          if (echartsData.value[i].valueType !== '') {
            echartsData.value[i].valueType = echartsData.value[i].valueType.split(',')
            echartsData.value[i].valueType = echartsData.value[i].valueType.map(Number)
            if (echartsData.value[i].valueType && echartsData.value[i].valueType.length > 0) {
              for (let j = 0; j < echartsData.value[i].valueType.length; j++) {
                if (echartsData.value[i].valueType[j] === 0) {
                  echartsData.value[i].markPoint.data.push({
                    type: 'max', name: 'Max'
                  })
                }
                if (echartsData.value[i].valueType[j] === 1) {
                  echartsData.value[i].markPoint.data.push({
                    type: 'min', name: 'Min'
                  })
                }
              }
            }
          }
          // 处理图表类型
          if (echartsData.value[i].chatType === 0) {
            echartsData.value[i].type = 'bar'
            echartsData.value[i].symbol = 'none'
            echartsData.value[i].barGap = '0%'
          } else if (echartsData.value[i].chatType === 1) {
            echartsData.value[i].type = 'line'
            echartsData.value[i].lineStyle = {
              width: 1
            }
            echartsData.value[i].symbol = 'circle'
            // echartsData.value[i].symbol = 'none'
          } else if (echartsData.value[i].chatType === 3) {
            echartsData.value[i].type = 'scatter'
            echartsData.value[i].symbolSize = 15
            //for (let j = 0; j < echartsData.value[i].data.length; j++) {
            // echartsData.value[i].data.splice(j, 1, [echartsDataX.value[j], Number(echartsData.value[i].data[j])])
            //}
          } else if (echartsData.value[i].chatType === 2) {
            echartsData.value[i].type = 'line'
            echartsData.value[i].symbol = 'none'
            echartsData.value[i].areaStyle = {
              opacity: 0.3
            }
          }
        }
        nextTick(() => {
          chartLoad()
        })
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message
        })
      }
    }).catch(err => {
      console.log(err);
    })
  } else {
    ElMessage({
      type: 'info',
      message: '请选择筛选条件'
    })
  }
}


// 查询
const searchFun = async function () {
  await getEchartsDataFun()
}
// 重置
const resetFun = function () {
  plan.value = ''
  dateTime.value = ''
  frequency.value = 1
  timeUnit.value = ''
  timeFlag.value = ''
  yom.value = []
  equipmentArr.value = [
    {
      formula: 'A',
      name: '',
      chatType: '',
      valueType: [],
      data: [{
        val: "",
        type: '',
        unit: "",
        typeId: '',
        typeName: "",
        replaceStr: "A",
        difference: '',
        deviceId: '',
        deviceName: '',
      }]
    },
  ]

  echartsData.value = []
  echartsDataX.value = []
  nextTick(() => {
    chartLoad()
  })
}
// 加载图表
const chartLoad = function () {
  var chartDom = document.querySelector('.chart');
  var myChart = echarts.init(chartDom);
  var option;

  option = {
    // toolbox: {
    //   feature: {
    //     dataZoom: {
    //       yAxisIndex: 'none'
    //     },
    //     restore: {},
    //     saveAsImage: {}
    //   }
    // },
    grid: {
      left: 50,
      right: 20
    },
    legend: {
      show: true,
      type: 'scroll'
    },
    tooltip: {
      trigger: 'axis', // axis   item   none三个值
      show: true
    },
    xAxis: {
      type: 'category',
      boundaryGap: true,
      data: echartsDataX.value
    },
    yAxis: {
      type: 'value',
    },
    dataZoom: [
      {
        type: 'inside',
        start: 0,
        end: 100,
        disabled: false
      },
      {
        start: 0,
        end: 100
      }
    ],
    series: echartsData.value
  };
  myChart.clear()
  option && myChart.setOption(option);

  myChart.resize();
  window.onresize = () => {
    myChart.resize();
  }
}
// 加载的动画
let loadingAdd = ref()
const openFullScreen = function () {
  loadingAdd.value = ElLoading.service({
    lock: true,
    text: 'Loading',
    background: 'rgba(0, 0, 0, 0.1)'
  })
}
// 点击导出
const exportFun = function () {

  let data = {
    startTime: dateTime.value ? dateTime.value[0] : '',
    endTime: dateTime.value ? dateTime.value[1] : '',
    frequency: Number(frequency.value),
    timeUnit: timeUnit.value,
    compositeIndexList: JSON.parse(JSON.stringify(equipmentArr.value)),
    yom: yom.value[0]
  }
  // 处理差值
  for (let i = 0; i < data.compositeIndexList.length; i++) {
    // 处理最大最小值
    data.compositeIndexList[i].valueType = data.compositeIndexList[i].valueType.join()
    for (let j = 0; j < data.compositeIndexList[i].data.length; j++) {
      data.compositeIndexList[i].data[j].difference = data.compositeIndexList[i].data[j].difference ? 1 : 0
      data.compositeIndexList[i].data[j].val = Number(data.compositeIndexList[i].data[j].val)
    }
  }
  // 计算同环比时间
  if (yom.value && yom.value[0] == 1) {
    data.lastStartTime = moment(data.startTime).subtract(1, 'years').format("YYYY-MM-DD HH:mm:ss")
    data.lastEndTime = moment(data.endTime).subtract(1, 'years').format("YYYY-MM-DD HH:mm:ss")
  } else if (yom.value && yom.value[0] == 2) {
    if (timeFlag.value === '1M') {
      data.lastStartTime = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD HH:mm:ss')
      data.lastEndTime = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD HH:mm:ss')
    } else if (timeFlag.value === '1Q') {
      data.lastStartTime = moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).subtract(1, 'quarter').startOf('quarter').format("YYYY-MM-DD HH:mm:ss")
      data.lastEndTime = moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).subtract(1, 'quarter').endOf('quarter').format("YYYY-MM-DD HH:mm:ss")
    } else {
      let timeLength = moment(data.endTime).diff(data.startTime, 'second')
      data.lastStartTime = moment(data.startTime).subtract(timeLength, 'second').format('YYYY-MM-DD HH:mm:ss')
      data.lastEndTime = moment(data.endTime).subtract(timeLength, 'second').format('YYYY-MM-DD HH:mm:ss')
    }
  }
  let flag = false
  for (let i = 0; i < data.compositeIndexList.length; i++) {
    if (!data.compositeIndexList[i].name || !data.compositeIndexList[i].formula || data.compositeIndexList[i].chatType === '' || (data.compositeIndexList[i].data.length === 0)) {
      flag = true
    } else {
      for (let j = 0; j < data.compositeIndexList[i].data.length; j++) {
        if (data.compositeIndexList[i].data[j].type === '') {
          flag = true
        }
      }
    }
  }
  // 处理符号和字母
  for (let i = 0; i < data.compositeIndexList.length; i++) {
    for (let j = 0; j < data.compositeIndexList[i].data.length; j++) {
      data.compositeIndexList[i].data[j].replaceStr = letterList()[j].name
    }
    data.compositeIndexList[i].formula = letterList()[0].name + data.compositeIndexList[i].formula.substr(1);
    for (let j = 0; j < data.compositeIndexList[i].formula.length; j++) {
      if (j % 2 == 0 && j !== 0) {
        data.compositeIndexList[i].formula = data.compositeIndexList[i].formula.substr(0, j) + letterList()[j / 2].name + data.compositeIndexList[i].formula.substr(j + 1);
      }
    }
  }
  if (data.frequency && data.timeUnit && !flag) {
    const downloadZip = (data, fileName = '数据查询') => {
      var blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})
      if ('download' in document.createElement('a')) {
        var downloadElement = document.createElement('a')
        var href = window.URL.createObjectURL(blob) //创建下载的链接
        downloadElement.href = href
        downloadElement.download = fileName //下载后文件名
        document.body.appendChild(downloadElement)
        downloadElement.click() //点击下载
        document.body.removeChild(downloadElement) //下载完成移除元素
        window.URL.revokeObjectURL(href) //释放掉blob对象
      }
    }
    openFullScreen()
    exportComDataApi(data).then(res => {
      loadingAdd.value.close()
      downloadZip(res.data)
    }).catch(err => {
      console.log(err);
    })
  } else {
    ElMessage({
      type: 'info',
      message: '请选择筛选条件'
    })
  }
}

// 获取查询方案列表
let planList = ref([])
const getSearchListFun = async function () {
  await getQueryListApi(1).then(res => {
    if (res.data.code === 200) {
      planList.value = res.data.data
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 查询方案change事件
const planChange = async function () {
  await getQueryDetailApi(plan.value).then(res => {
    if (res.data.code === 200) {
      frequency.value = res.data.data.frequency
      timeUnit.value = res.data.data.timeUnit
      timeFlag.value = res.data.data.timeFlag
      yom.value = res.data.data.yom ? [res.data.data.yom] : []
      timeFlagSelect()
      if (timeFlag.value === '-1') {
        dateTime.value = [res.data.data.startTime, res.data.data.endTime]
      }
      equipmentArr.value = res.data.data.compositeIndexList

      for (let i = 0; i < equipmentArr.value.length; i++) {
        // 处理最大值最小值
        equipmentArr.value[i].valueType = equipmentArr.value[i].valueType.split(',')
        equipmentArr.value[i].valueType = equipmentArr.value[i].valueType.map(Number)
        for (let j = 0; j < equipmentArr.value[i].data.length; j++) {
          equipmentArr.value[i].data[j].difference = equipmentArr.value[i].data[j].difference === 1 ? true : false
        }
      }
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}


// 获取树形结构列表----------------------------------------------------------------------
// 左侧项目树元素
let treeRef = ref()
//获取项目树
const getProjectFun = async function (node, resolve) {
  await getTreeListApi(qs.stringify({
    parentId: node.data.id ? node.data.id : 0,
    type: node.data.type ? node.data.type : 1
  })).then(res => {
    if (res.data.code === 0) {
      return resolve(res.data.result)
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 项目树配置
let props = {
  label: 'name',
  children: 'subList',
  isLeaf: (data, node) => {
    if (node.data.type === 3) {
      return true
    }
  }
}
// 树级列表
const treeList = (node, resolve) => {
  getProjectFun(node, resolve)
}


// 弹窗的参数操作------------------------------------
// 符号点击
const symbolClick = function (index, index_child, e) {
  let str = equipmentArr.value[index].formula
  equipmentArr.value[index].formula = str.substr(0, 2 * index_child - 1) + e + str.substr(2 * index_child);
}
// 添加行
const addCompound = function () {
  equipmentArr.value.push({
    formula: 'A',
    name: '',
    chatType: '',
    valueType: [],
    data: [{
      val: "",
      type: '',
      unit: "",
      typeId: '',
      typeName: "",
      replaceStr: "A",
      difference: '',
      deviceId: '',
      deviceName: '',
    }]
  })
}
// 删除行
const delCompound = function (index) {
  ElMessageBox.confirm('', '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '删除复合指标',
    message: h('p', null, [
      h('img', {
        src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
        style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
      }, ''),
      h('span', {style: 'color:#595959;'}, '请确认是否要删除此复合指标，删除后数据不可恢复'),
    ])
  }).then(() => {
    equipmentArr.value.splice(index, 1)
    ElMessage({
      type: 'success',
      message: '删除成功'
    });
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '已取消删除'
    });
  });
}
// 添加参数值
const addControl = function (index) {
  equipmentArr.value[index].formula = equipmentArr.value[index].formula.concat('+D')
  equipmentArr.value[index].data.push({
    val: "",
    type: '',
    unit: "",
    typeId: '',
    typeName: "",
    replaceStr: "A",
    difference: '',
    deviceId: '',
    deviceName: '',
  })
  console.log(equipmentArr.value)
}
// 删除参数值
const delControl = function (index, index_child) {
  ElMessageBox.confirm('', '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '删除参数',
    message: h('p', null, [
      h('img', {
        src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
        style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
      }, ''),
      h('span', {style: 'color:#595959;'}, '请确认是否要删除此参数，删除后数据不可恢复'),
    ])
  }).then(() => {
    let str = equipmentArr.value[index].formula
    let strA
    let strB
    if (index_child !== 0) {
      strA = str.slice(0, 2 * index_child - 1)
      strB = str.slice(2 * index_child + 1, str.length)
    } else {
      strA = ''
      strB = str.slice(2, str.length)
    }
    equipmentArr.value[index].formula = strA.concat(strB)
    equipmentArr.value[index].data.splice(index_child, 1)
    ElMessage({
      type: 'success',
      message: '删除成功'
    });
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '已取消删除'
    });
  });
}
//node的点击
let elSelectTable = ref()
const clickUnitTreeNodeChild = function (data, index, index_child) {
  if (data.type == 3) {
    equipmentArr.value[index].data[index_child].typeName = data.name
    equipmentArr.value[index].data[index_child].typeId = data.id
    equipmentArr.value[index].data[index_child].deviceName = data.equipmentName
    equipmentArr.value[index].data[index_child].unit = data.unitName
    equipmentArr.value[index].data[index_child].deviceId = data.equipmentId
    elSelectTable.value[index_child].blur();
  } else {
    // ElMessage({
    //   type: 'info',
    //   message: '当前选择不是指标选项'
    // });
  }
}


// 下拉搜索
const filterMethod = function (val) {
  treeRef.value[0].filter(val)
}
const filterNode = (value, data) => {
  if (!value) return true
  return data.name.includes(value)
}


onMounted(async () => {
  await getSearchListFun()
  if (router.currentRoute.value.query.id) {
    plan.value = router.currentRoute.value.query.id
    await planChange()
    await getEchartsDataFun()
  }
})

</script>

<style lang="less" scoped>
</style>
