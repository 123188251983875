<template>
  <div class="charge">
    <div class="title">
      <p>
        <span>结算管理</span>
      </p>
    </div>
    <div class="content">
      <div class="searchBox">
        <div class="liBox">
          <label style="margin-right: 10px">房东名称</label>
          <el-input
            v-model="queryParams.landlordName"
            prefix-icon="Search"
            clearable
            placeholder="请输入房东名称"
          ></el-input>
        </div>
        <div class="liBox">
          <el-button class="searchButton" type="primary" @click="handleQuery"
            >查询</el-button
          >
          <el-button class="searchButton resetButton" @click="resetQuery"
            >重置</el-button
          >
        </div>
      </div>
      <div class="contain">
        <el-button class="addBtn" type="primary" @click="handleGenerate">
          <el-icon>
            <Plus />
          </el-icon>
          生成结算清单
        </el-button>
        <el-table v-loading="loading" :data="chargeList" row-key="id">
          <template #empty>
            <el-empty
              class="emptyClass"
              description="暂无数据信息"
              image="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png"
            >
              <p>暂无计费清单</p>
            </el-empty>
          </template>
          <el-table-column type="expand">
            <template #default="props">
              <div style="margin: 1rem">
                <h3>租户计费列表</h3>
                <el-table :data="props.row.tenantChargeDetailVOList">
                  <el-table-column
                    label="租户姓名"
                    align="center"
                    prop="tenantName"
                  />
                  <el-table-column
                    label="手机号码"
                    align="center"
                    prop="tenantMobile"
                  />
                  <el-table-column
                    label="上次结余"
                    align="center"
                    prop="tenantLastBalance"
                  />
                  <el-table-column
                    label="本次结余"
                    align="center"
                    prop="tenantCurrentBalance"
                  />
                  <el-table-column
                    label="实付电费"
                    align="center"
                    prop="tenantActualCharge"
                  />
                  <el-table-column
                    label="是否已交费"
                    align="center"
                    prop="tenantIsPay"
                  >
                    <template #default="scope">
                      <el-tag
                        :type="
                          yes_or_no.find(
                            (item) => item.value === scope.row.tenantIsPay
                          )?.type
                        "
                        size="small"
                      >
                        {{
                          yes_or_no.find(
                            (item) => item.value === scope.row.tenantIsPay
                          )?.label
                        }}
                      </el-tag>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="异动提示"
                    align="center"
                    prop="fluctuationMsg"
                  />
                  <el-table-column label="操作" align="center" width="200px">
                    <template #default="scope">
                      <div class="opacity">
                        <p class="btn" @click="handleView(scope.row)">
                          查看详情
                        </p>
                        <p
                          v-if="scope.row.tenantIsPay === 0"
                          class="btn"
                          @click="handlePay(scope.row)"
                        >
                          缴费
                        </p>
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="房东名称"
            align="center"
            prop="landlordName"
          />
          <el-table-column
            label="手机号码"
            align="center"
            prop="landlordMobile"
          />
          <el-table-column label="计费日期范围" align="center" width="240px">
            <template #default="scope">
              {{ scope.row.startChargeDate }} ~ {{ scope.row.endChargeDate }}
            </template>
          </el-table-column>
          <el-table-column
            label="自定义结算日清单"
            align="center"
            prop="isDailyCutoff"
          >
            <template #default="scope">
              <el-tag
                :type="
                  yes_or_no.find(
                    (item) => item.value === scope.row.isDailyCutoff
                  )?.type
                "
                size="small"
              >
                {{
                  yes_or_no.find(
                    (item) => item.value === scope.row.isDailyCutoff
                  )?.label
                }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column label="计费状态" align="center" prop="chargeStatus">
            <template #default="scope">
              <el-tag
                :type="
                  charge_status.find(
                    (item) => item.value === scope.row.chargeStatus
                  )?.type
                "
                size="small"
              >
                {{
                  charge_status.find(
                    (item) => item.value === scope.row.chargeStatus
                  )?.label
                }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column
            label="结果"
            align="center"
            prop="chargeResult"
            show-overflow-tooltip
          />
          <el-table-column label="操作" align="center" width="300px">
            <template #default="scope">
              <div class="opacity">
                <p
                  v-if="scope.row.chargeStatus == 0"
                  class="btn"
                  @click="handleAdd(scope.row)"
                >
                  补充电费单信息
                </p>
                <p
                  v-if="
                    scope.row.chargeStatus == 1 ||
                    scope.row.chargeStatus == 2 ||
                    scope.row.chargeStatus == 5
                  "
                  class="btn"
                  @click="handleUpdate(scope.row)"
                >
                  修改电费单信息
                </p>
                <p
                  v-if="scope.row.chargeStatus == 1"
                  class="btn"
                  @click="handleConfirmDetail(scope.row)"
                >
                  确认
                </p>
                <p
                  v-if="
                    scope.row.chargeStatus == 2 || scope.row.chargeStatus == 5
                  "
                  class="btn"
                  @click="handleCharging(scope.row)"
                >
                  开始计费
                </p>
                <p
                  v-if="scope.row.chargeStatus == 4"
                  class="btn"
                  @click="handleExport(scope.row)"
                >
                  导出数据
                </p>
                <!-- <p class="btn" @click="handleUpdate(scope.row)">修改</p> -->
                <!-- <p class="btn delClass" @click="handleDelete(scope.row)">
                  删除
                </p> -->
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pag">
          <el-pagination
            v-model:page-size="queryParams.pageSize"
            :page-sizes="[10, 20, 50, 100]"
            :total="total"
            background
            layout="total,sizes,prev, pager, next"
            small
            @size-change="handleSizeChange"
            @current-change="pageChange"
          />
        </div>
      </div>
    </div>

    <el-dialog
      :title="generateTitle"
      v-model="generateOpen"
      :close-on-click-modal="false"
      width="500px"
      append-to-body
    >
      <el-form
        ref="generateFormRef"
        :model="generateForm"
        :rules="generateRules"
        label-width="auto"
      >
        <el-form-item label="自定义结算日清单" prop="isDailyCutoff">
          <el-switch
            v-model="generateForm.isDailyCutoff"
            active-text="是"
            inactive-text="否"
            :active-value="1"
            :inactive-value="0"
          ></el-switch>
        </el-form-item>
        <el-form-item
          v-if="generateForm.isDailyCutoff === 0"
          label="自然月时间"
          prop="defaultDate"
        >
          <el-switch
            v-model="generateForm.defaultDate"
            active-text="是"
            inactive-text="否"
            :active-value="1"
            :inactive-value="0"
          ></el-switch>
        </el-form-item>
        <el-form-item
          v-if="
            generateForm.defaultDate === 0 && generateForm.isDailyCutoff === 0
          "
          label="计费日期"
          prop="chargeDate"
        >
          <el-date-picker
            v-model="generateForm.chargeDate"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            clearable
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            @change="handleChargeDateChange"
          ></el-date-picker>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button class="cancle resetButton" @click="generateCancel"
            >取消</el-button
          >
          <el-button class="confrim" type="primary" @click="submitGenerateForm"
            >确定</el-button
          >
        </span>
      </template>
    </el-dialog>

    <el-dialog :title="title" v-model="open" width="1200px" append-to-body>
      <el-form
        ref="chargeFormRef"
        :model="form"
        :rules="rules"
        label-width="auto"
      >
        <el-row :guuter="20">
          <el-col :span="12">
            <el-form-item label="上传电费单">
              <div style="display: flex; flex-direction: column">
                <el-image
                  v-if="imgSrc"
                  :src="imgSrc"
                  :preview-src-list="[imgSrc]"
                  style="width: 200px"
                />

                <div>
                  <el-button @click="openUpload">上传</el-button>
                </div>
              </div>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="尖峰电量" prop="peakPowerConsumption">
              <el-input
                v-model="form.peakPowerConsumption"
                placeholder="请输入尖峰电量"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="尖-电费单价" prop="peakPeakPricePerUnit">
              <el-input
                v-model="form.peakPeakPricePerUnit"
                placeholder="请输入尖-电费单价"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="峰-电费单价" prop="peakPricePerUnit">
              <el-input
                v-model="form.peakPricePerUnit"
                placeholder="请输入峰-电费单价"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="平-电费单价" prop="flatPricePerUnit">
              <el-input
                v-model="form.flatPricePerUnit"
                placeholder="请输入平-电费单价"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="谷-电费单价" prop="valleyPricePerUnit">
              <el-input
                v-model="form.valleyPricePerUnit"
                placeholder="请输入谷-电费单价"
                clearable
              />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="光伏柜电量" prop="pvCabinetPower">
              <el-input
                v-model="form.pvCabinetPower"
                placeholder="请输入光伏柜电量"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="光伏柜电价" prop="pvCabinetPrice">
              <el-input
                v-model="form.pvCabinetPrice"
                placeholder="请输入光伏柜电价"
                clearable
              />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="电站光伏电量" prop="stationPvPower">
              <el-input
                v-model="form.stationPvPower"
                placeholder="请输入电站光伏电量"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="光伏返网电量" prop="pvReturnToGridPower">
              <el-input
                v-model="form.pvReturnToGridPower"
                placeholder="请输入光伏返网电量"
                clearable
              />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="电费单合计金额" prop="totalBillAmount">
              <el-input
                v-model="form.totalBillAmount"
                placeholder="请输入电费单合计金额"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="基建费用" prop="infrastructureCost">
              <el-input
                v-model="form.infrastructureCost"
                placeholder="请输入基建费用"
                clearable
              />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="是否展示电损" prop="isCalculatingElectricLoss">
              <el-radio-group v-model="form.isCalculatingElectricLoss">
                <el-radio
                  v-for="dict in yes_or_no"
                  :key="dict.value"
                  :label="dict.value"
                  >{{ dict.label }}</el-radio
                >
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="是否公摊" prop="publicSharing">
              <el-radio-group v-model="form.publicSharing">
                <el-radio
                  v-for="dict in yes_or_no"
                  :key="dict.value"
                  :label="dict.value"
                  >{{ dict.label }}</el-radio
                >
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col v-if="form.publicSharing === 1" :span="12">
            <el-form-item label="公摊电表" prop="meterId">
              <el-select
                v-model="form.meterId"
                placeholder="请选择公摊电表"
                clearable
                style="width: 100%"
              >
                <el-option
                  v-for="item in formLists.meterList"
                  :key="item.meterId"
                  :label="item.meterName"
                  :value="item.meterId"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item
              label="是否有充电桩"
              prop="isChargingStationAvailable"
            >
              <el-radio-group v-model="form.isChargingStationAvailable">
                <el-radio
                  v-for="dict in yes_or_no"
                  :key="dict.value"
                  :label="dict.value"
                  >{{ dict.label }}</el-radio
                >
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="form.isChargingStationAvailable === 1">
            <el-form-item
              label="储能储存反网电量"
              prop="storedReverseGridEnergy"
            >
              <el-input
                v-model="form.storedReverseGridEnergy"
                placeholder="请输入储能储存反网电量"
                clearable
              />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="8" v-if="form.isChargingStationAvailable === 1">
            <el-form-item
              label="储能正向充电-尖电价"
              prop="chargingSharpElectricityPrice"
            >
              <el-input
                v-model="form.chargingSharpElectricityPrice"
                placeholder="请输入储能正向充电-尖电价"
                clearable
              />
            </el-form-item>
            <el-form-item
              label="储能正向充电-峰电价"
              prop="chargingPeakElectricityPrice"
            >
              <el-input
                v-model="form.chargingPeakElectricityPrice"
                placeholder="请输入储能正向充电-峰电价"
                clearable
              />
            </el-form-item>
            <el-form-item
              label="储能正向充电-平电价"
              prop="chargingFlatElectricityPrice"
            >
              <el-input
                v-model="form.chargingFlatElectricityPrice"
                placeholder="请输入储能正向充电-平电价"
                clearable
              />
            </el-form-item>
            <el-form-item
              label="储能正向充电-谷电价"
              prop="chargingValleyElectricityPrice"
            >
              <el-input
                v-model="form.chargingValleyElectricityPrice"
                placeholder="请输入储能正向充电-谷电价"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="8" v-if="form.isChargingStationAvailable === 1">
            <el-form-item
              label="储能反向放电-尖电价"
              prop="dischargingSharpElectricityPrice"
            >
              <el-input
                v-model="form.dischargingSharpElectricityPrice"
                placeholder="请输入储能反向放电-尖电价"
                clearable
              />
            </el-form-item>
            <el-form-item
              label="储能反向放电-峰电价"
              prop="dischargingPeakElectricityPrice"
            >
              <el-input
                v-model="form.dischargingPeakElectricityPrice"
                placeholder="请输入储能反向放电-峰电价"
                clearable
              />
            </el-form-item>
            <el-form-item
              label="储能反向放电-平电价"
              prop="dischargingFlatElectricityPrice"
            >
              <el-input
                v-model="form.dischargingFlatElectricityPrice"
                placeholder="请输入储能反向放电-平电价"
                clearable
              />
            </el-form-item>
            <el-form-item
              label="储能反向放电-谷电价"
              prop="dischargingValleyElectricityPrice"
            >
              <el-input
                v-model="form.dischargingValleyElectricityPrice"
                placeholder="请输入储能反向放电-谷电价"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="8" v-if="form.isChargingStationAvailable === 1">
            <el-form-item
              label="充电桩-尖电价"
              prop="chargingStationSharpElectricityPrice"
            >
              <el-input
                v-model="form.chargingStationSharpElectricityPrice"
                placeholder="请输入充电桩-尖电价"
                clearable
              />
            </el-form-item>
            <el-form-item
              label="充电桩-峰电价"
              prop="chargingStationPeakElectricityPrice"
            >
              <el-input
                v-model="form.chargingStationPeakElectricityPrice"
                placeholder="请输入充电桩-峰电价"
                clearable
              />
            </el-form-item>
            <el-form-item
              label="充电桩-平电价"
              prop="chargingStationFlatElectricityPrice"
            >
              <el-input
                v-model="form.chargingStationFlatElectricityPrice"
                placeholder="请输入充电桩-平电价"
                clearable
              />
            </el-form-item>
            <el-form-item
              label="充电桩-谷电价"
              prop="chargingStationValleyElectricityPrice"
            >
              <el-input
                v-model="form.chargingStationValleyElectricityPrice"
                placeholder="请输入充电桩-谷电价"
                clearable
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" @click="submitForm">确 定</el-button>
          <el-button @click="cancel">取 消</el-button>
        </div>
      </template>
    </el-dialog>

    <el-dialog
      :title="tableTitle"
      v-model="tableOpen"
      width="1000px"
      append-to-body
    >
      <table style="width: 100%; border: none">
        <caption style="font-size: 24px; text-align: center">
          {{
            tenantChargeDetail.startChargeDate
          }}
          ~
          {{
            tenantChargeDetail.endChargeDate
          }}
          {{
            tenantChargeDetail.excelTitle
          }}
        </caption>
        <thead>
          <tr style="border: none">
            <td colspan="2" style="border: none"></td>
            <td colspan="5" style="border: none">
              户名：{{ tenantChargeDetail.userName }}
            </td>
            <!-- <td style="border: none">
              {{ tenantChargeDetail.startChargeDate }} ~
              {{ tenantChargeDetail.endChargeDate }}
            </td> -->
          </tr>
          <tr style="border: none">
            <td colspan="12" style="border: none">
              用户单位：{{ tenantChargeDetail.landlordName }}
            </td>
          </tr>
          <tr style="text-align: center">
            <td colspan="2"></td>
            <td>本月止度</td>
            <td>上月示度</td>
            <td style="width: 50px">倍率</td>
            <td>电量</td>
            <td>电价</td>
            <td>实付电费</td>
          </tr>
        </thead>
        <tbody style="text-align: center">
          <tr
            v-for="(item, index) in tenantChargeDetail.resultDetailVOList"
            :key="index"
          >
            <td colspan="2">{{ item.chargeItemName }}</td>
            <td>{{ item.currentMonthReading }}</td>
            <td>{{ item.previousMonthReading }}</td>
            <td>{{ item.multiplicationFactor }}</td>
            <td>
              {{ item.electricityAmount }}
            </td>
            <td>{{ item.electricityPrice }}</td>
            <td>{{ item.actualPaidAmount }}</td>
          </tr>
        </tbody>
        <tfoot style="text-align: center">
          <tr>
            <td style="width: 50px">已收金额</td>
            <td>{{ tenantChargeDetail.amountReceived }}</td>
            <td>上次结余</td>
            <td>{{ tenantChargeDetail.tenantLastBalance }}</td>
            <td>本次结余</td>
            <td colspan="2">{{ tenantChargeDetail.tenantCurrentBalance }}</td>
            <td>{{ tenantChargeDetail.actualPaidAmount }}</td>
          </tr>
        </tfoot>
      </table>
      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" @click="tableSubmitForm">确 定</el-button>
          <el-button @click="tableCancel">取 消</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, reactive, toRefs, h } from 'vue'
import './charge.less'
import { ElMessage, ElMessageBox } from 'element-plus'
import {
  listCharge,
  getCharge,
  delCharge,
  generateCharge,
  cutoffCharge,
  updateCharge,
  confirmCharge,
  startCharge,
  getTenantCharge,
  tenantPayment,
} from '@/api/modules/charge'
import { saveAs } from 'file-saver'
import { http } from '@/api'

const chargeList = ref([])
const open = ref(false)
const loading = ref(true)
const total = ref(0)
const title = ref('')
const chargeFormRef = ref(null)

const generateTitle = ref('')
const generateOpen = ref(false)
const generateFormRef = ref(null)
const generateForm = ref({
  isDailyCutoff: 0,
  defaultDate: 1,
  chargeDate: null,
})
const generateRules = reactive({
  isDailyCutoff: [
    {
      required: true,
      message: '请选择自定义结算日清单',
      trigger: 'change',
    },
  ],
  defaultDate: [
    {
      required: true,
      message: '请选择自然月时间',
      trigger: 'change',
    },
  ],
  chargeDate: [
    {
      required: true,
      message: '请选择计费日期',
      trigger: 'change',
    },
  ],
})

const formLists = reactive({
  meterList: [],
})

const imgSrc = ref('')

const tableTitle = ref('')
const tableOpen = ref(false)
const tenantChargeDetail = ref({
  startChargeDate: '',
  endChargeDate: '',
  excelTitle: '',
  userName: '',
  landlordName: '',
  amountReceived: '',
  tenantLastBalance: '',
  tenantCurrentBalance: '',
  actualPaidAmount: '',
  resultDetailVOList: [],
})

const charge_status = ref([
  { label: '待补充信息', value: 0, type: 'warning' },
  { label: '待确认', value: 1, type: 'primary' },
  { label: '确认通过', value: 2, type: 'warning' },
  { label: '处理中', value: 3, type: 'warning' },
  { label: '已完成', value: 4, type: 'success' },
  { label: '处理失败', value: 5, type: 'danger' },
])
const yes_or_no = ref([
  { label: '否', value: 0, type: 'danger' },
  { label: '是', value: 1, type: 'success' },
])

const data = reactive({
  form: {},
  queryParams: {
    pageNumber: 1,
    pageSize: 10,
    landlordName: null,
  },
  rules: {
    chargeYearMonth: [
      {
        required: true,
        message: '请选择计费清单月份',
        trigger: 'change',
      },
    ],
    peakPeakPricePerUnit: [
      { required: true, message: '请输入尖-电费单价', trigger: 'blur' },
    ],
    peakPricePerUnit: [
      { required: true, message: '请输入峰-电费单价', trigger: 'blur' },
    ],
    flatPricePerUnit: [
      { required: true, message: '请输入平-电费单价', trigger: 'blur' },
    ],
    valleyPricePerUnit: [
      { required: true, message: '请输入谷-电费单价', trigger: 'blur' },
    ],
    pvCabinetPower: [
      { required: true, message: '请输入光伏柜电量', trigger: 'blur' },
    ],
    stationPvPower: [
      { required: true, message: '请输入电站光伏电量', trigger: 'blur' },
    ],
    pvReturnToGridPower: [
      { required: true, message: '请输入光伏返网电量', trigger: 'blur' },
    ],
    infrastructureCost: [
      { required: true, message: '请输入基建费用', trigger: 'blur' },
    ],
    peakPowerConsumption: [
      { required: true, message: '请输入尖峰电量', trigger: 'blur' },
    ],
    totalBillAmount: [
      { required: true, message: '请输入电费单上的合计金额', trigger: 'blur' },
    ],
    pvCabinetPrice: [
      { required: true, message: '请输入光伏柜电价', trigger: 'blur' },
    ],
    publicSharing: [
      { required: true, message: '请选择是否公摊', trigger: 'change' },
    ],
    isCalculatingElectricLoss: [
      { required: true, message: '请选择是否展示电损', trigger: 'change' },
    ],
    meterId: [{ required: true, message: '请选择公摊电表', trigger: 'change' }],
    isChargingStationAvailable: [
      { required: true, message: '请选择是否有充电桩', trigger: 'change' },
    ],
    storedReverseGridEnergy: [
      { required: true, message: '请输入储能储存反网电量', trigger: 'change' },
    ],
    chargingSharpElectricityPrice: [
      {
        required: true,
        message: '请输入储能正向充电-尖电价',
        trigger: 'change',
      },
    ],
    chargingPeakElectricityPrice: [
      {
        required: true,
        message: '请输入储能正向充电-峰电价',
        trigger: 'change',
      },
    ],
    chargingFlatElectricityPrice: [
      {
        required: true,
        message: '请输入储能正向充电-平电价',
        trigger: 'change',
      },
    ],
    chargingValleyElectricityPrice: [
      {
        required: true,
        message: '请输入储能正向充电-谷电价',
        trigger: 'change',
      },
    ],
    dischargingSharpElectricityPrice: [
      {
        required: true,
        message: '请输入储能反向放电-尖电价',
        trigger: 'change',
      },
    ],
    dischargingPeakElectricityPrice: [
      {
        required: true,
        message: '请输入储能反向放电-峰电价',
        trigger: 'change',
      },
    ],
    dischargingFlatElectricityPrice: [
      {
        required: true,
        message: '请输入储能反向放电-平电价',
        trigger: 'change',
      },
    ],
    dischargingValleyElectricityPrice: [
      {
        required: true,
        message: '请输入储能反向放电-谷电价',
        trigger: 'change',
      },
    ],
    chargingStationSharpElectricityPrice: [
      {
        required: true,
        message: '请输入充电桩-尖电价',
        trigger: 'change',
      },
    ],
    chargingStationPeakElectricityPrice: [
      {
        required: true,
        message: '请输入充电桩-峰电价',
        trigger: 'change',
      },
    ],
    chargingStationFlatElectricityPrice: [
      {
        required: true,
        message: '请输入充电桩-平电价',
        trigger: 'change',
      },
    ],
    chargingStationValleyElectricityPrice: [
      {
        required: true,
        message: '请输入充电桩-谷电价',
        trigger: 'change',
      },
    ],
  },
})

const { queryParams, form, rules } = toRefs(data)

/** 查询房东列表 */
function getList() {
  loading.value = true
  listCharge(queryParams.value).then((response) => {
    chargeList.value = response.data.result.list
    total.value = Number(response.data.result.total)
    loading.value = false
  })
}

// 取消按钮
function cancel() {
  open.value = false
  reset()
}

// 表单重置
function reset() {
  form.value = {
    id: null,
    chargeYearMonth: null,
    meterPriceDetailId: null,
    peakPricePerUnit: null,
    flatPricePerUnit: null,
    valleyPricePerUnit: null,
    peakPeakPricePerUnit: null,
    pvCabinetPower: null,
    stationPvPower: null,
    pvReturnToGridPower: null,
    infrastructureCost: null,
    peakPowerConsumption: null,
    totalBillAmount: null,
    pvCabinetPrice: null,
    publicSharing: null,
    meterId: null,
    isChargingStationAvailable: null,
    storedReverseGridEnergy: null,
    chargingSharpElectricityPrice: null,
    chargingPeakElectricityPrice: null,
    chargingFlatElectricityPrice: null,
    chargingValleyElectricityPrice: null,
    dischargingSharpElectricityPrice: null,
    dischargingPeakElectricityPrice: null,
    dischargingFlatElectricityPrice: null,
    dischargingValleyElectricityPrice: null,
    chargingStationSharpElectricityPrice: null,
    chargingStationPeakElectricityPrice: null,
    chargingStationFlatElectricityPrice: null,
    chargingStationValleyElectricityPrice: null,
    isCalculatingElectricLoss: null,
  }
  chargeFormRef.value?.resetFields()
}

/** 搜索按钮操作 */
function handleQuery() {
  queryParams.value.pageNumber = 1
  getList()
}

/** 重置按钮操作 */
function resetQuery() {
  queryParams.value = {
    pageNumber: 1,
    pageSize: 10,
    name: null,
  }
  handleQuery()
}

/** 新增按钮操作 */
function handleAdd(row) {
  reset()
  form.value.id = row.id
  open.value = true
  title.value = '补充电费单信息'
}

/** 修改按钮操作 */
function handleUpdate(row) {
  reset()
  getCharge(row.id).then((res) => {
    form.value = res.data.result
    open.value = true
    title.value = '修改电费单信息'
  })
}

/** 提交按钮 */
function submitForm() {
  chargeFormRef.value.validate((valid) => {
    if (valid) {
      updateCharge(form.value).then((response) => {
        if (response.data.code === 0) {
          ElMessage.success('操作成功')
          open.value = false
          getList()
        } else {
          ElMessage({
            type: 'error',
            message: response.data.message,
          })
        }
      })
    }
  })
}

/** 删除按钮操作 */
function handleDelete(row) {
  ElMessageBox.confirm('确认删除吗，删除后不可恢复，请谨慎操作', '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '删除计费清单',
    message: h('p', null, [
      h(
        'img',
        {
          src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
          style:
            'width:20px;height:20px;transform: translateY(4px);margin-right:8px;',
        },
        ''
      ),
      h(
        'span',
        {
          style: 'color:#595959;',
        },
        '请确认是否要删除此计费清单，删除后数据不可恢复'
      ),
    ]),
  })
    .then(function () {
      return delCharge(row.id)
    })
    .then((response) => {
      if (response.data.code === 0) {
        getList()
        ElMessage.success('删除成功')
      } else {
        ElMessage({
          type: 'error',
          message: response.data.message,
        })
      }
    })
    .catch(() => {
      ElMessage({
        message: '已取消删除',
        type: 'info',
      })
    })
}

getList()

function handleSizeChange(e) {
  queryParams.value.pageSize = e
  getList()
}

function pageChange(e) {
  queryParams.value.pageNumberberberber = e
  getList()
}

function handleChargeDateChange(e) {
  if (e) {
    form.value.startChargeDate = e[0]
    form.value.endChargeDate = e[1]
  } else {
    form.value.startChargeDate = null
    form.value.endChargeDate = null
  }
}

function handleGenerate() {
  generateReset()
  generateOpen.value = true
  generateTitle.value = '生成结算清单'
}

function generateReset() {
  generateForm.value = {
    isDailyCutoff: 0,
    defaultDate: 1,
    chargeDate: null,
  }
  generateFormRef.value?.resetFields()
}

function generateCancel() {
  generateOpen.value = false
  generateReset()
}

function submitGenerateForm() {
  generateFormRef.value.validate((valid) => {
    if (valid) {
      if (generateForm.value.isDailyCutoff === 0) {
        generateCharge(generateForm.value).then((response) => {
          if (response.data.code === 0) {
            ElMessage.success('生成成功')
            generateOpen.value = false
            getList()
          } else {
            ElMessage({
              type: 'error',
              message: response.data.message,
            })
          }
        })
      } else {
        cutoffCharge().then((response) => {
          if (response.data.code === 0) {
            ElMessage.success('生成成功')
            generateOpen.value = false
            getList()
          } else {
            ElMessage({
              type: 'error',
              message: response.data.message,
            })
          }
        })
      }
    }
  })
}

function openUpload() {}

function handleView(row) {
  tableReset()
  getTenantCharge(row.id).then((response) => {
    tenantChargeDetail.value = response.data.result
    tableOpen.value = true
    tableTitle.value = '查看详情'
  })
}

function tableSubmitForm() {
  tableOpen.value = false
}

function tableReset() {
  tenantChargeDetail.value = {
    startChargeDate: '',
    endChargeDate: '',
    excelTitle: '',
    userName: '',
    landlordName: '',
    amountReceived: '',
    tenantLastBalance: '',
    tenantCurrentBalance: '',
    actualPaidAmount: '',
    resultDetailVOList: [],
  }
}

function tableCancel() {
  tableOpen.value = false
  tableReset()
}

function handleConfirmDetail(row) {
  ElMessageBox.confirm('确认提交吗', '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '确认',
    message: h('p', null, [
      h(
        'img',
        {
          src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
          style:
            'width:20px;height:20px;transform: translateY(4px);margin-right:8px;',
        },
        ''
      ),
      h(
        'span',
        {
          style: 'color:#595959;',
        },
        '是否审核通过该记录？'
      ),
    ]),
  })
    .then(function () {
      return confirmCharge(row.id)
    })
    .then((response) => {
      if (response.data.code === 0) {
        getList()
        ElMessage.success('审核通过成功')
      } else {
        ElMessage({
          type: 'error',
          message: response.data.message,
        })
      }
    })
    .catch(() => {
      ElMessage({
        message: '已取消审核',
        type: 'info',
      })
    })
}

function handleCharging(row) {
  ElMessageBox.confirm('确认提交吗', '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '确认',
    message: h('p', null, [
      h(
        'img',
        {
          src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
          style:
            'width:20px;height:20px;transform: translateY(4px);margin-right:8px;',
        },
        ''
      ),
      h(
        'span',
        {
          style: 'color:#595959;',
        },
        '是否开始计费？'
      ),
    ]),
  })
    .then(function () {
      return startCharge(row.id)
    })
    .then((response) => {
      if (response.data.code === 0) {
        getList()
        ElMessage.success('开始计费成功')
      } else {
        ElMessage({
          type: 'error',
          message: response.data.message,
        })
      }
    })
    .catch(() => {
      ElMessage({
        message: '已取消开始计费',
        type: 'info',
      })
    })
}

function handlePay(row) {
  ElMessageBox.confirm('确认提交吗', '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '确认',
    message: h('p', null, [
      h(
        'img',
        {
          src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
          style:
            'width:20px;height:20px;transform: translateY(4px);margin-right:8px;',
        },
        ''
      ),
      h(
        'span',
        {
          style: 'color:#595959;',
        },
        '是否确认缴费？'
      ),
    ]),
  })
    .then(function () {
      return tenantPayment(row.id)
    })
    .then((response) => {
      if (response.data.code === 0) {
        getList()
        ElMessage.success('缴费成功')
      } else {
        ElMessage({
          type: 'error',
          message: response.data.message,
        })
      }
    })
    .catch(() => {
      ElMessage({
        message: '已取消缴费',
        type: 'info',
      })
    })
}

function handleExport(row) {
  saveAs(
    `${http}zouk/billing/charge/export?id=${
      row.id
    }&token=${localStorage.getItem('Authorization')}`,
    `${row.landlordName}${row.startChargeDate}~${row.endChargeDate}电费清单.xlsx`
  )
}
</script>

<style scoped>
table,
td,
th {
  border: 1px solid #595959;
  border-collapse: collapse;
}
td,
th {
  padding: 3px;
  width: 30px;
  height: 25px;
}
</style>
