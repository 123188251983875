<template>
  <div class="projectDevice">
    <div class="title">
      <el-tabs v-model="activeName" class="demo-tabs" @tab-change="handleTabClick">
        <el-tab-pane :name="1" label="网关设备"></el-tab-pane>
        <el-tab-pane v-if="isOpenDevice||custerObj.nodeType==2" :name="3" label="直连设备"></el-tab-pane>
      </el-tabs>
    </div>
    <div class="content">
      <div class="searchBox">
        <el-form>
          <div class="searchPox">
            <div v-if="activeName===1" class="liBox">
              <el-form-item label="产品型号">
                <el-input v-model="productModel" autocomplete="off" clearable placeholder='请输入产品型号'
                          type="text"></el-input>
              </el-form-item>
            </div>
            <div class="liBox">
              <el-form-item label="设备名称">
                <el-input v-model="name" autocomplete="off" clearable placeholder='请输入设备名称' type="text"
                          @change="nameChange"></el-input>
              </el-form-item>
            </div>
            <div v-if="activeName===1" class="liBox">
              <el-form-item label="通讯地址">
                <el-input v-model="address" autocomplete="off" clearable placeholder='请输入通讯地址' type="text"></el-input>
              </el-form-item>
            </div>
            <div v-if="activeName===1" class="liBox">
              <el-form-item label="设备状态">
                <el-select v-model="useStatus" clearable placeholder="请选择" style="width:100%" @change="getListFun">
                  <el-option label="全部" value=""></el-option>
                  <el-option label="在线" value="1"></el-option>
                  <el-option label="离线" value="0"></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="liBox searchBtn">
              <el-button class="searchButton" type="primary" @click="getListFun">查询</el-button>
              <el-button :disabled="custerObj.nodeType==2?true:false" :icon="Plus" type="primary" @click="addClick()">
                新建设备
              </el-button>
              <el-button type="primary">
                <el-checkbox v-model="getSubFlag" label="" size="large" @change="showNextLevel"/>
                显示下级
              </el-button>
              <el-button :disabled="custerObj.nodeType==2?true:false" type="primary" @click="bindClick()">绑定设备
              </el-button>
              <el-button :disabled="custerObj.nodeType==2?true:false" @click="removeClick()">批量解绑</el-button>
              <el-button @click="enterFullScreen()">全屏展示</el-button>
            </div>
          </div>

        </el-form>
      </div>
      <div class="tableBox">
        <!--列表-->
        <div class="tableBox">
          <el-table ref="multipleTableRef" v-loading='deviceLoading' :data="deviceList" :row-key="rowKey2"
                    style="width: 100%" table-layout="auto"
                    @selection-change="handleSelectionChange">
            <template #empty>
              <el-empty class="emptyClass" description="暂无数据信息" image="
https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
                <p>您可以在此页面绑定设备</p>
              </el-empty>
            </template>
            <el-table-column :reserve-selection="true" type="selection" width="55"/>
            <el-table-column label="设备名称" min-width="150px" prop="equipmentName"/>
            <el-table-column label="设备地址" min-width="150px" prop="equipmentAddress"/>
            <el-table-column label="网关" min-width="150px" prop="gatewayAddress"/>
            <el-table-column label="状态" min-width="150px" property="useStatusStr">
              <template #default="scope">
                <div v-show="scope.row.useStatus==1" class='statusOnClass'>
                  <div class="dotOnClass"></div>
                  {{ scope.row.useStatusStr }}
                </div>
                <div v-show="scope.row.useStatus==0" class='statusOutClass'>
                  <div class="dotOutClass"></div>
                  {{ scope.row.useStatusStr }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="产品型号" min-width="150px" prop="productModel"/>
            <el-table-column label="创建人" min-width="150px" prop="creatorName"/>
            <el-table-column label="最后通讯时间" min-width="150px" prop="createTime" sortable/>
            <el-table-column :width="activeName===1?120:180" fixed="right" label="操作">
              <template #default="scope">
                <ul v-if="activeName===3" class="operationUl">
                  <li v-show="custerObj.nodeType!=2">
                    <el-tooltip :content="scope.row.switch?'停止':'启动'" class="box-item" effect="dark">
                      <el-switch v-model="scope.row.switch" style="--el-switch-on-color: #ccefe3;"
                                 @change="isOpenChange($event,scope.row)"/>
                    </el-tooltip>
                  </li>
                  <li class="editClass" @click="managementClick(scope.row.id)">
                    <el-tooltip content="数据" effect="light" placement="top">
                      <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/projectOpear2.png">
                    </el-tooltip>
                  </li>
                  <li class="editClass" @click="eleSetClick(scope.row)">
                    <el-tooltip content="编辑" effect="light" placement="top">
                      <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/projectOpear1.png">
                    </el-tooltip>
                  </li>
                  <li v-show="custerObj.nodeType!=2" class="editClass" @click="editClick(scope.row)">编辑</li>
                  <li v-show="custerObj.nodeType!=2">
                    <el-popover popper-class="moreOpera" trigger="click">
                      <div class="listPox">
                        <p @click='deviceDetailClick(2,scope.row.equipmentName,scope.row.plugin2,scope.row.id)'>
                          <span>设备配置</span>
                        </p>
                        <p @click="delClick(scope.row.equipmentName,scope.row.id)">
                          <span>删除</span>
                        </p>
                      </div>
                      <template #reference>
                        <p class="more">
                          <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/projectOpear3.png">
                        </p>
                      </template>
                    </el-popover>
                  </li>
                </ul>
                <ul v-if="activeName===1" class="operationUl">
                  <li v-show="custerObj.nodeType!=2" class="editClass" @click="addClick(scope.row.id)">
                    <el-tooltip content="编辑" effect="light" placement="top">
                      <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/projectOpear1.png">
                    </el-tooltip>
                  </li>
                  <li class="editClass" @click="managementClick(scope.row.id)">
                    <el-tooltip content="数据" effect="light" placement="top">
                      <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/projectOpear2.png">
                    </el-tooltip>
                  </li>
                  <li class="editClass">
                    <el-popover popper-class="moreOpera2" trigger="hover">
                      <div class="listPox">
                        <p @click='goWarnFun(scope.row.id)'>报警</p>
                        <p v-show="custerObj.nodeType!=2" @click="deleteClick(scope.row.id)">删除</p>
                      </div>
                      <template #reference>
                        <p class="more">
                          <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/projectOpear3.png">
                        </p>
                      </template>
                    </el-popover>
                  </li>
                </ul>
              </template>
            </el-table-column>
          </el-table>
          <div class="footerBox">
            <el-pagination :disabled="disabled" :page-size="pageSize" :page-sizes="[20,100, 200, 300, 400]"
                           :total="total" background layout="total,sizes, prev, pager, next" small
                           @size-change="handleSizeChange" @current-change="handleCurrentChange"/>
          </div>
        </div>
      </div>
    </div>

    <!--设备类型弹窗-->
    <el-dialog v-model="typeDialogVisible" :close-on-click-modal="false" :title="title" class="typeDialogVisible"
               width="544px">
      <div class="selectPox">
        <!--        <el-radio-group v-model="deviceType">-->
        <!--          <el-radio-button label="网关设备" value="网关设备"/>-->
        <!--          <el-radio-button label="直连设备" value="直连设备"/>-->
        <!--        </el-radio-group>-->
        <div :class="deviceType==='网关设备'?'activePox pox':'pox'" @click="deviceType='网关设备'">
          <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/deviceSelect1.png">
          <p>网关设备</p>
        </div>
        <div :class="deviceType==='直连设备'?'activePox pox':'pox'" @click="deviceType='直连设备'">
          <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/deviceSelect2.png">
          <p>直连设备</p>
        </div>
      </div>
      <template #footer>
				<span class="dialog-footer">
		    		<el-button class="resetButton" @click="typeDialogVisible = false">取消</el-button>
		    		<el-button type="primary" @click="deviceTypeFun">确认</el-button>
      			</span>
      </template>
    </el-dialog>
    <!--新增设备弹窗-->
    <el-dialog v-model="deviceDialogVisible" :close-on-click-modal="false" :title="title" :width="dialogWidth">
      <div>
        <el-form ref="deviceFormRef" :model="deviceForm" :rules="deviceRules" class="demo-ruleForm" label-width="auto"
                 style="width:98%">
          <div style="display: flex;">
            <div style="flex:1;margin-right: 30px;">
              <el-form-item label="产品类型" prop="categoryId">
                <el-select v-model="deviceForm.categoryId" clearable placeholder="请选择产品类型" style="width:100%"
                           @change="categoryChange(deviceForm.categoryId)">
                  <el-option v-for="item in allCategoryList" :key="item.id" :label="item.name" :value="item.id"
                             style="width:100%">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item v-if="categoryName=='电表'" label="电网费率" prop="powerGridRateId">
                <el-select v-model="deviceForm.powerGridRateId" clearable placeholder="请选择电网费率" style="width:100%">
                  <el-option v-for="item in powerGridRateData" :key="item.id" :label="item.versionNumber"
                             :value="item.id" style="width:100%">
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="设备名称">
                <el-input v-model="deviceForm.name" placeholder="请输入设备名称"/>
              </el-form-item>
              <el-form-item label="通讯地址" prop="address">
                <el-input v-model="deviceForm.address" placeholder="请输入通讯地址" @change="addressChange"/>
              </el-form-item>
              <el-form-item label="用户类型" prop="userType">
                <el-input v-model="deviceForm.userType" placeholder="请输入用户类型"/>
              </el-form-item>
              <el-form-item label="端口" prop="port">
                <el-select v-model="deviceForm.port" clearable placeholder="请选择端口" style="width:100%">
                  <el-option v-for="item in portList" :key="item.id" :label="item.name" :value="item.id"
                             style="width:100%">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="电流变比" prop="rateA">
                <el-input v-model="deviceForm.rateA" placeholder="请输入电流变比" type="number"/>
              </el-form-item>

              <el-form-item label="是否启用" prop="enableFlag">
                <el-select v-model="deviceForm.enableFlag" clearable filterable placeholder="请选择是否启用"
                           style="width:100%">
                  <el-option v-for="item in enableList" :key="item.id" :label="item.name" :value="item.id"
                             style="width:100%">
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div style="flex:1;">
              <el-form-item label="产品型号" prop="productId">
                <el-select v-model="deviceForm.productId" clearable placeholder="请选择产品型号" style="width:100%">
                  <el-option v-for="item in productList" :key="item.id" :label="item.name" :value="item.id"
                             style="width:100%">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="网关" prop="gatewayId">
                <el-select v-model="deviceForm.gatewayId" clearable filterable placeholder="请选择网关" style="width:100%">
                  <el-option v-for="item in gatewayList" :key="item.id" :label="item.name" :value="item.id"
                             style="width:100%">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="规约类型" prop="rulerType">
                <el-select v-model="deviceForm.rulerType" clearable placeholder="请选择规约类型" style="width:100%">
                  <el-option v-for="item in rulerTypeList" :key="item.id" :label="item.name" :value="item.id"
                             style="width:100%">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="波特率" prop="rateType">
                <el-select v-model="deviceForm.rateType" clearable placeholder="请选择波特率" style="width:100%">
                  <el-option v-for="item in rateTypeList" :key="item.id" :label="item.name" :value="item.id"
                             style="width:100%">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="电压变比" prop="rateV">
                <el-input v-model="deviceForm.rateV" placeholder="请输入电压变比" type="number"/>
              </el-form-item>
              <el-form-item label="备注">
                <el-input v-model="deviceForm.remark" placeholder="请输入备注"/>
              </el-form-item>
              <!--<el-form-item label="关联设备名称">
                <el-input v-model="deviceForm.relationName" placeholder="请输入关联设备名称" />
              </el-form-item>-->
            </div>
          </div>
        </el-form>
      </div>
      <template #footer>
				<span class="dialog-footer">
		    		<el-button class="resetButton" @click="deviceDialogVisible = false">取消</el-button>
		    		<el-button type="primary" @click="deviceSave">保存</el-button>
      			</span>
      </template>
    </el-dialog>
    <!--直连设备操作弹窗-->
    <!--添加弹窗-->
    <el-dialog v-model="dialogFormVisible" :close-on-click-modal="false" :title="title" class="dialogFormVisible"
               width="40%">
      <el-form ref="userForm" :inline="true" :model="formData" :rules="formRules" label-width="auto">
        <el-form-item label="名称" prop="name" style="margin-bottom: 20px">
          <el-input v-model="formData.name" placeholder="请输入应用名称"></el-input>
        </el-form-item>
        <el-form-item label="插件" prop="plugin">
          <el-select v-model="formData.plugin" clearable filterable placeholder="请选择插件" @change="selectChange">
            <el-option v-for="(item,index) in pluginData" :key="index" :label="item.name==='eKuiper'?'ZKData':item.name"
                       :value="item.name"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-form :inline="true" :model="formData.params" label-width="auto">
        <!--接口请求循环出来的表单数据-->
        <el-form-item v-for="(value, key, index) in formObj" :key="index" :prop="key"
                      :rules="[{
                        required: value.attribute==='required'&&(!value.condition||formData.params[value.condition.field]===value.condition.value),
                        message: `${value.type==='string'||value.type==='int'?'请输入':'请选择'}${value.name_zh}`,
                        trigger: 'blur',
                      }]" class="formItem">
          <div v-if="(!value.condition||formData.params[value.condition.field]===value.condition.value)&&value.name_zh"
               slot="label"
               class="labelBox" style="margin-top: 20px">
            <i v-if="value.attribute==='required'" style="color: red;">*</i>
            <p style="margin-left: 5px">{{ value.name_zh }}</p>
            <el-tooltip :content="value.description_zh" effect="dark" placement="top" popper-class="el_tooltip_item">
              <el-icon>
                <QuestionFilled/>
              </el-icon>
            </el-tooltip>
          </div>
          <div class="widthBox">
            <!--输入框-->
            <el-input
                v-if="value.type==='string'&&(!value.condition||formData.params[value.condition.field]===value.condition.value)"
                v-model="formData.params[key]" :maxlength="value.valid&&value.valid.length"></el-input>
            <!--下拉框-->
            <el-select
                v-if="value.type==='map'&&(!value.condition||formData.params[value.condition.field]===value.condition.value)"
                v-model="formData.params[key]" clearable>
              <el-option v-for="(item,index) in value.valid.map" :key="index" :label="item.key"
                         :value="item.value"></el-option>
            </el-select>
            <!--单选框-->
            <el-radio-group
                v-if="value.type==='bool'&&(!value.condition||formData.params[value.condition.field]===value.condition.value)"
                v-model="formData.params[key]">
              <el-radio :label="true" border>True</el-radio>
              <el-radio :label="false" border>False</el-radio>
            </el-radio-group>
            <!--数字输入框-->
            <el-input-number
                v-if="value.type==='int'&&(!value.condition||formData.params[value.condition.field]===value.condition.value)"
                v-model="formData.params[key]" :controls="false" :max="value.valid.max" :min="value.valid.min"/>
            <!--上传文件-->
            <el-upload
                v-if="value.type==='file'&&(!value.condition||formData.params[value.condition.field]===value.condition.value)"
                v-model:file-list="formData.params[key+'-fileList']" :auto-upload="false" action="#" class="upload-demo"
                limit="1" @change="uploadFile($event,key)">
              <div class="uploadBox">
                <div :class="formData.params[key+'-fileList']&&formData.params[key+'-fileList'].length>0?'none':''"
                     class="upBtnBox">
                  <el-icon>
                    <Document/>
                  </el-icon>
                  <span>点击上传</span>
                </div>
              </div>
            </el-upload>
          </div>
        </el-form-item>
      </el-form>
      <template #footer>
				<span class="dialog-footer">
        <el-button class="cancle resetButton" @click="dialogFormVisible=false">取消</el-button>
        <el-button class="confrim" type="primary" @click="save()">保存</el-button>
      </span>
      </template>
    </el-dialog>
    <!--编辑弹窗-->
    <el-dialog v-model="editDialogFormVisible" :close-on-click-modal="false" :title="title" width="40%">
      <el-form ref="userForm2" :inline="true" :model="formData2" :rules="formRules2" label-width="auto">
        <el-form-item label="名称" prop="name">
          <el-input v-model="formData2.name" placeholder="请输入设备名称"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
				<span class="dialog-footer">
        <el-button class="cancle resetButton" @click="editDialogFormVisible=false">取消</el-button>
        <el-button class="confrim" type="primary" @click="editSave()">保存</el-button>
      </span>
      </template>
    </el-dialog>
    <!--绑定弹窗-->
    <el-dialog v-model="bindDeviceDialog" title="绑定设备" width="70%">
      <el-input v-model="bDeviceName" clearable placeholder="请输入设备名称" style="width: 200px;margin:0 10px 10px 0"
                @input="getAllDeviceListFun"></el-input>
      <el-table ref="multipleTableRef2" v-loading='unBindDeviceLoading' :data="unBindDeviceList" :row-key="rowKey"
                style="width: 100%" table-layout="auto"
                @selection-change="handleSelectionChange2">
        <el-table-column :reserve-selection="true" type="selection" width="55"/>
        <el-table-column label="设备名称" prop="name"/>
        <el-table-column label="通讯地址" prop="address"/>
        <el-table-column label="产品型号" prop="productModel"/>
        <el-table-column label="创建人" prop="creatorName"/>
        <el-table-column label="创建时间" prop="createTime"/>
      </el-table>
      <el-pagination :page-size="bPageSize" :page-sizes="[20,100, 200, 300, 400]" :small="small" :total="bTotal"
                     background layout="total, prev, pager, next, sizes" small @size-change="bHandleSizeChange"
                     @current-change="bHandleCurrentChange"/>
      <template #footer>
				<span class="dialog-footer">
        <el-button class="resetButton" @click="bindDeviceDialog = false">取消</el-button>
        <el-button type="primary" @click="bindSubmit">确定</el-button>
     </span>
      </template>
    </el-dialog>
    <!--本地直连设备编辑电网费率弹窗-->
    <el-dialog v-model="editEleDialogFormVisible" :close-on-click-modal="false" :title="title" width="40%">
      <el-form ref="userForm3" :inline="true" :model="formData3" :rules="formRules3" label-width="auto">
        <el-form-item label="电网费率" prop="powerGridRateId" style="width: 100%">
          <el-select v-model="formData3.powerGridRateId" clearable placeholder="请选择电网费率" style="width:100%">
            <el-option v-for="item in powerGridRateData" :key="item.id" :label="item.versionNumber"
                       :value="item.id" style="width:100%">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
				<span class="dialog-footer">
        <el-button class="cancle resetButton" @click="editEleDialogFormVisible=false">取消</el-button>
        <el-button class="confrim" type="primary" @click="editEleSave()">保存</el-button>
      </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {defineProps, h, nextTick, onMounted, reactive, ref, toRefs, watch} from "vue"
import './projectDevice.less'
import {useRouter} from 'vue-router';
import {ElTable, ElMessage, ElMessageBox} from 'element-plus'
import {
  ArrowUpBold,
  ArrowDownBold,
  Plus,
  Search,
  CollectionTag,
  EditPen,
  DataAnalysis,
  Setting,
  Memo,
  Download,
  MoreFilled,
  Delete
} from '@element-plus/icons-vue'
import {
  getList,
  getAllCategory,
  getProductList,
  getSelectList,
  getGatewayList,
  getDetail,
  saveList,
  deleteList,
  powerGridRateList,
  updatePowerGridRateIdApi
} from '@/api/modules/device'
import {
  getNode,
  getPlugin,
  getState,
  ctl,
  driversList,
  driversControl,
  nodeDelete,
  levelControl,
  nodeEditSave,
  template,
  templateImport,
  saveSouthEquipmentApi
} from "@/api/modules/southDriver";
import {
  downloadLogApi,
  addDataApi,
  getFormDataApi,
} from "@/api/modules/northDriver";
import qs from 'qs';
import {
  bindDeviceListApi,
  getAllDeviceListApi,
  getDeviceListApi,
  getDeviceListLocalApi,
  removeDeviceListApi,
  getDeviceIsOpenApi
} from "@/api/modules/project";

const props = defineProps(['custerObj']);
let router = useRouter()
let route = useRouter()


// 选择网关设备或直连设备
let activeName = ref(1)
const handleTabClick = async function (tab, event) {
  pageNumber.value = 1
  pageSize.value = 20
  productModel.value = ''
  name.value = ''
  address.value = ''
  useStatus.value = ''
  await getListFun()
  if (activeName.value == 3 && custerObj.value.nodeType != 2) {
    await getPluginFun()
  }
}


//设备名称的改变
const nameChange = function () {
  window.localStorage.setItem('name', name.value)
}
let pageNumber = ref(1) //页码
let pageSize = ref(20) //页数
let total = ref(0) //总页数
let tableData = ref([]) //列表数据
//重置

//每页多少
const handleSizeChange = function (val) {
  pageSize.value = val;
  getListFun();
}
//更改页数
const handleCurrentChange = function (val) {
  pageNumber.value = val;
  getListFun();
}
let loadingVal = ref(false) //表格加载
let productModel = ref() //产品线型号
let name = ref() //设备名称
let address = ref() //通讯地址
let useStatus = ref('') //状态
let getSubFlag = ref(true) //状态
//获取设备列表
let deviceList = ref([])
const getListFun = async function () {
  loadingVal.value = true
  let params = {
    pageNumber: pageNumber.value,
    pageSize: pageSize.value,
    id: custerObj.value.id,
    getSubFlag: getSubFlag.value ? 1 : 0,
    productModel: productModel.value,
    equipmentName: name.value,
    equipmentAddress: address.value,
    useStatus: useStatus.value,
    type: activeName.value,
  }
  if (custerObj.value.nodeType === 2) {
    await getDeviceListLocalApi(qs.stringify(params)).then(res => {
      loadingVal.value = false
      if (res.data.ok) {
        deviceList.value = res.data.result.list
        total.value = Number(res.data.result.total)
      } else {
        ElMessage({
          message: res.data.message,
          type: 'error'
        })
      }
    }).catch(err => {
      console.log(err);
    })
  } else {
    await getDeviceListApi(qs.stringify(params)).then(res => {
      loadingVal.value = false
      if (res.data.ok) {
        deviceList.value = res.data.result.list
        total.value = Number(res.data.result.total)
        if (activeName.value == 3) {
          getStateFun()
        }
      } else {
        ElMessage({
          message: res.data.message,
          type: 'error'
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }
}
let allCategoryList = ref([]) //所有分类列表
//所有分类列表
const getAllCategoryFun = function () {
  getAllCategory(null).then(res => {
    if (res.data.ok) {
      allCategoryList.value = res.data.result
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
let productList = ref([]) //产品列表
//产品列表
const getProductListFun = function () {
  getProductList(qs.stringify({
    categoryId: deviceForm.value.categoryId
  })).then(res => {
    if (res.data.ok) {
      productList.value = res.data.result
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
let powerGridRateData = ref() //费率配置列表
const powerGridRateListFun = function () {
  powerGridRateList(null).then(res => {
    if (res.data.ok) {
      powerGridRateData.value = res.data.result
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
let portList = ref([])
let rateTypeList = ref([])
let rulerTypeList = ref([])
let enableList = ref([{
  id: 1,
  name: '是'
}, {
  id: 0,
  name: '否'
}])
const getSelectListFun = function () {
  getSelectList(null).then(res => {
    if (res.data.ok) {
      portList.value = res.data.result.portList
      rateTypeList.value = res.data.result.rateTypeList
      rulerTypeList.value = res.data.result.rulerTypeList
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
let gatewayList = ref([]) //网关列表
const getGatewayListFun = function () {
  getGatewayList(null).then(res => {
    if (res.data.ok) {
      gatewayList.value = res.data.result
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
let categoryName = ref()
//分类改变
const categoryChange = function (id) {
  if (deviceForm.value.categoryId) {
    getProductListFun()
  }
  allCategoryList.value.forEach(item => {
    if (item.id == id) {
      categoryName.value = item.name
      if (item.name != '电表') {
        deviceForm.value.powerGridRateId = ''
      }
    }
  })
  deviceForm.value.productId = ''
}
// 显示下级
const showNextLevel = function () {
  getListFun()
}

let dialogWidth = ref('60%') //新增设备弹窗宽度
let deviceDialogVisible = ref(false) //新增设备弹窗开关
let title = ref() //弹窗标题
let editId = ref() //编辑id
//新增设备的点击
const addClick = function (id) {
  if (id) {
    editId.value = id
    title.value = '编辑设备'
    getDetail(qs.stringify({
      id: id
    })).then(res => {
      if (res.data.ok) {
        deviceForm.value.categoryId = res.data.result.categoryId
        categoryChange(res.data.result.categoryId)
        deviceForm.value.powerGridRateId = res.data.result.powerGridRateId
        deviceForm.value.productId = res.data.result.productId
        deviceForm.value.gatewayId = res.data.result.gatewayId
        deviceForm.value.name = res.data.result.name
        deviceForm.value.address = res.data.result.address
        deviceForm.value.enableFlag = res.data.result.enableFlag
        deviceForm.value.rulerType = String(res.data.result.rulerType)
        deviceForm.value.userType = res.data.result.userType
        deviceForm.value.rateType = String(res.data.result.rateType)
        deviceForm.value.port = String(res.data.result.port)
        deviceForm.value.rateV = res.data.result.rateV
        deviceForm.value.rateA = res.data.result.rateA
        deviceForm.value.remark = res.data.result.remark
        deviceForm.value.relationName = res.data.result.relationName
        deviceForm.value.projectId = custerObj.value.id
        getProductListFun()
      } else {
        ElMessage({
          message: res.data.message,
          type: 'error'
        })
      }
    }).catch(err => {
      console.log(err);
    })
    deviceDialogVisible.value = true
  } else {
    typeDialogVisible.value = true
    title.value = '设备类型'
  }
}
const deviceTypeFun = function () {
  if (deviceType.value == '网关设备') {
    editId.value = ''
    deviceForm.value = {
      categoryId: '',
      powerGridRateId: '',
      productId: '',
      gatewayId: '',
      name: '',
      address: '',
      enableFlag: 1,
      rulerType: '',
      userType: '1',
      rateType: '',
      port: '',
      rateV: 1,
      rateA: 1,
      remark: '',
      relationName: ''
    }
    deviceDialogVisible.value = true
    typeDialogVisible.value = false
  } else {
    // 判断是直连设备
    formData.value = {
      name: '',
      plugin: '',
      params: {}
    }
    dialogFormVisible.value = true
    typeDialogVisible.value = false


    // router.push({
    //   path: '/admin/southDriver',
    //   query: {
    //     ty: 1
    //   }
    // })
  }
}
let deviceFormRef = ref() //设备
//设备表单
let deviceForm = ref({
  categoryId: '',
  powerGridRateId: '',
  productId: '',
  gatewayId: '',
  name: '',
  address: '',
  enableFlag: 1,
  rulerType: '',
  userType: '1',
  rateType: '',
  port: '',
  rateV: 1,
  rateA: 1,
  remark: '',
  relationName: ''
})
// 设备表单验证规则
let deviceRules = ref({
  categoryId: [{
    required: true,
    message: '请选择产品类型',
    trigger: 'blur'
  }],
  powerGridRateId: [{
    required: true,
    message: '请选择电网费率',
    trigger: 'blur'
  }],
  productId: [{
    required: true,
    message: '请选择产品型号',
    trigger: 'blur'
  }],
  gatewayId: [{
    required: true,
    message: '请选择网关',
    trigger: 'blur'
  }],
  name: [{
    required: true,
    message: '请输入设备名称',
    trigger: 'blur'
  }],
  address: [{
    required: true,
    message: '请输入通讯地址',
    trigger: 'blur'
  }],
  port: [{
    required: true,
    message: '请选择端口',
    trigger: 'blur'
  }],
  rateV: [{
    required: true,
    message: '请输入电压变比',
    trigger: 'blur'
  }],
  rateA: [{
    required: true,
    message: '请输入电流变比',
    trigger: 'blur'
  }],
})
//设备的保存
const deviceSave = function () {
  let params = {
    productId: deviceForm.value.productId,
    gatewayId: deviceForm.value.gatewayId,
    powerGridRateId: categoryName.value == '电表' ? deviceForm.value.powerGridRateId : null,
    name: deviceForm.value.name,
    address: deviceForm.value.address,
    enableFlag: deviceForm.value.enableFlag,
    rulerType: deviceForm.value.rulerType,
    userType: deviceForm.value.userType,
    rateType: deviceForm.value.rateType,
    port: deviceForm.value.port,
    rateV: deviceForm.value.rateV,
    rateA: deviceForm.value.rateA,
    remark: deviceForm.value.remark,
    relationName: deviceForm.value.relationName,
    type: 1,
    projectId: custerObj.value.id
  }
  if (title.value == '新建设备') {
    params['id'] = 0
  } else if (title.value == '编辑设备') {
    params['id'] = editId.value
  }
  if (deviceFormRef) {
    deviceFormRef.value.validate((valid) => {
      //提交成功
      if (valid) {
        if (deviceForm.value.name) {
          saveList(
              qs.stringify(params)
          ).then(async res => {
            if (res.data.ok) {
              deviceDialogVisible.value = false
              getListFun()
            } else {
              ElMessage({
                message: res.data.message,
                type: 'error'
              })
            }
          }).catch(err => {
            console.log(err);
          })
        } else {
          ElMessage({
            message: '请输入设备名称',
            type: 'info'
          })
        }
      }
    })
  }
}
//删除
const deleteClick = function (id) {
  ElMessageBox.confirm("确认删除吗，删除后不可恢复，请谨慎操作", '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '删除设备',
    message: h('p', null, [
      h('img', {
        src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
        style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
      }, ''),
      h('span', {
        style: 'color:#595959;'
      }, '请确认是否要删除此设备，删除后数据不可恢复'),
    ])
  }).then(() => {
    deleteList(
        qs.stringify({
          id: id
        })
    ).then(res => {
      if (res.data.ok) {
        getListFun()
      } else {
        ElMessage({
          message: res.data.message,
          type: 'error'
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }).catch(() => {
    ElMessage({
      message: '已取消删除',
      type: 'info',
    })
  });
}
//通讯地址改变
const addressChange = function () {
  deviceForm.value.address = deviceForm.value.address.padStart(12, '0')
}
//管理
const managementClick = function (id) {
  // router.push({
  //   path: '/admin/projectData',
  //   query: {
  //     isLocal: custerObj.value.nodeType,
  //     id: id,
  //     type: activeName.value
  //   }
  // })
  window.open(`#/admin/projectData?isLocal=${custerObj.value.nodeType}&id=${id}&type=${activeName.value}`, '_blank');
}
// 点击报警
const goWarnFun = function (id) {
  // router.push({
  //   path: '/admin/projectWarning',
  //   query: {
  //     id: id
  //   }
  // })
  window.open(`#/admin/projectWarning?id=${id}`, '_blank');
}


/*直连设备****************************************************************************/
// 新建直连设备弹窗
let dialogFormVisible = ref(false)
//获取插件列表
let pluginData = ref([])
const getPluginFun = function () {
  getPlugin(null).then(res => {
    if (res.status == 200) {
      pluginData.value = res.data.plugins
      deviceList.value.forEach(item => {
        pluginData.value.forEach(items => {
          if (item.productModel == items.name) {
            item.plugin2 = items.schema
          }
        })
      })
    } else {
      pluginData.value = []
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}

// 添加设备表单数据----------------------------------------------
let formData = ref({
  name: '',
  plugin: '',
  params: {}
})
// 添加设备表单验证
let formRules = ref({
  name: [{
    required: true,
    message: '请输入设备名称',
    trigger: 'change',
  }],
  plugin: [{
    required: true,
    message: '请选择插件',
    trigger: 'blur'
  }],
  copyName: [{
    required: true,
    message: '请输入驱动名称',
    trigger: 'change',
  }],
})
let userForm = ref() // 提交添加设备表单
// 添加设备的保存
const save = function () {
  userForm.value.validate((valid) => {
    //提交成功
    if (valid) {
      saveSouthEquipmentApi(qs.stringify({
        id: 0,
        name: formData.value.name,
        plugin: formData.value.plugin,
        projectId: custerObj.value.id,
        params: JSON.stringify(formData.value.params),
        authorization: 'Bearer ' + window.localStorage.getItem('token')
      })).then(res => {
        if (res.data.code === 0) {
          dialogFormVisible.value = false
          typeDialogVisible.value = false
          getListFun()
        } else {
          ElMessage({
            type: 'error',
            message: res.data.message
          });
        }
      }).catch(err => {
        console.log(err)
      })
    }
  })
}
// 直连设备的列表详情
//获取设备列表
const getStateFun = function () {
  getState(null).then(res => {
    if (res.status == 200) {
      deviceList.value.forEach(item => {
        res.data.states.forEach(items => {
          if (item.equipmentName == items.node) {
            item.link = items.link
            item.running = items.running
            item.rtt = items.rtt
            if (items.running == 3) {
              item.switch = true
            } else {
              item.switch = false
            }
            item.log_level = items.log_level
          }
        })
      })
      console.log(deviceList.value)
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 插件选择事件
let formObj = ref({})
const selectChange = function () {
  var schema_name;
  pluginData.value.forEach(items => {
    if (formData.value.plugin == items.name) {
      schema_name = items.schema
    }
  })
  getFormDataApi({
    schema_name: schema_name
  }).then(res => {
    if (res.status === 200) {
      formData.value = {
        name: formData.value.name,
        plugin: formData.value.plugin,
        params: {}
      }
      formObj.value = res.data
      let num = 0
      for (const key in formObj.value) {
        formData.value.params[key] = formObj.value[key].default
        if (formObj.value[key].type === 'file') {
          let fileList = key + '-fileList'
          formData.value.params[fileList] = []
          num++
        }
      }
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 本地直连设备电网费率更新-----------------------
let editEleDialogFormVisible = ref(false)
let formData3 = ref({
  id: 0,
  powerGridRateId: '',
})
let formRules3 = ref({
  powerGridRateId: [{
    required: true,
    message: '请选择电网费率',
    trigger: 'change',
  }],
})
const eleSetClick = function (row) {
  formData3.value.id = row.id
  formData3.value.powerGridRateId = row.powerGridRateId
  editEleDialogFormVisible.value = true
}
let userForm3 = ref() // 提交编辑设备表单
//编辑的保存
const editEleSave = function () {
  userForm3.value.validate((valid) => {
    //提交成功
    if (valid) {
      updatePowerGridRateIdApi(qs.stringify(formData3.value)).then(res => {
        if (res.data.code == 0) {
          getListFun()
          editEleDialogFormVisible.value = false
        } else {
          ElMessage({
            type: 'error',
            message: res.data.message
          });
        }
      }).catch(err => {
        console.log(err)
      })
    }
  })
}


// 设备连接断开----------------------------------------------
// 应用连接断开
let loading = ref(false)
const isOpenChange = function (value, scope) {
  ctl({
    cmd: scope.running === 3 ? 1 : 0,
    node: scope.equipmentName,
  }).then(res => {
    loading.value = false
    if (res.status === 200) {
      getListFun()
      ElMessage({
        message: scope.running === 3 ? '停止成功' : '运行成功',
        type: 'success'
      })
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    loading.value = false
    console.log(err);
  })
}

//关闭或开启debug日志----------------------------------------------
const debugClick = function (log_level, name) {
  var level;
  if (log_level == 'debug') {
    level = 'notice'
  } else {
    level = 'debug'
  }
  var params = {
    core: false,
    level: level,
    node: name
  }
  levelControl(params).then(res => {
    if (res.status == 200) {
      getListFun()
      copyeditDialogFormVisible.value = false
      if (log_level == 'debug') {
        ElMessage({
          message: '已关闭DEBUG日志！',
          type: 'success'
        })
      } else if (log_level == 'notice') {
        ElMessage({
          message: '已开启DEBUG日志！',
          type: 'success'
        })
      }
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
//组的点击
const groupClick = function (node) {
  router.push({
    path: '/admin/group',
    query: {
      node: node
    }
  })
}
let typeDialogVisible = ref(false)
let deviceType = ref("网关设备")
let editDialogFormVisible = ref(false) // 编辑表单弹窗
let oldName = ref()
// 编辑设备点击----------------------------------------------
const editClick = function (row) {
  editDialogFormVisible.value = true
  title.value = "编辑设备"
  formData2.value = {
    id: row.id,
    name: row.equipmentName,
    authorization: 'Bearer ' + window.localStorage.getItem('token')
  }
  oldName = row.name
}
// 编辑设备表单数据
let formData2 = ref({
  id: 0,
  name: '',
  authorization: 'Bearer ' + window.localStorage.getItem('token')
})
// 编辑设备表单验证
let formRules2 = ref({
  name: [{
    required: true,
    message: '请输入设备名称',
    trigger: 'change',
  }],
})
let userForm2 = ref() // 提交编辑设备表单
//编辑的保存
const editSave = function () {
  var params = {
    name: oldName,
    new_name: formData2.value.name
  }
  userForm2.value.validate((valid) => {
    //提交成功
    if (valid) {
      saveSouthEquipmentApi(qs.stringify(formData2.value)).then(res => {
        if (res.data.code == 0) {
          getListFun()
          editDialogFormVisible.value = false
        } else {
          ElMessage({
            type: 'error',
            message: res.data.message
          });
        }
      }).catch(err => {
        console.log(err)
      })
    }
  })
}
//数据统计/数据配置----------------------------------------------
const deviceDetailClick = function (type, node, plugin, id) {
  router.push({
    path: '/admin/deviceDetail',
    query: {
      type: type,
      node: node,
      plugin: plugin,
      id: id,
      projectId: custerObj.value.id
    }
  })
}
let driversData = ref([])
// let formData = ref({})
//复制弹窗的点击----------------------------------------------
let copyeditDialogFormVisible = ref(false)
const copyClick = function (name) {
  formData.value.copyName = name + '_copy'
  copyeditDialogFormVisible.value = true
  driversList({
    name: name
  }).then(res => {
    if (res.status == 200) {
      driversData.value = res.data
      driversData.value.nodes[0].name = formData.value.copyName
    } else {
      driversData.value = []
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
//复制弹窗的保存
const copySave = function () {
  driversControl(driversData.value).then(res => {
    if (res.status == 200) {
      getListFun()
      copyeditDialogFormVisible.value = false
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 下载驱动日志
const downloadLogFun = function (scope) {
  const downloadZip = (data, fileName = '驱动日志') => {
    var blob = new Blob([data], {
      type: 'application/x-tar'
    })
    if ('download' in document.createElement('a')) {
      var downloadElement = document.createElement('a')
      var href = window.URL.createObjectURL(blob) //创建下载的链接
      downloadElement.href = href
      downloadElement.download = fileName //下载后文件名
      document.body.appendChild(downloadElement)
      downloadElement.click() //点击下载
      document.body.removeChild(downloadElement) //下载完成移除元素
      window.URL.revokeObjectURL(href) //释放掉blob对象
    }
  }
  openFullScreen()
  downloadLogApi(scope.equipmentName).then(res => {
    loadingAdd.value.close()
    if (res.status === 200) {
      downloadZip(res.data)
      ElMessage({
        type: 'success',
        message: '下载成功',
      })
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message,
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 删除设备
const delClick = function (name, id) {
  ElMessageBox.confirm('', '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '删除组',
    message: h('p', null, [
      h('img', {
        src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
        style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
      }, ''),
      h('span', {
        style: 'color:#595959;'
      }, '请确认是否要删除此组，删除后数据不可恢复'),
    ])
  }).then(() => {
    deleteList(
        qs.stringify({
          id: id
        })
    ).then(res => {
      if (res.data.ok) {
        getListFun()
        nodeDelete({
          name: name
        }).then(res => {
          if (res.status == 200) {
            ElMessage({
              type: 'success',
              message: '删除成功',
            })
            getListFun()
          } else {
            ElMessage({
              type: 'error',
              message: res.data.message,
            })
          }
        }).catch(err => {
          console.log(err);
        })
      } else {
        ElMessage({
          message: res.data.message,
          type: 'error'
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '取消删除',
    })
  })
}


// 绑定事件----------------------------------------------------------------------------
let bindFlag = ref(true)
let bTotal = ref()
let bPageNumber = ref(1)
let bPageSize = ref(20)
let bDeviceName = ref()
let unBindDeviceLoading = ref(false)
// 绑定弹窗
let bindDeviceDialog = ref(false)
// 未绑定的设备列表
let unBindDeviceList = ref([])
// 获取未绑定设备列表
const getAllDeviceListFun = async function () {
  await getAllDeviceListApi(qs.stringify({
    pageNumber: bPageNumber.value,
    pageSize: bPageSize.value,
    name: bDeviceName.value,
    type: 1
  })).then(res => {
    unBindDeviceLoading.value = false
    if (res.data.code === 0) {
      unBindDeviceList.value = res.data.result.list
      bTotal.value = Number(res.data.result.total)
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 点击绑定
const bindClick = async function () {
  bindDeviceDialog.value = true
  unBindDeviceLoading.value = true
  await getAllDeviceListFun()
  multipleTableRef2.value.clearSelection()
}
const bHandleSizeChange = function (e) {
  bPageSize.value = e
  getAllDeviceListFun()
}
const bHandleCurrentChange = function (e) {
  bPageNumber.value = e
  getAllDeviceListFun()
}
// 设备表格多选事件
let checkIdArr2 = ref([])
const handleSelectionChange2 = function (e) {
  checkIdArr2.value = []
  for (let i = 0; i < e.length; i++) {
    checkIdArr2.value.push(Number(e[i].id))
  }
  checkIdArr2.value = [...new Set(checkIdArr2.value)]
}
// 全选
let multipleTableRef2 = ref()
const checkAll2 = async function (e) {
  let arr = []
  await getAllDeviceListApi(qs.stringify({
    pageNumber: 1,
    pageSize: bTotal.value,
    name: bDeviceName.value,
    type: 1
  })).then(res => {
    if (res.data.code === 0) {
      arr = res.data.result.list
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })

  // 判断全选或全不选
  if (!multipleTableRef2.value.store.states.isAllSelected.value) {
    multipleTableRef2.value.clearSelection()
  } else {
    for (let i = 0; i < arr.length; i++) {
      multipleTableRef2.value.toggleRowSelection(arr[i], true)
    }
  }
}
const rowKey = function (row) {
  return row.id
}
const rowKey2 = function (row) {
  return row.id
}

// 提交绑定
const bindSubmit = function () {
  bindDeviceListApi(qs.stringify({
    type: 1,
    id: custerObj.value.id,
    idList: checkIdArr2.value.join(',')
  })).then(res => {
    if (res.data.code === 0) {
      bindDeviceDialog.value = false
      getListFun()
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}

// 解绑事件----------------------------------------------------------------------------
// 点击解绑
const removeClick = function (id) {
  ElMessageBox.confirm('请确认是否要解绑这些设备', 'Warning', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning',
    title: ''
  }).then(() => {
    removeDeviceListApi(qs.stringify({
      type: 1,
      id: custerObj.value.id,
      idList: id ? id : checkIdArr.value.join(',')
    })).then(res => {
      if (res.data.code === 0) {
        ElMessage({
          type: 'success',
          message: '解绑成功',
        })
        getListFun()
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message,
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '取消解绑',
    })
  })
}
// 表格多选框选择事件
let checkIdArr = ref([])
const handleSelectionChange = function (e) {
  checkIdArr.value = []
  for (let i = 0; i < e.length; i++) {
    checkIdArr.value.push(Number(e[i].id))
  }
  checkIdArr.value = [...new Set(checkIdArr.value)]
}
// 全选
let multipleTableRef = ref()
const checkAll = async function (e) {
  let arr = []
  let params = {
    pageNumber: 1,
    pageSize: total.value,
    id: custerObj.value.id,
    getSubFlag: getSubFlag.value ? 1 : 0,
    productModel: productModel.value,
    equipmentName: equipmentName.value,
    equipmentAddress: equipmentAddress.value
  }
  await getDeviceListApi(qs.stringify(params)).then(res => {
    if (res.data.ok) {
      arr = res.data.result.list

    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })

  // 判断全选或全不选
  if (!multipleTableRef.value.store.states.isAllSelected.value) {
    multipleTableRef.value.clearSelection()
  } else {
    for (let i = 0; i < arr.length; i++) {
      multipleTableRef.value.toggleRowSelection(arr[i], true)
    }
  }
}


// 进入全屏
const enterFullScreen = () => {
  // 获取layout的dom元素
  const parentNode = document.querySelector('.rightBox'); // 获取需要全屏的元素;
  // document.querySelector('.largeScreenContent .chart').style.height = 'calc(100% - 140px)'  //进入全屏后变大图片
  // 进入全屏
  if (parentNode.requestFullscreen) {
    parentNode.requestFullscreen();
  } else if (parentNode.webkitRequestFullScreen) {
    parentNode.webkitRequestFullScreen();
  } else if (parentNode.mozRequestFullScreen) {
    parentNode.mozRequestFullScreen();
  } else if (parentNode.msRequestFullscreen) {
    // IE11
    parentNode.msRequestFullscreen();
  }


};
// 退出全屏
const exitFullscreen = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if ((document).webkitCancelFullScreen) {
    (document).webkitCancelFullScreen();
  } else if ((document).mozCancelFullScreen) {
    (document).mozCancelFullScreen();
  } else if ((document).msExitFullscreen) {
    (document).msExitFullscreen();
  }

};


let isOpenDevice = ref(1)
/*生命周期*/
onMounted(async () => {
  getAllCategoryFun()
  getSelectListFun()
  getGatewayListFun()
  powerGridRateListFun()
  if (activeName.value == 3 && custerObj.value.nodeType != 2) {
    await getPluginFun()
  }

  activeName.value = 1
  if (router.currentRoute.value.query.type == 3) {
    activeName.value = 3
  }
  const query = {...route.query};
  // 使用新的查询参数替换当前路由
  router.replace({query});


  getDeviceIsOpenApi().then(res => {
    if (res.data.ok) {
      isOpenDevice.value = res.data.result
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
})

let custerObj = ref()
// 监听props.data.list中每个元素的变化，当它变化时，启动动画
watch(() => props.custerObj, async (newValues, oldValues) => {
  custerObj.value = newValues
  if (newValues.id) {
    if (isOpenDevice.value == 0 && custerObj.value.nodeType != 2) {
      activeName.value = 1
    }
    await getListFun()
    if (activeName.value == 3 && custerObj.value.nodeType != 2) {
      await getPluginFun()
    }
  }
}, {immediate: true, deep: true});


</script>

<style lang="less" scoped>
</style>
