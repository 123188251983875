<template>
  <div class="dataSearch">
    <div class="title">
      <p>
        <span>数据查询</span>
      </p>
      <div>
        <el-button @click="searchPlan('true')">新增方案</el-button>
        <el-button type="primary" @click="searchPlan">查询管理</el-button>
      </div>
    </div>
    <div class="content">
      <!--搜索-->
      <div :style="{paddingBottom: !isFold?'52px':0}" class="searchBox">
        <div class="liBox" style="display: inline-block">
          <span>查询方案： </span>
          <el-select v-model="plan" class="planBox" @change="planChange">
            <el-option v-for="(item,index) in planList" :key="index" :label="item.queryName"
                       :value="item.id"></el-option>
          </el-select>
          <span style="margin-left: 50px">数据间隔： </span>
          <!--          <el-input v-model="frequency" :disabled="true" placeholder="请输入数据间隔" type="number">-->
          <!--            &lt;!&ndash;            <template #suffix>&ndash;&gt;-->
          <!--            &lt;!&ndash;              分钟&ndash;&gt;-->
          <!--            &lt;!&ndash;            </template>&ndash;&gt;-->
          <!--            <template #append>-->
          <!--              <el-select v-model="timeUnit" style="width: 90px;">-->
          <!--                <el-option label="分钟" value="m"/>-->
          <!--                <el-option label="小时" value="h"/>-->
          <!--                <el-option label="天" value="d"/>-->
          <!--                <el-option label="月" value="n"/>-->
          <!--                <el-option label="年" value="y"/>-->
          <!--              </el-select>-->
          <!--            </template>-->
          <!--          </el-input>-->
          <el-select v-model="timeUnit" placeholder="请选择数据间隔">
            <el-option v-for="(item,index) in intervalList" :key="index" :label="item.value" :value="item.key"/>
          </el-select>
        </div>
        <div v-show="!isFold" class="liBox">
          <span>日期： </span>
          <el-select v-model="timeFlag" clearable @change="timeFlagSelect">
            <el-option label="自定义时间段" value="-1"></el-option>
            <el-option :disabled="chatType==4?false:true" label="瞬时" value="now"></el-option>
            <el-option label="近1小时" value="1H"></el-option>
            <el-option label="近12小时" value="12H"></el-option>
            <el-option label="近一天" value="1D"></el-option>
            <el-option label="近3天" value="3D"></el-option>
            <el-option label="近1周" value="1W"></el-option>
            <el-option label="本周" value="THIS_WEEK"></el-option>
            <el-option label="本月" value="1M"></el-option>
            <el-option label="本季" value="1Q"></el-option>
          </el-select>
          <el-date-picker v-model="dateTime" end-placeholder="结束时间" popper-class="date-style"
                          range-separator="→"
                          start-placeholder="开始时间" style="margin-left: 15px"
                          type="datetimerange"
                          value-format="YYYY-MM-DD HH:mm:ss">
          </el-date-picker>
          <el-checkbox-group v-model="yom" :max="1" :min="0"
                             style="display: inline-block;margin-left: 20px;transform: translateY(3px)">
            <el-checkbox :label="1">同比</el-checkbox>
            <el-checkbox :label="2">环比</el-checkbox>
          </el-checkbox-group>
        </div>
        <div v-for="(item,index) in equipmentArr" v-show="!isFold" :key="index" class="liBox liCopy">
          <span>设备： </span>
          <!--          <el-select v-model="item.deviceId" clearable placeholder="请选择设备" @change="equipmentChange(index)">-->
          <!--            <el-option v-for="(item_d,index_d) in equipmentList" :key="index_d" :label="item_d.name"-->
          <!--                       :value="item_d.id"></el-option>-->
          <!--          </el-select>-->
          <el-tree-select
              ref="treeRef1"
              v-model="item.deviceId"
              :data="allTree"
              :props="props"
              :render-after-expand="false"
              :render-content="renderContent"
              filterable
              node-key="id"
              style="height: auto!important;"
              @change="equipmentChange(index)"
          />
          <el-select v-model="item.dictKeys" class="item" clearable multiple placeholder="请选择指标"
                     @change="dataChange(index)">
            <el-option v-for="(item_d,index_d) in dataList[index]" :key="index_d" :label="item_d.value"
                       :value="item_d.key"></el-option>
          </el-select>
          <el-icon v-if="equipmentArr.length>1" class="delIcon" @click="delClick(index)">
            <CircleClose/>
          </el-icon>
          <el-icon v-if="index===equipmentArr.length-1" class="addIcon" @click="addClick">
            <CirclePlus/>
          </el-icon>
        </div>
        <div class="liBox" style="float: right">
          <el-button class="searchButton" type="primary" @click="searchFun">查询</el-button>
          <el-button type="success" @click="exportFun">导出</el-button>
          <el-button class="searchButton resetButton" @click="resetFun">重置</el-button>
          <el-button class="searchButton resetButton" @click="enterFullScreen">全屏展示</el-button>
          <el-button v-show="isFold" class="searchButton resetButton" @click="isFold=false">
            <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/bottomArrow.png"
                 style="width: 16px;height: 16px;margin-right: 7px">
            展开
          </el-button>
          <el-button v-show="!isFold" class="searchButton resetButton" @click="isFold=true">
            <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/topArrow.png"
                 style="width: 16px;height: 16px;margin-right: 7px">
            收起
          </el-button>
        </div>
      </div>
      <!--搜索的内容-->
      <div class="contain">
        <div class="fullScreenHead">
          <p>{{ planName ? planName : '--' }}</p>
          <el-button @click="exitFullscreen"><img alt=""
                                                  src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/back.png"
                                                  style="width: 16px;height: 16px;margin-right: 7px">返回
          </el-button>
        </div>
        <div class="head" style="border-bottom: 1px solid rgba(0,0,0,0.08)">
          <div class="tab">
            <p :class="showFlag?'active':''" @click="showClick(1)">图表</p>
            <p :class="!showFlag?'active':''" @click="showClick(2)">表格</p>
          </div>
        </div>
        <div v-show="showFlag" style="margin: 20px 0 30px 0">
          <span>强调展示：</span>
          <el-select v-model="strongShow" :teleported="false" multiple placeholder="请选择强调展示的数据"
                     style="width: 500px"
                     @change="strongShowChange">
            <el-option v-for="(item,index) in strongShowArr" :key="index" :label="item.name"
                       :value="item.name">
              <span :style="{backgroundColor:item.color}"
                    style="float: left;display: inline-block;width: 12px;height: 12px;border-radius: 50%;transform: translate(-7px, 11px);"></span>
              <span>{{ item.name }}</span>
            </el-option>
          </el-select>
          <span style="margin-left: 20px">展示方式：</span>
          <el-select v-model="chatType" :teleported="false" placeholder="请选择图表类型" style="width: 190px;"
                     @change="chatTypeChange">
            <el-option :value="1" label="折线图"></el-option>
            <el-option :value="0" label="柱状图"></el-option>
            <el-option :value="4" label="饼图"></el-option>
            <el-option :disabled="true" :value="2" label="面积图"></el-option>
            <el-option :disabled="true" :value="3" label="点图"></el-option>
          </el-select>
        </div>

        <el-empty v-if="tableData.length===0&&echartsData.length===0"
                  class="emptyClass" description="暂无数据信息"
                  image="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png"/>
        <!--图表-->
        <div v-show="showFlag&&echartsData.length!==0" class="chart"></div>
        <!--表格-->
        <div v-show="!showFlag&&tableData.length!==0" class="tableCont">
          <div v-for="(item,index) in tableData" :key="index" class="tableItem">
            <div class="tit">{{ item.deviceName }}</div>
            <el-table :data="item.dataMetricsVO" style="width: calc(100% - 48px);" table-layout="auto">
              <el-table-column label="上报时间" prop="ts"></el-table-column>
              <el-table-column v-for="(item_a,index_a) in item.labelArr" :key="index_a" :label="item_a"
                               :prop="item_a"></el-table-column>
            </el-table>
            <el-button v-if="item.dataMetricsVO.length>0" @click="moreClick(index)">加载更多</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {nextTick, onMounted, reactive, ref} from "vue"
import './dataSearch.less'
import {CloseBold, ZoomIn, Plus, Delete, CirclePlus, CircleClose} from '@element-plus/icons-vue'
import {CascaderProps, ElLoading, ElMessage, ElMessageBox} from 'element-plus'
import * as echarts from 'echarts';
import {useRouter} from 'vue-router';
import qs from "qs";
import {
  getEquipmentListApi,
  getDataListApi,
  getEchartsDataApi,
  getTableDataApi,
  getSearchListApi, getSearchDetailApi, exportDataApi, getIntervalListApi
} from '@/api/modules/dataSearch'
import moment from 'moment'
import {getAllTreeApi, getAllTreeApi2} from "@/api/modules/strategy";

let router = useRouter()


// 点击跳转查询管理
const searchPlan = function (flag) {
  if (flag === 'true') {
    router.push({
      path: '/admin/searchPlan',
      query: {
        flag: true
      }
    })
  } else {
    router.push('/admin/searchPlan')
  }
}

// 搜索---------------------------------------------------------------------------------------
let plan = ref()   //选择的搜索方案
let timeFlag = ref()   //日期快捷选择
let dateTime = ref()   //搜索日期
let frequency = ref(1)   //频率
let timeUnit = ref('1m')   //频率单位
let yom = ref([])  //同环比
let equipmentArr = ref([
  {
    deviceId: '',
    dictKeys: []
  }
])    //搜索的设备
// 日期快捷选择事件
const timeFlagSelect = function () {
  if (timeFlag.value === '-1') {
    dateTime.value = []
  } else if (timeFlag.value === '1H') {
    dateTime.value = [moment().subtract(1, "hours").format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (timeFlag.value === '12H') {
    dateTime.value = [moment().subtract(12, "hours").format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (timeFlag.value === '1D') {
    dateTime.value = [moment().subtract(1, "days").format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (timeFlag.value === '3D') {
    dateTime.value = [moment().subtract(3, "days").format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (timeFlag.value === '1W') {
    dateTime.value = [moment().subtract(1, "weeks").format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (timeFlag.value === 'THIS_WEEK') {
    dateTime.value = [moment().startOf('isoWeek').format('YYYY-MM-DD HH:mm:ss'), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (timeFlag.value === '1M') {
    dateTime.value = [moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (timeFlag.value === '1Q') {
    dateTime.value = [moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).startOf('quarter').format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
    // dateTime.value = [moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).startOf('quarter').format("YYYY-MM-DD HH:mm:ss"), moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).endOf('quarter').format("YYYY-MM-DD HH:mm:ss")]
  }
}
let chatType = ref(1)  //展示方式
let strongShow = ref([])  //强调展示


// 设备列表
let equipmentList = ref([])
// 指标列表
let dataList = ref([])
// 获取设备列表
const getEquipmentListFun = async function () {
  await getEquipmentListApi().then(res => {
    if (res.data.code === 200) {
      equipmentList.value = res.data.data
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 获取指标列表
const getDataListFun = async function (index, id) {
  if (id) {
    await getDataListApi(qs.stringify({
      id: id
    })).then(res => {
      if (res.data.code === 0) {
        dataList.value[index] = res.data.result
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }
}
// 选择设备事件
let treeRef1 = ref()
const equipmentChange = function (index) {
  equipmentArr.value[index].dictKeys = []
  // getDataListFun(index)
  getDataListFun(index, treeRef1.value[index].getCurrentNode().id)
  equipmentArr.value[index].deviceId = treeRef1.value[index].getCurrentNode().id
}
// 选择指标事件
const dataChange = function (index) {
  if (equipmentArr.value[index].dictKeys.length > 5) {
    ElMessage({
      type: 'info',
      message: '最多选择5个指标'
    })
    equipmentArr.value[index].dictKeys.splice(-1)
  }
}
// 点击新增设备
const addClick = function () {
  equipmentArr.value.push({
    deviceId: '',
    dictKeys: []
  })
}
// 点击删除设备
const delClick = function (index) {
  equipmentArr.value.splice(index, 1)
  dataList.value.splice(index, 1)
}

// 获取图表数据
let echartsData = ref([])
let echartsDataX = ref([])
let strongShowArr = ref([])
const getEchartsDataFun = async function () {
  echartsDataX.value = []
  echartsData.value = []
  let data = {
    startTime: dateTime.value ? dateTime.value[0] : '',
    endTime: dateTime.value ? dateTime.value[1] : '',
    frequency: frequency.value,
    timeUnit: timeUnit.value,
    timeFlag: timeFlag.value,
    chatType: chatType.value,
    params: equipmentArr.value,
    yom: chatType.value === 4 ? yom.value[0] : 0
  }
  // 计算同环比时间
  if (yom.value && yom.value[0] == 1) {
    data.lastStartTime = moment(data.startTime).subtract(1, 'years').format("YYYY-MM-DD HH:mm:ss")
    data.lastEndTime = moment(data.endTime).subtract(1, 'years').format("YYYY-MM-DD HH:mm:ss")
  } else if (yom.value && yom.value[0] == 2) {
    if (timeFlag.value === '1M') {
      data.lastStartTime = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD HH:mm:ss')
      data.lastEndTime = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD HH:mm:ss')
    } else if (timeFlag.value === '1Q') {
      data.lastStartTime = moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).subtract(1, 'quarter').startOf('quarter').format("YYYY-MM-DD HH:mm:ss")
      data.lastEndTime = moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).subtract(1, 'quarter').endOf('quarter').format("YYYY-MM-DD HH:mm:ss")
    } else {
      let timeLength = moment(data.endTime).diff(data.startTime, 'second')
      data.lastStartTime = moment(data.startTime).subtract(timeLength, 'second').format('YYYY-MM-DD HH:mm:ss')
      data.lastEndTime = moment(data.endTime).subtract(timeLength, 'second').format('YYYY-MM-DD HH:mm:ss')
    }
  }
  let flag = false
  for (let i = 0; i < data.params.length; i++) {
    if (!data.params[i].deviceId || (data.params[i].dictKeys.length === 0)) {
      flag = true
    }
    delete data.params[i].dictList
    delete data.params[i].dictIds
  }
  if (chatType.value === 4) {
    yom.value = []
  }
  if (data.frequency && data.timeUnit && !flag) {
    openFullScreen()
    await getEchartsDataApi(data).then(res => {
      loadingAdd.value.close()
      if (res.data.code === 0) {
        // 判断是否为饼图
        if (chatType.value == 4) {
          echartsDataX.value = null
          let list = res.data.result.pieList
          for (let i = 0; i < list.length; i++) {
            list[i].name = list[i].key
            list[i].value = Number(list[i].value)
            list[i].itemStyle = {color: colorArr[i]}
            list[i].label = {formatter: `{b}: {d}%\n${list[i].time}`}
            list[i].tooltip = {formatter: `{b}: {c}<br />占比: {d}%<br />${list[i].time}`}
            strongShowArr.value.push({
              name: list[i].key,
              color: colorArr[i]
            })
          }
          echartsData.value = [{
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2,
            },
            label: {
              show: true,
              lineHeight: 18
            },
            data: list
          }]
        } else {
          echartsDataX.value = res.data.result.xAxis
          echartsData.value = res.data.result.yAxis
          let isThan = false
          for (let i = 0; i < echartsData.value.length; i++) {
            // 把每条数据名称改为设备+指标
            echartsData.value[i].name = echartsData.value[i].deviceName + '-' + echartsData.value[i].titleName
            echartsData.value[i].sampling = 'lttb'
            if (echartsData.value[i].name.includes('同比') || echartsData.value[i].name.includes('环比')) {
              isThan = true
            }

            // 数据处理为小数点两位
            for (let j = 0; j < echartsData.value[i].data.length; j++) {
              echartsData.value[i].data[j] = Number(echartsData.value[i].data[j]).toFixed(2)
            }
          }
          // 图表类型样式
          // 如果是属于折线图
          if (chatType.value == 1) {
            for (let i = 0; i < echartsData.value.length; i++) {
              echartsData.value[i].type = 'line'
              // 处理同环比的不同样式
              judgmentRatio(echartsData.value[i], i, isThan)
            }
          }
          // 如果是柱状图
          if (chatType.value == 0) {
            for (let i = 0; i < echartsData.value.length; i++) {
              echartsData.value[i].type = 'bar'
              // 处理同环比的不同样式
              judgmentRatio(echartsData.value[i], i, isThan)
            }
          }

          for (let i = 0; i < echartsData.value.length; i++) {
            strongShowArr.value.push({
              name: echartsData.value[i].deviceName + '-' + echartsData.value[i].titleName,
              color: echartsData.value[i].color
            })
          }
        }
        nextTick(() => {
          chartLoad()
        })
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message
        })
      }
    }).catch(err => {
      console.log(err);
    })
  } else {
    ElMessage({
      type: 'info',
      message: '请选择筛选条件'
    })
  }
}
// 获取表格数据
let tableData = ref([])
let pageData = ref([])
const getTableDataFun = async function () {
  pageData.value = []
  tableData.value = []
  for (let i = 0; i < equipmentArr.value.length; i++) {
    pageData.value.push({
      pageNum: 1,
      pageSize: 10
    })
    let data = {
      startTime: dateTime.value ? dateTime.value[0] : '',
      endTime: dateTime.value ? dateTime.value[1] : '',
      pageNum: pageData.value[i].pageNum,
      pageSize: 10,
      frequency: frequency.value,
      timeUnit: timeUnit.value,
      timeFlag: timeFlag.value,
      params: [equipmentArr.value[i]]
    }
    // 计算同环比时间
    if (yom.value && yom.value[0] == 1) {
      data.lastStartTime = moment(data.startTime).subtract(1, 'years').format("YYYY-MM-DD HH:mm:ss")
      data.lastEndTime = moment(data.endTime).subtract(1, 'years').format("YYYY-MM-DD HH:mm:ss")
      console.log(data.lastStartTime, data.lastEndTime)
    } else if (yom.value && yom.value[0] == 2) {
      if (timeFlag.value === '1M') {
        let lastStartTime = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD HH:mm:ss')
        let lastEndTime = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD HH:mm:ss')
      } else if (timeFlag.value === '1Q') {
        data.lastStartTime = moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).subtract(1, 'quarter').startOf('quarter').format("YYYY-MM-DD HH:mm:ss")
        data.lastEndTime = moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).subtract(1, 'quarter').endOf('quarter').format("YYYY-MM-DD HH:mm:ss")
      } else {
        let timeLength = moment(data.endTime).diff(data.startTime, 'second')
        data.lastStartTime = moment(data.startTime).subtract(timeLength, 'second').format('YYYY-MM-DD HH:mm:ss')
        data.lastEndTime = moment(data.endTime).subtract(timeLength, 'second').format('YYYY-MM-DD HH:mm:ss')
      }
    }
    let flag = false
    for (let i = 0; i < data.params.length; i++) {
      if (!data.params[i].deviceId || (data.params[i].dictKeys.length === 0)) {
        flag = true
      }
      delete data.params[i].dictList
      delete data.params[i].dictIds
    }
    if (data.frequency && data.timeUnit && !flag) {
      openFullScreen()
      await getTableDataApi(data).then(res => {
        loadingAdd.value.close()
        if (res.data.code === 0) {
          let arr = res.data.result.list
          for (let i = 0; i < arr.length; i++) {
            arr[i].labelArr = []
            for (let j = 0; j < arr[i].dataMetricsVO.length; j++) {
              arr[i].dataMetricsVO[j].ts = arr[i].dataMetricsVO[j].ts.slice(0, 10) + ' ' + arr[i].dataMetricsVO[j].ts.slice(11, 19)
              for (let k = 0; k < arr[i].dataMetricsVO[j].metricDataList.length; k++) {
                arr[i].labelArr.push(arr[i].dataMetricsVO[j].metricDataList[k].titleName)
                arr[i].labelArr = Array.from(new Set(arr[i].labelArr))
                let name = arr[i].dataMetricsVO[j].metricDataList[k].titleName
                arr[i].dataMetricsVO[j][name] = Number(arr[i].dataMetricsVO[j].metricDataList[k].val).toFixed(2)
              }
            }
          }
          tableData.value.push(...arr)
        } else {
          ElMessage({
            type: 'error',
            message: res.data.message
          })
        }
      }).catch(err => {
        console.log(err);
      })
    } else {
      ElMessage({
        type: 'info',
        message: '请选择筛选条件'
      })
    }
  }
}

const moreClick = async function (index) {
  openFullScreen()
  pageData.value[index].pageNum = pageData.value[index].pageNum + 1
  let data = {
    startTime: dateTime.value ? dateTime.value[0] : '',
    endTime: dateTime.value ? dateTime.value[1] : '',
    pageNum: pageData.value[index].pageNum,
    pageSize: 10,
    frequency: frequency.value,
    timeUnit: timeUnit.value,
    timeFlag: timeFlag.value,
    params: [equipmentArr.value[index]]
  }
  // 计算同环比时间
  if (yom.value && yom.value[0] == 1) {
    data.lastStartTime = moment(data.startTime).subtract(1, 'years').format("YYYY-MM-DD HH:mm:ss")
    data.lastEndTime = moment(data.endTime).subtract(1, 'years').format("YYYY-MM-DD HH:mm:ss")
    console.log(data.lastStartTime, data.lastEndTime)
  } else if (yom.value && yom.value[0] == 2) {
    if (timeFlag.value === '1M') {
      let lastStartTime = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD HH:mm:ss')
      let lastEndTime = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD HH:mm:ss')
    } else if (timeFlag.value === '1Q') {
      data.lastStartTime = moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).subtract(1, 'quarter').startOf('quarter').format("YYYY-MM-DD HH:mm:ss")
      data.lastEndTime = moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).subtract(1, 'quarter').endOf('quarter').format("YYYY-MM-DD HH:mm:ss")
    } else {
      let timeLength = moment(data.endTime).diff(data.startTime, 'second')
      data.lastStartTime = moment(data.startTime).subtract(timeLength, 'second').format('YYYY-MM-DD HH:mm:ss')
      data.lastEndTime = moment(data.endTime).subtract(timeLength, 'second').format('YYYY-MM-DD HH:mm:ss')
    }
  }
  await getTableDataApi(data).then(res => {
    loadingAdd.value.close()
    if (res.data.code === 0) {
      let arr = res.data.result.list
      if (arr.length > 0) {
        for (let i = 0; i < arr.length; i++) {
          arr[i].labelArr = []
          for (let j = 0; j < arr[i].dataMetricsVO.length; j++) {
            arr[i].dataMetricsVO[j].ts = arr[i].dataMetricsVO[j].ts.slice(0, 10) + ' ' + arr[i].dataMetricsVO[j].ts.slice(11, 19)
            for (let k = 0; k < arr[i].dataMetricsVO[j].metricDataList.length; k++) {
              arr[i].labelArr.push(arr[i].dataMetricsVO[j].metricDataList[k].titleName)
              arr[i].labelArr = Array.from(new Set(arr[i].labelArr))
              let name = arr[i].dataMetricsVO[j].metricDataList[k].titleName
              arr[i].dataMetricsVO[j][name] = Number(arr[i].dataMetricsVO[j].metricDataList[k].val).toFixed(2)
            }
          }
        }
        tableData.value[index].dataMetricsVO.push(...arr[0].dataMetricsVO)
        tableData.value[index].labelArr.push(...arr[0].labelArr)
        tableData.value[index].labelArr = Array.from(new Set(tableData.value[index].labelArr))
      } else {
        ElMessage({
          type: 'info',
          message: '没有更多数据了！'
        })
      }
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}


// 判断同环比方法
const judgmentRatio = function (item, i, isThan) {
  // 如果是带同环比--------------------------------------------
  if (isThan) {
    if (item.name.includes('同比') || item.name.includes('环比')) {
      // 判断当前是同环比********************
      item.color = colorArr3[Math.floor(i / 2)]
      item.lineStyle = {
        width: 3,
      }
      item.symbol = 'circle'
      item.symbolSize = 7
    } else {
      // 判断当前不是同环比********************
      item.color = colorArr[Math.floor(i / 2)]
      item.lineStyle = {
        width: 3,
        shadowColor: colorArr2[Math.floor(i / 2)],
        shadowBlur: 4
      }
      item.symbolSize = 10
    }
  } else {
    // 如果不带同环比--------------------------------------------
    item.color = colorArr[i]
    item.lineStyle = {
      width: 3,
      shadowColor: colorArr2[i],
      shadowBlur: 4
    }
    item.symbolSize = 7
  }

}
// 强调展示选择事件
let strongShowChange = function () {
  // 判断是否为饼图
  if (chatType.value === 4) {
    if (strongShow.value && strongShow.value.length === 0) {
      // 强调展示为空时
      for (let i = 0; i < echartsData.value[0].data.length; i++) {
        echartsData.value[0].data[i].itemStyle = {color: colorArr[i]}
      }
    } else {
      // 强调展示不为空时 处理强调展示的数据
      for (let i = 0; i < echartsData.value[0].data.length; i++) {
        echartsData.value[0].data[i].itemStyle = {color: '#DDDDDD'}
        for (let j = 0; j < strongShow.value.length; j++) {
          if (echartsData.value[0].data[i].name === strongShow.value[j]) {
            echartsData.value[0].data[i].itemStyle = {color: colorArr[i]}
          }
        }
      }
    }
  } else {
    if (strongShow.value && strongShow.value.length === 0) {
      // 强调展示为空时
      let isThan = false
      for (let i = 0; i < echartsData.value.length; i++) {
        if (echartsData.value[i].name.includes('同比') || echartsData.value[i].name.includes('环比')) {
          isThan = true
        }
      }
      for (let i = 0; i < echartsData.value.length; i++) {
        judgmentRatio(echartsData.value[i], i, isThan)
      }
    } else {
      // 强调展示不为空时 处理强调展示的数据
      let isThan = false
      for (let i = 0; i < echartsData.value.length; i++) {
        if (echartsData.value[i].name.includes('同比') || echartsData.value[i].name.includes('环比')) {
          isThan = true
        }
      }
      for (let i = 0; i < echartsData.value.length; i++) {
        echartsData.value[i].color = '#DDDDDD'
        echartsData.value[i].lineStyle = {
          width: 3,
        }
        for (let j = 0; j < strongShow.value.length; j++) {
          if (echartsData.value[i].name === strongShow.value[j]) {
            judgmentRatio(echartsData.value[i], i, isThan)
          }
        }
      }
    }
  }

  nextTick(() => {
    chartLoad()
  })
}
// 展示方式选择事件
const chatTypeChange = function () {
  searchFun()
}
// 查询
const searchFun = async function () {
  if (showFlag.value) {
    strongShowArr.value = []
    isFold.value = true
    await getEchartsDataFun()
  } else {
    await getTableDataFun()
  }
}
// 重置
const resetFun = function () {
  plan.value = ''
  dateTime.value = ''
  frequency.value = 1
  timeUnit.value = ''
  timeFlag.value = ''
  yom.value = []
  equipmentArr.value = [
    {
      deviceId: '',
      dictKeys: []
    }
  ]


  echartsData.value = []
  echartsDataX.value = []
  tableData.value = []
  nextTick(() => {
    chartLoad()
  })
}

// 搜索的数据展开还是收起
let isFold = ref(false)  //是否收起


// 控制展示图表还是表格
let showFlag = ref(true)
const showClick = async function (flag) {
  // showFlag.value = flag === 1;
  if (flag === 1) {
    showFlag.value = true
    await getEchartsDataFun()
    if (tableData.value.length > 0) {
      await nextTick(() => {
        chartLoad()
      })
    }
  } else {
    await getTableDataFun()
    showFlag.value = false
  }
}


// 加载图表
let colorArr = ['rgba(69,125,252,1)', 'rgba(189,82,244,1)', 'rgba(0,229,166,1)', 'rgba(8,205,255,1)', 'rgba(249,74,171,1)', 'rgba(254, 44, 44, 1)', 'rgba(136, 228, 49, 1)', 'rgba(255, 144, 71, 1)', 'rgba(255, 187, 0, 1)', 'rgba(33, 120, 86, 1)', 'rgba(68, 68, 93, 1)', 'rgba(29, 33, 212, 1)', 'rgba(220, 122, 30, 1)']
let colorArr2 = ['rgba(69,125,252,0.2)', 'rgba(189,82,244,0.2)', 'rgba(0,229,166,0.2)', 'rgba(8,205,255,0.2)', 'rgba(249,74,171,0.2)', 'rgba(254, 44, 44, 0.2)', 'rgba(136, 228, 49, 0.2)', 'rgba(255, 144, 71, 0.2)', 'rgba(255, 187, 0, 0.2)', 'rgba(33, 120, 86, 0.2)', 'rgba(68, 68, 93, 0.2)', 'rgba(29, 33, 212, 0.2)', 'rgba(220, 122, 30, 0.2)']
// let colorArr3 = ['rgba(69,125,252,0.4)', 'rgba(189,82,244,0.4)', 'rgba(0,229,166,0.4)', 'rgba(8,205,255,0.4)', 'rgba(249,74,171,0.4)', 'rgba(254, 44, 44, 0.4)', 'rgba(136, 228, 49, 0.4)', 'rgba(255, 144, 71, 0.4)', 'rgba(255, 187, 0, 0.4)', 'rgba(33, 120, 86, 0.4)', 'rgba(68, 68, 93, 0.4)', 'rgba(29, 33, 212, 0.4)', 'rgba(220, 122, 30, 0.4)']
let colorArr3 = ['rgba(181, 203, 254, 1)', 'rgba(229, 186, 251, 1)', 'rgba(153, 244, 219, 1)', 'rgba(156, 235, 255, 1)', 'rgba(253, 182, 222, 1)', 'rgba(255, 171, 171, 1)', 'rgba(208, 244, 173, 1)', 'rgba(255, 209, 180, 1)', 'rgba(255, 228, 153, 1)', 'rgba(166, 201, 187, 1)', 'rgba(180, 180, 190, 1)', 'rgba(164, 166, 238, 1)', 'rgba(241, 202, 165, 1)']

const chartLoad = function () {
  var chartDom = document.querySelector('.chart');
  var myChart = echarts.init(chartDom);
  var option;

  option = {
    // toolbox: {
    //   feature: {
    //     dataZoom: {
    //       yAxisIndex: 'none'
    //     },
    //     restore: {},
    //     saveAsImage: {}
    //   }
    // },
    grid: {
      left: 50,
      right: 20,
    },
    color: colorArr,
    legend: {
      show: true,
      type: 'scroll'
    },
    tooltip: {
      trigger: chatType.value === 4 ? 'item' : 'axis', // axis   item   none三个值
      show: true
    },
    xAxis: {
      type: 'category',
      boundaryGap: chatType.value === 0,
      data: echartsDataX.value,
      show: echartsDataX.value
    },
    yAxis: {
      type: 'value',
    },
    dataZoom: [
      {
        type: 'inside',
        start: 0,
        end: 100,
        disabled: false,
      },
      {
        type: 'slider',
        start: 0,
        end: 100,
        show: echartsDataX.value ? true : false
      }
    ],
    series: echartsData.value
  };
  myChart.clear()
  option && myChart.setOption(option);

  myChart.resize();
  window.onresize = () => {
    myChart.resize();
  }

  // 创建一个ResizeObserver实例
  const observer = new ResizeObserver(entries => {
    myChart.resize();
  });

// 监听目标div元素的宽度变化
  let ele = document.querySelector('.contain')
  observer.observe(ele);
}

// 加载的动画
let loadingAdd = ref()
const openFullScreen = function () {
  loadingAdd.value = ElLoading.service({
    lock: true,
    text: 'Loading',
    background: 'rgba(0, 0, 0, 0.1)'
  })
}
// 点击导出
const exportFun = function () {
  let data = {
    startTime: dateTime.value ? dateTime.value[0] : '',
    endTime: dateTime.value ? dateTime.value[1] : '',
    frequency: frequency.value,
    timeUnit: timeUnit.value,
    timeFlag: timeFlag.value,
    params: equipmentArr.value,
    yom: yom.value[0]
  }
  // 计算同环比时间
  if (yom.value && yom.value[0] == 1) {
    data.lastStartTime = moment(data.startTime).subtract(1, 'years').format("YYYY-MM-DD HH:mm:ss")
    data.lastEndTime = moment(data.endTime).subtract(1, 'years').format("YYYY-MM-DD HH:mm:ss")
  } else if (yom.value && yom.value[0] == 2) {
    if (timeFlag.value === '1M') {
      data.lastStartTime = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD HH:mm:ss')
      data.lastEndTime = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD HH:mm:ss')
    } else if (timeFlag.value === '1Q') {
      data.lastStartTime = moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).subtract(1, 'quarter').startOf('quarter').format("YYYY-MM-DD HH:mm:ss")
      data.lastEndTime = moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).subtract(1, 'quarter').endOf('quarter').format("YYYY-MM-DD HH:mm:ss")
    } else {
      let timeLength = moment(data.endTime).diff(data.startTime, 'second')
      data.lastStartTime = moment(data.startTime).subtract(timeLength, 'second').format('YYYY-MM-DD HH:mm:ss')
      data.lastEndTime = moment(data.endTime).subtract(timeLength, 'second').format('YYYY-MM-DD HH:mm:ss')
    }
  }
  let flag = false
  for (let i = 0; i < data.params.length; i++) {
    if (!data.params[i].deviceId || (data.params[i].dictKeys.length === 0)) {
      flag = true
    }
    delete data.params[i].dictList
    delete data.params[i].dictIds
  }

  if (data.frequency && data.timeUnit && !flag) {
    const downloadZip = (data, fileName = '数据查询') => {
      var blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})
      if ('download' in document.createElement('a')) {
        var downloadElement = document.createElement('a')
        var href = window.URL.createObjectURL(blob) //创建下载的链接
        downloadElement.href = href
        downloadElement.download = fileName //下载后文件名
        document.body.appendChild(downloadElement)
        downloadElement.click() //点击下载
        document.body.removeChild(downloadElement) //下载完成移除元素
        window.URL.revokeObjectURL(href) //释放掉blob对象
      }
    }
    openFullScreen()
    exportDataApi(data).then(res => {
      loadingAdd.value.close()
      downloadZip(res.data)
    }).catch(err => {
      console.log(err);
    })
  } else {
    ElMessage({
      type: 'info',
      message: '请选择筛选条件'
    })
  }
}


// 获取查询方案列表
let planList = ref([])
const getSearchListFun = async function () {
  await getSearchListApi(0).then(res => {
    if (res.data.code === 200) {
      planList.value = res.data.data
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 查询方案change事件
let planName = ref()
const planChange = async function () {
  for (let i = 0; i < planList.value.length; i++) {
    if (planList.value[i].id == plan.value) {
      planName.value = planList.value[i].queryName
    }
  }
  await getSearchDetailApi(plan.value).then(res => {
    if (res.data.code === 200) {
      isFold.value = false
      frequency.value = res.data.data.frequency
      timeUnit.value = res.data.data.timeUnit
      timeFlag.value = res.data.data.timeFlag
      chatType.value = res.data.data.chatType
      yom.value = res.data.data.yom ? [res.data.data.yom] : []
      timeFlagSelect()
      if (timeFlag.value === '-1' || timeFlag.value === 'now') {
        dateTime.value = [res.data.data.startTime, res.data.data.endTime]
      }
      equipmentArr.value = res.data.data.deviceConditionList
      for (let i = 0; i < res.data.data.deviceConditionList.length; i++) {
        getDataListFun(i, res.data.data.deviceConditionList[i].deviceId)
      }

      // 处理指标
      for (let i = 0; i < res.data.data.deviceConditionList.length; i++) {
        res.data.data.deviceConditionList[i].dictKeys = []
        for (let j = 0; j < res.data.data.deviceConditionList[i].dictList.length; j++) {
          res.data.data.deviceConditionList[i].dictKeys[j] = res.data.data.deviceConditionList[i].dictList[j].key
        }
      }
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}


// 进入全屏
const enterFullScreen = () => {
  // 获取layout的dom元素
  const parentNode = document.querySelector('.contain'); // 获取需要全屏的元素;
  document.querySelector('.contain .chart').style.height = 'calc(100% - 200px)'  //进入全屏后变大图片
  // 进入全屏
  if (parentNode.requestFullscreen) {
    parentNode.requestFullscreen();
  } else if (parentNode.webkitRequestFullScreen) {
    parentNode.webkitRequestFullScreen();
  } else if (parentNode.mozRequestFullScreen) {
    parentNode.mozRequestFullScreen();
  } else if (parentNode.msRequestFullscreen) {
    // IE11
    parentNode.msRequestFullscreen();
  }
  nextTick(() => {
    chartLoad()
  })

};
// 退出全屏
const exitFullscreen = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if ((document).webkitCancelFullScreen) {
    (document).webkitCancelFullScreen();
  } else if ((document).mozCancelFullScreen) {
    (document).mozCancelFullScreen();
  } else if ((document).msExitFullscreen) {
    (document).msExitFullscreen();
  }
  nextTick(() => {
    chartLoad()
  })
};


// 筛选树形结构唯一值
const onlySelect = function (data) {
  for (let i = 0; i < data.length; i++) {
    if (data[i].type == 1) {
      data[i].id = data[i].id + '_' + data[i].type
    }
    // data[i].onlyValue = data[i].id + '_' + data[i].type
    if (data[i].subList.length > 0) {
      onlySelect(data[i].subList)
    }
  }
}
// 项目树配置
let props = {
  label: 'name',
  value: 'id',
  children: 'subList',
  disabled: 'isProject',
  isLeaf: (data, node) => {
    if (node.data.type === 2) {
      return true
    }
  }
}
// 自定义树形结构的前面图标
const renderContent = (h, {node, data}) => {
  return h('div', [
    h('img', {
      src: data.isProject ? 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/energyIcon5.png' : 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/energyIcon4.png',
      style: {
        width: '16px',
        height: '16px',
        transform: 'translateY(3px)',
        marginRight: '5px'
      }
    }, ''),
    h('span', null, node.label)
  ])
}

let allTree = ref()   // 树形结构数据
let intervalList = ref([])  //数据间隔列表
onMounted(async () => {
  // getEquipmentListFun()
  await getSearchListFun()
  if (router.currentRoute.value.query.id) {
    plan.value = router.currentRoute.value.query.id
    await planChange()
    await getEchartsDataFun()
  }

  // 获取所有设备树
  getAllTreeApi2().then(async res => {
    if (res.data.code === 0) {
      let data = res.data.result
      await onlySelect(data)
      allTree.value = data
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message,
      })
    }
  }).catch(err => {
    console.log(err);
  })

  // 获取数据间隔列表
  getIntervalListApi().then(async res => {
    if (res.data.code === 0) {
      intervalList.value = res.data.result
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message,
      })
    }
  }).catch(err => {
    console.log(err);
  })

  document.addEventListener("fullscreenchange", (event) => {
    if (document.fullscreenElement) {
      document.querySelector('.fullScreenHead').style.display = 'flex'
      document.querySelector('.contain').style.paddingTop = 0
    } else {
      document.querySelector('.fullScreenHead').style.display = 'none'
      document.querySelector('.contain .chart').style.height = '400px'
      document.querySelector('.contain').style.paddingTop = '16px'
    }
  });
})

</script>

<style lang="less" scoped>
</style>
