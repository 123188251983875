<template>
  <div class="landlord">
    <!-- <div class="title">
      <p>
        <span>房东信息管理</span>
      </p>
    </div> -->
    <div class="content">
      <div class="searchBox">
        <div class="liBox">
          <label style="margin-right: 10px">房东</label>
          <el-input
            v-model="queryParams.landlordName"
            prefix-icon="Search"
            clearable
            placeholder="请输入房东"
          ></el-input>
        </div>
        <div class="liBox">
          <label style="margin-right: 10px">手机号</label>
          <el-input
            v-model="queryParams.mobile"
            prefix-icon="Search"
            clearable
            placeholder="请输入手机号"
          ></el-input>
        </div>
        <div class="liBox">
          <el-button class="searchButton" type="primary" @click="handleQuery"
            >查询</el-button
          >
          <el-button class="searchButton resetButton" @click="resetQuery"
            >重置</el-button
          >
        </div>
      </div>
      <div class="contain">
        <el-button class="addBtn" type="primary" @click="handleAdd">
          <el-icon>
            <Plus />
          </el-icon>
          新建房东信息
        </el-button>
        <el-table v-loading="loading" :data="landlordList">
          <template #empty>
            <el-empty
              class="emptyClass"
              description="暂无数据信息"
              image="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png"
            >
              <p>您可以在此页面新建房东信息</p>
            </el-empty>
          </template>
          <el-table-column
            label="房东姓名"
            align="center"
            prop="landlordName"
          />
          <el-table-column label="户名" align="center" prop="userName" />
          <el-table-column label="手机号码" align="center" prop="mobile" />
          <el-table-column label="租户数量" align="center" prop="tenantNum">
            <template #default="scope">
              <el-button
                v-if="scope.row.tenantNum > 0"
                type="primary"
                @click="handleTenantNum(scope.row)"
                >{{ scope.row.tenantNum }}</el-button
              >
              <span v-else>{{ scope.row.tenantNum }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="设备数量"
            align="center"
            prop="totalEquipmentNum"
          >
            <template #default="scope">
              <el-button
                v-if="scope.row.totalEquipmentNum > 0"
                type="primary"
                @click="handleEquipment(scope.row, '2')"
                >{{ scope.row.totalEquipmentNum }}</el-button
              >
              <span v-else>{{ scope.row.totalEquipmentNum }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="300px">
            <template #default="scope">
              <div class="opacity">
                <p class="btn" @click="handleEquipment(scope.row, '1')">
                  绑定设备
                </p>
                <p class="btn" @click="handleUnBind(scope.row)">解绑租户</p>
                <p class="btn" @click="handleUpdate(scope.row)">修改</p>
                <p class="btn delClass" @click="handleDelete(scope.row)">
                  删除
                </p>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pag">
          <el-pagination
            v-model:page-size="queryParams.pageSize"
            :page-sizes="[10, 20, 50, 100]"
            :total="total"
            background
            layout="total,sizes,prev, pager, next"
            small
            @size-change="handleSizeChange"
            @current-change="pageChange"
          />
        </div>
      </div>
    </div>

    <el-dialog
      :title="title"
      v-model="open"
      :close-on-click-modal="false"
      width="500px"
      append-to-body
    >
      <el-form
        ref="landlordRef"
        :model="form"
        :rules="rules"
        label-width="auto"
      >
        <el-form-item v-if="isShow" label="项目" prop="projectId">
          <el-select
            v-model="form.projectId"
            placeholder="请选择项目"
            clearable
            filterable
            style="width: 100%"
          >
            <el-option
              v-for="item in formLists.projectList"
              :key="item.projectId"
              :label="item.projectName"
              :value="item.projectId"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="房东姓名" prop="landlordName">
          <el-input
            v-model="form.landlordName"
            placeholder="请输入房东姓名"
            clearable
          />
        </el-form-item>
        <el-form-item label="户名" prop="userName">
          <el-input
            v-model="form.userName"
            placeholder="请输入户名"
            clearable
          />
        </el-form-item>
        <el-form-item label="手机号码" prop="mobile">
          <el-input
            v-model="form.mobile"
            placeholder="请输入手机号码"
            clearable
          />
        </el-form-item>
        <el-form-item label="详细地址" prop="address">
          <el-input
            v-model="form.address"
            placeholder="请输入详细地址"
            clearable
          />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button class="cancle resetButton" @click="cancel">取消</el-button>
          <el-button class="confrim" type="primary" @click="submitForm"
            >确定</el-button
          >
        </span>
      </template>
    </el-dialog>

    <el-dialog
      :title="equipmentForm.type === '1' ? '绑定设备' : '解绑设备'"
      v-model="equipmentOpen"
      :close-on-click-modal="false"
      width="1000px"
      append-to-body
    >
      <el-input
        v-model="equipmentQueryParams.equipmentName"
        clearable
        placeholder="请输入设备名称"
        style="width: 200px; margin: 0 10px 10px 0"
        @input="getAllDeviceListFun"
      ></el-input>

      <el-table
        :data="equipmentList"
        @selection-change="handleEquipmentSelectionChange"
      >
        <template #empty>
          <el-empty
            class="emptyClass"
            description="暂无数据信息"
            image="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png"
          >
            <p>暂无设备信息</p>
          </el-empty>
        </template>
        <el-table-column type="selection" align="center" width="55" />
        <el-table-column label="设备名称" align="center" prop="equipmentName" />
        <el-table-column
          label="设备地址"
          align="center"
          prop="equipmentAddress"
        />
        <el-table-column label="产品型号" align="center" prop="productName" />
        <el-table-column label="创建人" align="center" prop="createName" />
        <el-table-column label="创建时间" align="center" prop="createTime" />
      </el-table>

      <div style="display: flex; justify-content: flex-end; margin-top: 20px">
        <el-pagination
          v-model:page-size="equipmentQueryParams.pageSize"
          :page-sizes="[10, 20, 50, 100]"
          :total="equipmentTotal"
          background
          layout="total,sizes,prev, pager, next"
          small
          @size-change="handleEquipmentSizeChange"
          @current-change="pageEquipmentChange"
        />
      </div>

      <template #footer>
        <span class="dialog-footer">
          <el-button class="cancle resetButton" @click="equipmentCancel"
            >取消</el-button
          >
          <el-button
            class="confrim"
            type="primary"
            @click="submitEquipmentOpenForm"
            >确定</el-button
          >
        </span>
      </template>
    </el-dialog>

    <el-dialog
      title="解绑租户"
      v-model="unBindOpen"
      width="500px"
      append-to-body
    >
      <el-form
        ref="unBindFormRef"
        :model="unBindForm"
        :rules="unBindRules"
        label-width="auto"
      >
        <el-form-item label="租户" prop="tenantIds">
          <el-select
            v-model="unBindForm.tenantIds"
            placeholder="请选择租户"
            clearable
            filterable
            multiple
            style="width: 100%"
          >
            <el-option
              v-for="item in unBindFormLists.tenantList"
              :key="item.tenantId"
              :label="item.tenantName"
              :value="item.tenantId"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" @click="submitUnBindForm">确 定</el-button>
          <el-button @click="unBindCancel">取 消</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, reactive, toRefs, h } from 'vue'
import './landlord.less'
import { ElMessage, ElMessageBox } from 'element-plus'
import {
  listLandlord,
  getLandlord,
  delLandlord,
  addLandlord,
  updateLandlord,
  listLandlordProject,
  listLandlordEquipment,
  bindEquipment,
  unbindEquipment,
  unbindTenant,
} from '@/api/modules/landlord'
import { getTenantList } from '@/api/modules/tenant'
import { useRouter } from 'vue-router'

const router = useRouter()

const landlordList = ref([])
const open = ref(false)
const unBindOpen = ref(false)
const loading = ref(true)
const total = ref(0)
const title = ref('')
const landlordRef = ref(null)
const equipmentOpen = ref(false)
const equipmentList = ref([])
const equipmentQueryParams = ref({})
const equipmentTotal = ref(0)
const equipmentForm = ref({})
const isShow = ref(false)
const unBindFormRef = ref(null)

const formLists = reactive({
  projectList: [],
})

const data = reactive({
  form: {},
  queryParams: {
    pageNumber: 1,
    pageSize: 10,
    landlordName: null,
    mobile: null,
  },
  rules: {
    projectId: [{ required: true, message: '项目不能为空', trigger: 'blur' }],
    landlordName: [
      { required: true, message: '房东姓名不能为空', trigger: 'blur' },
    ],
    userName: [{ required: true, message: '户名不能为空', trigger: 'blur' }],
    mobile: [{ required: true, message: '手机号码不能为空', trigger: 'blur' }],
  },
})

const { queryParams, form, rules } = toRefs(data)

const unBindForm = ref({})
const unBindRules = reactive({
  tenantIds: [{ required: true, message: '租户不能为空', trigger: 'blur' }],
})

const unBindFormLists = reactive({
  tenantList: [],
})

/** 查询房东列表 */
function getList() {
  loading.value = true
  listLandlord(queryParams.value).then((response) => {
    landlordList.value = response.data.result.list
    total.value = Number(response.data.result.total)
    loading.value = false
  })
}

// 取消按钮
function cancel() {
  open.value = false
  reset()
}

// 表单重置
function reset() {
  form.value = {
    id: null,
    projectId: null,
    landlordName: null,
    userName: null,
    mobile: null,
    address: null,
  }
  landlordRef.value?.resetFields()
}

/** 搜索按钮操作 */
function handleQuery() {
  queryParams.value.pageNumber = 1
  getList()
}

/** 重置按钮操作 */
function resetQuery() {
  queryParams.value = {
    pageNumber: 1,
    pageSize: 10,
    landlordName: null,
    mobile: null,
  }
  handleQuery()
}

/** 新建按钮操作 */
function handleAdd() {
  reset()
  listLandlordProject().then((res) => {
    formLists.projectList = res.data.result
    if (formLists.projectList.length === 1) {
      form.value.projectId = formLists.projectList[0].projectId
      isShow.value = false
    } else {
      isShow.value = true
    }
    open.value = true
    title.value = '新建房东信息'
  })
}

/** 修改按钮操作 */
function handleUpdate(row) {
  reset()
  listLandlordProject().then((res) => {
    formLists.projectList = res.data.result
    getLandlord(row.id).then((res) => {
      form.value = res.data.result
      if (
        formLists.projectList.length === 1 &&
        form.value.projectId === formLists.projectList[0].projectId
      ) {
        isShow.value = false
      } else {
        isShow.value = true
      }
      open.value = true
      title.value = '修改房东信息'
    })
  })
}

/** 提交按钮 */
function submitForm() {
  landlordRef.value.validate((valid) => {
    if (valid) {
      if (form.value.id != null) {
        updateLandlord(form.value).then((response) => {
          if (response.data.code === 0) {
            ElMessage.success('修改成功')
            open.value = false
            getList()
          } else {
            ElMessage({
              type: 'error',
              message: response.data.message,
            })
          }
        })
      } else {
        addLandlord(form.value).then((response) => {
          if (response.data.code === 0) {
            ElMessage.success('新建成功')
            open.value = false
            getList()
          } else {
            ElMessage({
              type: 'error',
              message: response.data.message,
            })
          }
        })
      }
    }
  })
}

/** 删除按钮操作 */
function handleDelete(row) {
  ElMessageBox.confirm('确认删除吗，删除后不可恢复，请谨慎操作', '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '删除房东信息',
    message: h('p', null, [
      h(
        'img',
        {
          src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
          style:
            'width:20px;height:20px;transform: translateY(4px);margin-right:8px;',
        },
        ''
      ),
      h(
        'span',
        {
          style: 'color:#595959;',
        },
        '请确认是否要删除此房东信息，删除后数据不可恢复'
      ),
    ]),
  })
    .then(function () {
      return delLandlord(row.id)
    })
    .then((response) => {
      if (response.data.code === 0) {
        getList()
        ElMessage.success('删除成功')
      } else {
        ElMessage({
          type: 'error',
          message: response.data.message,
        })
      }
    })
    .catch(() => {
      ElMessage({
        message: '已取消删除',
        type: 'info',
      })
    })
}

getList()

function handleSizeChange(e) {
  queryParams.value.pageSize = e
  getList()
}

function pageChange(e) {
  queryParams.value.pageNumber = e
  getList()
}

let timer = null

function getAllDeviceListFun() {
  clearTimeout(timer)
  timer = setTimeout(() => {
    equipmentQueryParams.value.pageNumber = 1
    listLandlordEquipment(equipmentQueryParams.value).then((res) => {
      equipmentList.value = res.data.result.list
      equipmentTotal.value = Number(res.data.result.total)
    })
  }, 300)
}

function handleEquipment(row, type) {
  equipmentReset()
  equipmentForm.value.landlordId = row.id
  equipmentForm.value.type = type
  equipmentQueryParams.value.landlordId = row.id
  equipmentQueryParams.value.type = type
  listLandlordEquipment(equipmentQueryParams.value).then((res) => {
    equipmentList.value = res.data.result.list
    equipmentTotal.value = Number(res.data.result.total)
    equipmentOpen.value = true
  })
}

function equipmentReset() {
  equipmentQueryParams.value = {
    type: null,
    landlordId: null,
    pageNumber: 1,
    pageSize: 10,
    equipmentName: null,
  }
  equipmentList.value = []
  equipmentForm.value = {
    type: null,
    landlordId: null,
    equipmentIds: [],
  }
}

function equipmentCancel() {
  equipmentOpen.value = false
  equipmentReset()
}

function submitEquipmentOpenForm() {
  if (equipmentForm.value.equipmentIds.length === 0) {
    equipmentOpen.value = false
    return
  }

  if (equipmentForm.value.type === '1') {
    bindEquipment(equipmentForm.value).then((res) => {
      if (res.data.code === 0) {
        ElMessage.success('绑定成功')
        equipmentOpen.value = false
        getList()
      } else {
        ElMessage.error(res.data.message)
      }
    })
  }

  if (equipmentForm.value.type === '2') {
    unbindEquipment(equipmentForm.value).then((res) => {
      if (res.data.code === 0) {
        ElMessage.success('解绑成功')
        equipmentOpen.value = false
        getList()
      } else {
        ElMessage.error(res.data.message)
      }
    })
  }
}

function handleEquipmentSizeChange(e) {
  equipmentQueryParams.value.pageSize = e
  listLandlordEquipment(equipmentQueryParams.value).then((res) => {
    equipmentList.value = res.data.result.list
    equipmentTotal.value = Number(res.data.result.total)
  })
}

function pageEquipmentChange(e) {
  equipmentQueryParams.value.pageNumber = e
  listLandlordEquipment(equipmentQueryParams.value).then((res) => {
    equipmentList.value = res.data.result.list
    equipmentTotal.value = Number(res.data.result.total)
  })
}

function handleEquipmentSelectionChange(selection) {
  equipmentForm.value.equipmentIds = selection.map((item) => item.equipmentId)
}

function submitUnBindForm() {
  unBindFormRef.value.validate((valid) => {
    if (valid) {
      unbindTenant(unBindForm.value).then((res) => {
        if (res.data.code === 0) {
          ElMessage.success('解绑成功')
          unBindOpen.value = false
          getList()
        } else {
          ElMessage.error(res.data.message)
        }
      })
    }
  })
}

function unBindReset() {
  unBindForm.value = {
    landlordId: null,
    tenantIds: [],
  }
  unBindFormRef.value?.resetFields()
}

function unBindCancel() {
  unBindOpen.value = false
  unBindReset()
}

function handleUnBind(row) {
  unBindReset()
  unBindForm.value.landlordId = row.id
  getTenantList(unBindForm.value.landlordId).then((response) => {
    unBindFormLists.tenantList = response.data.result
    unBindOpen.value = true
  })
}

function handleTenantNum(row) {
  router.push({
    path: '/admin/tenant',
    query: {
      landlordId: row.id,
    },
  })
}
</script>
