<template>
  <div class="compoundQueryPlanLocal">
    <!--标题-->
    <div class="title">
      <p>
        <span @click="goBack">复合查询</span>
        <span> / </span>
        <span>复合查询管理</span>
      </p>
      <p class="tit">
        <el-icon @click="goBack">
          <Back/>
        </el-icon>
        复合查询管理
      </p>
    </div>
    <!--列表-->
    <div class="content">
      <div class="contain">
        <!--新增查询-->
        <el-button class="addBtn" type="primary" @click="addClick">
          <el-icon>
            <Plus/>
          </el-icon>
          新增查询
        </el-button>
        <!--工单列表-->
        <el-table v-loading="loading" :data="tableData" style="width: 100%">
          <template #empty>
            <el-empty class="emptyClass" description="暂无数据信息"
                      image="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
              <p>您可以在此页面新增复合查询方案</p>
            </el-empty>
          </template>
          <el-table-column label="查询名称" prop="queryName"></el-table-column>
          <el-table-column label="创建时间" prop="ct" sortable></el-table-column>
          <el-table-column label="创建人" prop="creator"></el-table-column>
          <el-table-column fixed="right" label="操作" width="180px">
            <template #default="scope">
              <div class="opacity">
                <span class="editClass btn" @click.prevent="openClick(scope.row.id,scope.row)">打开</span>
                <span class="editClass btn" @click.prevent="editClick(scope.row.id,scope.row)">编辑</span>
                <span class="editClass btn delClass" @click.prevent="delClick(scope.row.id,scope.row)">删除</span>
                <!--                <img alt="" src="../../../assets/img/161@2x.png" @click.prevent="editClick(scope.row.id,scope.row)">-->
                <!--                <img alt="" src="../../../assets/img/163@2x.png" @click.prevent="delClick(scope.row.id,scope.row)">-->
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pag">
          <el-pagination v-model:page-size="pageSize" :page-sizes="[10, 20, 50, 100]" :total="total" background
                         layout="total,sizes,prev, pager, next" small @size-change="handleSizeChange"
                         @current-change="pageChange"/>
        </div>
      </div>
    </div>
    <!--弹窗-->
    <el-dialog v-model="dialogFormVisible" :close-on-click-modal="false" :title="formData.id?'编辑查询':'新建查询'"
               width="60%">
      <el-form ref="fromRef" :inline="true" :model="formData" :rules="formRules">
        <el-form-item label="查询名称" prop="queryName" style="width: 34%;">
          <el-input v-model="formData.queryName" placeholder="请输入查询名称"></el-input>
        </el-form-item>
        <el-form-item label="采集频率" prop="frequency">
          <el-input v-model="formData.frequency" :disabled="true" placeholder="请输入采集频率" type="number">
            <!--            <template #suffix>-->
            <!--              小时-->
            <!--            </template>-->
            <template #append>
              <el-select v-model="formData.timeUnit" style="width: 90px;">
                <el-option label="分钟" value="m"/>
                <el-option label="小时" value="h"/>
                <el-option label="天" value="d"/>
                <el-option label="月" value="n"/>
                <el-option label="年" value="y"/>
              </el-select>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item label="日期期限" prop="timeFlag" style="width: 34%;">
          <el-select v-model="formData.timeFlag" clearable @change="timeFlagSelect">
            <el-option label="自定义时间段" value="-1"></el-option>
            <el-option label="近1小时" value="1H"></el-option>
            <el-option label="近12小时" value="12H"></el-option>
            <el-option label="近一天" value="1D"></el-option>
            <el-option label="近3天" value="3D"></el-option>
            <el-option label="近1周" value="1W"></el-option>
            <el-option label="本月" value="1M"></el-option>
            <el-option label="本季" value="1Q"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="查询日期" prop="date" style="width: 50%">
          <el-date-picker v-model="formData.date" end-placeholder="结束时间" popper-class="date-style"
                          range-separator="→"
                          start-placeholder="开始时间" type="datetimerange"
                          value-format="YYYY-MM-DD HH:mm:ss">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="" prop="yom" style="width: 16%">
          <el-checkbox-group v-model="formData.yom" :max="1" :min="0"
                             style="display: inline-block;margin-left: 20px">
            <el-checkbox :label="1">同比</el-checkbox>
            <el-checkbox :label="2">环比</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <div v-for="(item,index) in formData.compositeIndexList" :key="index" class="rowItem">
          <div v-if="formData.compositeIndexList.length!==1" style="display: flex;align-items: center">
            <el-icon class="el-icon-delete delFilter"
                     style="color: #FE4761;font-size: 16px;cursor: pointer;"
                     @click="delCompound(index)">
              <Delete/>
            </el-icon>
          </div>
          <div>
            <div>
              <el-input v-model="item.name" placeholder="请输入复合指标名称" style="width: 160px"></el-input>
              <div class="contTime">
                <el-radio-group v-model="item.chatType">
                  <el-radio :label="0" size="small">柱状</el-radio>
                  <el-radio :label="1" size="small">折线</el-radio>
                  <br/>
                  <el-radio :label="2" size="small">面积</el-radio>
                  <el-radio :label="3" size="small">点</el-radio>
                </el-radio-group>
              </div>
              <el-checkbox-group v-model="item.valueType">
                <el-checkbox :label="0">最大值</el-checkbox>
                <el-checkbox :label="1">最小值</el-checkbox>
              </el-checkbox-group>
            </div>
            <p>=</p>
          </div>
          <div v-for="(item_child,index_child) in item.data" :key="index_child"
               :style="{alignItems:item_child.type===''?'center':'start'}" class="contItemBox">
            <div v-if="index_child!==0" class="symbol">
              <p
                  :class="item.formula.substring(2*index_child-1,2*index_child)==='+'?'activeSymbol':''"
                  @click="symbolClick(index,index_child,'+')">+</p>
              <p
                  :class="item.formula.substring(2*index_child-1,2*index_child)==='-'?'activeSymbol':''"
                  @click="symbolClick(index,index_child,'-')">-</p>
              <p
                  :class="item.formula.substring(2*index_child-1,2*index_child)==='×'||item.formula.substring(2*index_child-1,2*index_child)==='*'?'activeSymbol':''"
                  @click="symbolClick(index,index_child,'*')">×</p>
              <p
                  :class="item.formula.substring(2*index_child-1,2*index_child)==='÷'||item.formula.substring(2*index_child-1,2*index_child)==='/'?'activeSymbol':''"
                  @click="symbolClick(index,index_child,'/')">÷</p>
            </div>
            <div class="contItem">
              <el-select v-model="item_child.type" clearable placeholder="请选择参数类型">
                <el-option :value="1" label="指标"/>
                <el-option :value="0" label="数值"/>
              </el-select>
              <div v-if="item_child.type!==''" class="contBox">
                <el-icon v-if="item.data.length>1" class="delIcon" @click="delControl(index,index_child)">
                  <CircleClose/>
                </el-icon>
                <el-select v-show="item_child.type==1" ref="elSelectTable" v-model="item_child.typeName"
                           :filter-method="filterMethod" :popper-append-to-body="false"
                           class="select-unitName select-option-father" clearable filterable
                           popper-class="select_report_and_type">
                  <el-option :value="dataItemList">
                    <el-tree
                        ref="treeRef"
                        :expand-on-click-node=true
                        :filter-node-method="filterNode"
                        :load="treeList"
                        :props="props"
                        highlight-current
                        lazy
                        @node-click="clickUnitTreeNodeChild($event,index,index_child)"
                    />
                  </el-option>
                </el-select>
                <div class="center">
                  <el-input v-if="item_child.type==0" v-model="item_child.val" class="val" placeholder="请输入"
                            type="number"
                  />
                  <p v-if="item_child.type==1">{{ item_child.deviceName }}</p>
                  <p v-if="item_child.type==1">{{ item_child.typeName }}</p>
                </div>
                <p v-if="item_child.type==1" class="unit">{{ item_child.unit }}</p>
              </div>
              <div v-if="item_child.type==1" class="contTime">
                <el-checkbox v-model="item_child.difference" label="差值" size="large"/>
              </div>
            </div>
          </div>
          <div>
            <el-icon class="addIcon" @click="addControl(index)">
              <CirclePlus/>
            </el-icon>
          </div>
        </div>
        <el-button style="margin-bottom: 0;width: 160px" type="primary" @click="addCompound">添加复合指标
        </el-button>
      </el-form>
      <template #footer>
      <span class="dialog-footer">
        <el-button class="cancle resetButton" @click="dialogFormVisible=false">取消</el-button>
        <el-button class="confrim" type="primary" @click="save()">保存</el-button>
      </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {h, onMounted, reactive, ref} from "vue"
import './compoundQueryPlan.less'

import {Delete, ZoomIn, Plus, Back, CircleClose, CirclePlus} from '@element-plus/icons-vue'
import {CascaderProps, ElMessage, ElMessageBox} from 'element-plus'
import {useRouter} from 'vue-router';
import qs from "qs";
import {
  getQueryListApi,
  getQueryDetailApi,
  saveQueryListApi,
  editQueryListApi,
  delQueryListApi
} from '@/api/modules/compoundQuery'

import moment from "moment";
import {getTreeListApi} from "@/api/modules/control";
import letterList from "@/assets/js/letterList";


let router = useRouter()

// 返回
const goBack = function () {
  router.push('/admin/compoundQueryLocal')
}


// 工单列表-----------------------------------------------------------------
// 分页
let pageNumber = ref(1)
let pageSize = ref(20)
let total = ref()
// 更改每页多少
const handleSizeChange = function (e) {
  pageSize.value = e
  getListFun()
}
// 跳转到某页
const pageChange = function (e) {
  pageNumber.value = e
  getListFun()
}
// 表格数据
let tableData = ref([])
// 获取表格数据
let loading = ref(false)
const getSearchListFun = function () {
  loading.value = true
  getQueryListApi(1).then(res => {
    loading.value = false
    if (res.data.code === 200) {
      tableData.value = res.data.data
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}


// 新增编辑-----------------------------------------------------------------
// 新增编辑弹窗
let dialogFormVisible = ref(false)

// 新增编辑表单数据
let formData = ref({
  queryName: '',
  queryType: 1,
  timeFlag: '',
  yom: [],
  date: [],
  startTime: '',
  endTime: '',
  frequency: 1,
  timeUnit: 'h',
  creator: window.localStorage.getItem('memberId'),
  compositeIndexList: [
    {
      formula: 'A',
      name: '',
      chatType: '',
      valueType: [],
      data: [{
        val: "",
        type: '',
        unit: "",
        typeId: '',
        typeName: "",
        replaceStr: "A",
        difference: '',
        deviceId: '',
        deviceName: '',
      }]
    },
  ]
})
// 表单规则
let formRules = ref({
  queryName: [
    {required: true, message: '请输入查询方案名称', trigger: 'blur'},
  ],
  timeFlag: [
    {required: true, message: '请选择日期期限', trigger: 'blur'},
  ],
  date: [
    {required: true, message: '请选择时间', trigger: 'blur'},
  ]
})
// 日期快捷选择事件
const timeFlagSelect = function () {
  console.log(formData.value.timeFlag)
  if (formData.value.timeFlag === '-1') {
    formData.value.date = []
  } else if (formData.value.timeFlag === '1H') {
    formData.value.date = [moment().subtract(1, "hours").format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (formData.value.timeFlag === '12H') {
    formData.value.date = [moment().subtract(12, "hours").format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (formData.value.timeFlag === '1D') {
    formData.value.date = [moment().subtract(1, "days").format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (formData.value.timeFlag === '3D') {
    formData.value.date = [moment().subtract(3, "days").format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (formData.value.timeFlag === '1W') {
    formData.value.date = [moment().subtract(1, "weeks").format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (formData.value.timeFlag === '1M') {
    formData.value.date = [moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'), moment().endOf('month').format('YYYY-MM-DD HH:mm:ss')]
  } else if (formData.value.timeFlag === '1Q') {
    formData.value.date = [moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).startOf('quarter').format("YYYY-MM-DD HH:mm:ss"), moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).endOf('quarter').format("YYYY-MM-DD HH:mm:ss")]
  }
}
// 点击新增
const addClick = function () {
  dialogFormVisible.value = true
  formData.value = {
    queryName: '',
    queryType: 1,
    timeFlag: '',
    yom: [],
    date: '',
    startTime: '',
    endTime: '',
    frequency: 1,
    timeUnit: 'h',
    creator: window.localStorage.getItem('memberId'),
    compositeIndexList: [
      {
        formula: 'A',
        name: '',
        chatType: '',
        valueType: [],
        data: [{
          val: "",
          type: '',
          unit: "",
          typeId: '',
          typeName: "",
          replaceStr: "A",
          difference: '',
          deviceId: '',
          deviceName: '',
        }]
      },
    ]
  }
}
// 点击编辑
const editClick = function (id, row) {
  dialogFormVisible.value = true
  getQueryDetailApi(id).then(res => {
    if (res.data.code === 200) {
      formData.value = {
        id: id,
        queryName: row.queryName,
        queryType: 1,
        timeFlag: res.data.data.timeFlag,
        yom: res.data.data.yom ? [res.data.data.yom] : [],
        date: [res.data.data.startTime, res.data.data.endTime],
        startTime: res.data.data.startTime,
        endTime: res.data.data.endTime,
        frequency: res.data.data.frequency,
        timeUnit: res.data.data.timeUnit,
        creator: window.localStorage.getItem('memberId'),
        compositeIndexList: res.data.data.compositeIndexList
      }
      for (let i = 0; i < formData.value.compositeIndexList.length; i++) {
        // 处理最大值最小值
        formData.value.compositeIndexList[i].valueType = formData.value.compositeIndexList[i].valueType.split(',')
        formData.value.compositeIndexList[i].valueType = formData.value.compositeIndexList[i].valueType.map(Number)
        // 处理差值
        for (let j = 0; j < formData.value.compositeIndexList[i].data.length; j++) {
          formData.value.compositeIndexList[i].data[j].difference = formData.value.compositeIndexList[i].data[j].difference === 1 ? true : false
        }
      }
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 提交
let fromRef = ref()
const save = function () {
  // 提交表单
  if (fromRef) {
    fromRef.value.validate((valid) => {
      //提交成功
      if (valid) {
        formData.value.startTime = formData.value.date[0]
        formData.value.endTime = formData.value.date[1]
        // 处理同比和环比
        if (formData.value.yom.length === 0) {
          formData.value.yom = 0
        } else {
          formData.value.yom = formData.value.yom[0]
        }
        // 处理差值
        for (let i = 0; i < formData.value.compositeIndexList.length; i++) {
          // 处理最大最小值
          formData.value.compositeIndexList[i].valueType = formData.value.compositeIndexList[i].valueType.join()
          for (let j = 0; j < formData.value.compositeIndexList[i].data.length; j++) {
            formData.value.compositeIndexList[i].data[j].difference = formData.value.compositeIndexList[i].data[j].difference ? 1 : 0
          }
        }
        // 处理符号和字母
        for (let i = 0; i < formData.value.compositeIndexList.length; i++) {
          for (let j = 0; j < formData.value.compositeIndexList[i].data.length; j++) {
            formData.value.compositeIndexList[i].data[j].replaceStr = letterList()[j].name
          }
          formData.value.compositeIndexList[i].formula = letterList()[0].name + formData.value.compositeIndexList[i].formula.substr(1);
          for (let j = 0; j < formData.value.compositeIndexList[i].formula.length; j++) {
            if (j % 2 == 0 && j !== 0) {
              formData.value.compositeIndexList[i].formula = formData.value.compositeIndexList[i].formula.substr(0, j) + letterList()[j / 2].name + formData.value.compositeIndexList[i].formula.substr(j + 1);
            }
          }
        }
        delete formData.value.date
        if (formData.value.id) {
          editQueryListApi(formData.value).then(res => {
            if (res.data.code === 200) {
              getSearchListFun()
              dialogFormVisible.value = false
            } else {
              ElMessage({
                type: 'error',
                message: res.data.message
              })
            }
          }).catch(err => {
            console.log(err);
          })
        } else {
          saveQueryListApi(formData.value).then(res => {
            if (res.data.code === 200) {
              getSearchListFun()
              dialogFormVisible.value = false
            } else {
              ElMessage({
                type: 'error',
                message: res.data.message
              })
            }
          }).catch(err => {
            console.log(err);
          })
        }
      }
    })
  }
}


// 点击删除
const delClick = function (id, row) {
  ElMessageBox.confirm('', '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '删除查询方案',
    message: h('p', null, [
      h('img', {
        src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
        style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
      }, ''),
      h('span', {style: 'color:#595959;'}, '请确认是否要删除此查询方案，删除后数据不可恢复'),
    ])
  }).then(() => {
    delQueryListApi(id).then(res => {
      if (res.data.code === 200) {
        getSearchListFun()
        ElMessage({
          type: 'success',
          message: '删除成功'
        });
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '已取消删除'
    });
  });
}

// 点击打开
const openClick = function (id, row) {
  router.push({
    path: '/admin/compoundQueryLocal',
    query: {id: id}
  })
}


// 获取树形结构列表----------------------------------------------------------------------
// 左侧项目树元素
let treeRef = ref()
//获取项目树
const getProjectFun = async function (node, resolve) {
  await getTreeListApi(qs.stringify({
    parentId: node.data.id ? node.data.id : 0,
    type: node.data.type ? node.data.type : 1
  })).then(res => {
    if (res.data.code === 0) {
      return resolve(res.data.result)
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 项目树配置
let props = {
  label: 'name',
  children: 'subList',
  isLeaf: (data, node) => {
    if (node.data.type === 3) {
      return true
    }
  }
}
// 树级列表
const treeList = (node, resolve) => {
  getProjectFun(node, resolve)
}


// 弹窗的参数操作------------------------------------
// 符号点击
const symbolClick = function (index, index_child, e) {
  let str = formData.value.compositeIndexList[index].formula
  formData.value.compositeIndexList[index].formula = str.substr(0, 2 * index_child - 1) + e + str.substr(2 * index_child);
}
// 添加行
const addCompound = function () {
  formData.value.compositeIndexList.push({
    formula: 'A',
    name: '',
    chatType: '',
    valueType: [],
    data: [{
      val: "",
      type: '',
      unit: "",
      typeId: '',
      typeName: "",
      replaceStr: "A",
      difference: '',
      deviceId: '',
      deviceName: '',
    }]
  })
}
// 删除行
const delCompound = function (index) {
  ElMessageBox.confirm('', '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '删除复合指标',
    message: h('p', null, [
      h('img', {
        src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
        style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
      }, ''),
      h('span', {style: 'color:#595959;'}, '请确认是否要删除此复合指标，删除后数据不可恢复'),
    ])
  }).then(() => {
    formData.value.compositeIndexList.splice(index, 1)
    ElMessage({
      type: 'success',
      message: '删除成功'
    });
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '已取消删除'
    });
  });
}
// 添加参数值
const addControl = function (index) {
  formData.value.compositeIndexList[index].formula = formData.value.compositeIndexList[index].formula.concat('+D')
  formData.value.compositeIndexList[index].data.push({
    val: "",
    type: '',
    unit: "",
    typeId: '',
    typeName: "",
    replaceStr: "A",
    difference: '',
    deviceId: '',
    deviceName: '',
  })
  console.log(formData.value.compositeIndexList)
}
// 删除参数值
const delControl = function (index, index_child) {
  ElMessageBox.confirm('', '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '删除参数',
    message: h('p', null, [
      h('img', {
        src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
        style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
      }, ''),
      h('span', {style: 'color:#595959;'}, '请确认是否要删除此参数，删除后数据不可恢复'),
    ])
  }).then(() => {
    let str = formData.value.compositeIndexList[index].formula
    let strA
    let strB
    if (index_child !== 0) {
      strA = str.slice(0, 2 * index_child - 1)
      strB = str.slice(2 * index_child + 1, str.length)
    } else {
      strA = ''
      strB = str.slice(2, str.length)
    }
    formData.value.compositeIndexList[index].formula = strA.concat(strB)
    formData.value.compositeIndexList[index].data.splice(index_child, 1)
    ElMessage({
      type: 'success',
      message: '删除成功'
    });
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '已取消删除'
    });
  });
}
//node的点击
let elSelectTable = ref()
const clickUnitTreeNodeChild = function (data, index, index_child) {
  if (data.type == 3) {
    formData.value.compositeIndexList[index].data[index_child].typeName = data.name
    formData.value.compositeIndexList[index].data[index_child].typeId = data.id
    formData.value.compositeIndexList[index].data[index_child].deviceName = data.equipmentName
    formData.value.compositeIndexList[index].data[index_child].unit = data.unitName
    formData.value.compositeIndexList[index].data[index_child].deviceId = data.equipmentId
    elSelectTable.value[index_child].blur();
  } else {
    // ElMessage({
    //   type: 'info',
    //   message: '当前选择不是指标选项'
    // });
  }
}


// 下拉搜索
const filterMethod = function (val) {
  treeRef.value[0].filter(val)
}
const filterNode = (value, data) => {
  if (!value) return true
  return data.name.includes(value)
}

onMounted(() => {
  getSearchListFun()
})

</script>

<style lang="less" scoped>
</style>
