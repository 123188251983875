<template>
  <div class="powerStationMap">
    <div id="mapGl"></div>
    <div class="backBox" @click="emit('isShowMapFun', 1);">
      <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/powerStation/mapBack.png">
      <span>返回上一层</span>
    </div>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {onMounted, reactive, ref} from "vue"
import './powerStationMap.less'


const emit = defineEmits(['isShowMapFun']);


onMounted(() => {
  var map = new BMap.Map('mapGl');
  var point = new BMap.Point(116.443, 39.935);
  map.centerAndZoom(point, 13);
  map.enableScrollWheelZoom();
  // map.setTilt(70);

  var citys = [
    {
      name: '北京大学',
      img: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/powerStation/mapIcon1.png'
    }, {
      name: '清华大学',
      img: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/powerStation/mapIcon1.png'
    }, {
      name: '中央财经大学',
      img: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/powerStation/mapIcon2.png'
    }, {
      name: '北京交通大学',
      img: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/powerStation/mapIcon2.png'
    }, {
      name: '北京工业大学',
      img: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/powerStation/mapIcon1.png'
    }, {
      name: '北京邮电大学',
      img: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/powerStation/mapIcon1.png'
    }, {
      name: '北京理工大学',
      img: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/powerStation/mapIcon1.png'
    }, {
      name: '北京航空航天大学',
      img: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/powerStation/mapIcon1.png'
    }, {
      name: '中国传媒大学',
      img: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/powerStation/mapIcon1.png'
    }];

  var cityGeo = new BMap.Geocoder();
  for (let i = 0; i < citys.length; i++) {
    cityGeo.getPoint(citys[i].name, function (point) { // 地址解析获取对应经纬度
      var pt = new BMap.Point(point.lng, point.lat);
      var icon = new BMap.Icon(citys[i].img, new BMap.Size(64, 71));
      var marker = new BMap.Marker(pt, {
        size: 40,
        icon: icon
      });
      map.addOverlay(marker);
      var opts = {
        width: 100,     // 信息窗口宽度
        height: 110,     // 信息窗口高度
        // title: "故宫博物院", // 信息窗口标题
        message: "这里是故宫",
        enableMessage: false
      }
      var sContent = `<div>
      <div style="display: flex;align-items: center">
        <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/powerStation/mapIcon3.png"
             style="width: 24px;height: 24px;">
        <div style="margin-left: 8px">
          <p style="font-weight: bold;">杭州xx站点</p>
          <p style="font-weight: 400;font-size: 10px;color: #969A9F;">2024-09-26 20:14:34</p>
        </div>
      </div>
      <div>
        <p>在线设备：56</p>
        <p>离线设备：0</p>
        <p>告警设备：0</p>
      </div>
    </div>`;
      var infoWindow = new BMap.InfoWindow(sContent, opts);  // 创建信息窗口对象
      marker.addEventListener("click", function () {
        map.openInfoWindow(infoWindow, point); //开启信息窗口
      });
    });
  }

  map.setMapStyleV2({
    styleId: 'ce50bf9cdb110ff8e9666da356ba4ff8'
  })

  // ce50bf9cdb110ff8e9666da356ba4ff8
})

</script>

<style lang="less" scoped>
#mapGl {
  width: 100%;
  height: 100%;
}
</style>
