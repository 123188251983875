<template>
  <div class="powerStationEquipment">
    <div class="title">
      <p>
        <span @click="goBack">光伏电站</span>
        <span> / </span>
        <!--        <span>电站</span>-->
        <!--        <span>电站数据</span>-->
        <span>设备</span>
        <span> / </span>
        <span>设备详情</span>
      </p>
      <p class="tit">
        <el-icon @click="goBack">
          <Back/>
        </el-icon>
        设备详情
      </p>
    </div>
    <div class="content">
      <div class="headBox">
        <div class="headBoxItem">
          <div class="leftBox">
            <p class="name">逆变器</p>
            <p class="offline online">
              <span></span>
              <span>在线</span>
            </p>
          </div>
          <div class="rightBox">
            <el-button type="primary" @click="editClick">编辑</el-button>
            <el-button class="editBtn" @click="lookDetailClick">查看</el-button>
            <el-button class="delBtn" @click="delClick">删除</el-button>
          </div>
        </div>
        <div class="headBoxItem">
          <p>
            <span>产品型号：</span>
            <span></span>
          </p>
          <p>
            <span>协议类型：</span>
            <span></span>
          </p>
          <p>
            <span>通讯地址：</span>
            <span></span>
          </p>
          <p>
            <span>创建人：</span>
            <span></span>
          </p>
        </div>
        <div class="headBoxItem">
          <div>
            <span>控制：</span>
            <span class="operator">拉闸</span>
            <span class="operator">合闸</span>
          </div>
          <div>
            <span>控制策略：</span>
            <span class="operator">控制策略方案</span>
          </div>
        </div>
      </div>
      <div class="contain">
        <el-tabs v-model="activeName" class="demo-tabs" @tab-change="handleTabClick">
          <el-tab-pane :name="1" label="数据监测"></el-tab-pane>
          <el-tab-pane :name="2" label="设备告警"></el-tab-pane>
        </el-tabs>
        <!--数据监测内容-->
        <div v-show="activeName===1" class="content">
          <div class="titleBox">
            <div>最后数据的时间: {{ lastTime }} &nbsp;
              <p style="display: inline-block;cursor: pointer" @click="getListFun">
                <el-icon style="transform: translateY(2px);color: #1890FF;">
                  <RefreshRight/>
                </el-icon>&nbsp;
                <span style="color:#1890FF;">刷新</span>
              </p>
            </div>
            <div class="rightTitleBox">
              <p>产品型号：{{ productModel }}</p>
            </div>
          </div>
          <div class="echartsTabBox">
            <div v-for="(item,index) in monitoringList" :key="index">
              <p class="groupName">{{ item.name }}</p>
              <div v-for="(item_t,index_t) in item.list" :key="index_t" class="parentClass">
                <div class="echartsBox" style="position:relative;" @mouseenter="drawLineItem(index,index_t)">
                  <div class="leftDetail">
                    <!--{{String(item.title).replace(/\s/g,"&ensp;")}}-->

                    <el-tooltip :content="item_t.titleName" placement="top">
                      <div class="bigClass">{{ String(item_t.titleName).replace(/\s/g, "&ensp;") }}</div>
                    </el-tooltip>
                    <div class="signClass">
                      <span class="numberValueClass">{{ item_t.value }}</span>
                      <span style="margin-left: 5px;">{{ item_t.unitName }}</span>
                    </div>
                  </div>
                  <div class="rightDetail">
                    <div :id="forId(index)" :class="'echrtsClass'+'_'+index+'_'+index_t" class="echrtsClass"></div>
                  </div>
                  <div class="largeDetail" @click="dataSearch(item_t.id,item_t.dictKey)">
                    <!--                <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/%E6%89%A9%E5%A4%A7.svg"-->
                    <!--                     @click="dataSearch(item_t.id)"/>-->
                    查看详情
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--设备告警内容-->
        <div v-show="activeName===2" class="tableBox">
          <el-table v-loading="loading" :data="tableWarnData" style="width: 100%;" table-layout="fixed">
            <template #empty>
              <el-empty class="emptyClass" description="暂无数据信息"
                        image="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
                <p>您可以在此页面新建设备信息</p>
              </el-empty>
            </template>
            <el-table-column label="级别" prop="name"></el-table-column>
            <el-table-column label="发生时间" prop="memberName">
              <template #default="scope">
                <span>{{}}</span>
              </template>
            </el-table-column>
            <el-table-column label="结束时间" prop="customerName"></el-table-column>
            <el-table-column label="类别" prop="createTime"></el-table-column>
            <el-table-column label="处理进度" prop="beforeContent">
              <template #default="scope">
                <p class="cont">{{ scope.row.beforeContent }}</p>
              </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="160px">
              <template #default="scope">
                <div class="opacity">
                  <el-tooltip content="结束报警">
                    <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/powerStation/operaIcon6.png"
                         @click="endClick(scope.row.id)">
                  </el-tooltip>
                  <el-tooltip content="转工单">
                    <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/powerStation/operaIcon7.png"
                         @click="wordClick(scope.row.id)">
                  </el-tooltip>
                  <el-tooltip content="查看当时值">
                    <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/powerStation/operaIcon1.png"
                         @click="lookValue(scope.row.id)">
                  </el-tooltip>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="pag">
            <el-pagination v-show="tableWarnData.length > 0" v-model:page-size="pageSize"
                           :page-sizes="[10, 20, 50, 100]"
                           :total="total" background
                           layout="total,sizes,prev, pager, next" @size-change="handleSizeChange"
                           @current-change="pageChange"/>
          </div>
        </div>
      </div>
    </div>
    <!--数据查询-->
    <el-dialog v-model="dialogVisible" title="数据查询" width="70%">
      <div class="searchHead">
        <div>
          <span>日期： </span>
          <el-select v-model="timeFlag" clearable style="width: 220px;margin-right: 15px"
                     @change="timeFlagSelect">
            <el-option label="自定义时间段" value="-1"></el-option>
            <el-option label="近1小时" value="1H"></el-option>
            <el-option label="近12小时" value="12H"></el-option>
            <el-option label="近一天" value="1D"></el-option>
            <el-option label="近3天" value="3D"></el-option>
            <el-option label="近1周" value="1W"></el-option>
            <el-option label="本周" value="THIS_WEEK"></el-option>
            <el-option label="本月" value="1M"></el-option>
            <el-option label="本季" value="1Q"></el-option>
          </el-select>
          <el-date-picker v-model="dateTime" end-placeholder="结束时间" popper-class="date-style" range-separator="→"
                          start-placeholder="开始时间" style="margin-right: 15px"
                          type="datetimerange" value-format="YYYY-MM-DD HH:mm:ss">
          </el-date-picker>
          <el-input v-if="timeFlag!='1H'" v-model="frequency" :disabled="true" placeholder="请输入采样频率"
                    style="width: 40%;" type="number">
            <template #append>
              <el-select v-model="timeUnit" style="width: 90px;">
                <el-option label="分钟" value="m"/>
                <el-option label="小时" value="h"/>
                <el-option label="天" value="d"/>
                <el-option label="月" value="n"/>
                <el-option label="年" value="y"/>
              </el-select>
            </template>
          </el-input>
        </div>

        <el-button class="searchButton" style="text-align: right;" type="primary" @click="searchFun">查询</el-button>
      </div>
      <div class="chart" style="height: 400px"></div>
      <template #footer>
				<span class="dialog-footer">
		    		<el-button class="resetButton" @click="dialogVisible = false">关闭</el-button>
      			</span>
      </template>
    </el-dialog>
    <!--新增设备弹窗-->
    <el-dialog v-model="dialogFormVisible" :close-on-click-modal="false" title="编辑设备" width="460">
      <div class="liPox">
        <span>设备名称：</span>
        <el-input v-model="formData.equipmentName"></el-input>
      </div>
      <div class="liPox">
        <span>关联设备：</span>
        <el-select v-model="formData.associationEquipment" :teleported="false" class="planBox">
          <el-option v-for="(item,index) in equipmentList" :key="index" :label="item.name"
                     :value="item.value"></el-option>
        </el-select>
      </div>
      <template #footer>
				<span class="dialog-footer">
        <el-button class="cancle" @click="dialogFormVisible=false">取消</el-button>
          <el-button class="confrim" type="primary" @click="save()">保存</el-button>
      </span>
      </template>
    </el-dialog>
    <!--查看设备详情弹窗-->
    <el-dialog v-model="dialogLookVisible" :close-on-click-modal="false" title="查看详情" width="60%">
      <div class="nameBox">
        <p class="name">逆变器</p>
        <p class="offline online">
          <span></span>
          <span>在线</span>
        </p>
      </div>
      <div class="listBox">
        <p>产品型号：</p>
        <p>协议类型：</p>
        <p>通讯地址：</p>
        <p>创建人：</p>
        <p>产品类型：</p>
        <p>电网费率：</p>
        <p>网关：</p>
        <p>规约类型：</p>
        <p>波特率：</p>
        <p>用户类型：</p>
        <p>电压变比：</p>
        <p>端口：</p>
        <p>电流变比：</p>
        <p>是否启用：</p>
        <p>备注：</p>
      </div>
    </el-dialog>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {h, nextTick, onMounted, reactive, ref} from "vue"
import './powerStationEquipment.less'
import {Back} from '@element-plus/icons-vue'
import {ElLoading, ElMessage, ElMessageBox} from "element-plus";
import {getList, getListLocalLink} from "@/api/modules/dataMonitoring";
import qs from "qs";
import echarts from "echarts";
import moment from "moment";
import {getDataListApi, getEchartsDataApi, getEquipmentListApi, getTableDataApi} from "@/api/modules/dataSearch";
import {useRouter} from "vue-router/dist/vue-router";

let router = useRouter()
// 选择展示内容
let activeName = ref(1)


// 设备操作-------------------------------------------------------------------------------------
// 编辑
let dialogFormVisible = ref(false) //新增设备弹窗
let equipmentList = ref([]) //关联设备列表
// 新增设备表单数据
let formData = ref({
  equipmentName: '',
  associationEquipment: '',
})
const editClick = function () {
  dialogFormVisible.value = true
}
// 点击保存
const save = function () {

}
// 查看
let dialogLookVisible = ref(false)
const lookDetailClick = function () {
  dialogLookVisible.value = true
}
// 删除
const delClick = function () {
  ElMessageBox.confirm('', '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '删除',
    message: h('p', null, [
      h('img', {
        src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
        style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
      }, ''),
      h('span', {style: 'color:#595959;'}, '请确认是否要删除此设备，删除后数据不可恢复'),
    ])
  }).then(() => {
    // delSearchListApi(id).then(res => {
    //   if (res.data.code === 200) {
    //     getSearchListFun()
    //     ElMessage({
    //       type: 'success',
    //       message: '删除成功'
    //     });
    //   } else {
    //     ElMessage({
    //       type: 'error',
    //       message: res.data.message
    //     })
    //   }
    // }).catch(err => {
    //   console.log(err);
    // })
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '已取消删除'
    });
  });
}


// 数据监测-------------------------------------------------------------------------------------
// 加载的动画
let loadingAdd = ref()
const openFullScreen = function () {
  loadingAdd.value = ElLoading.service({
    lock: true,
    text: '',
    background: 'rgba(0, 0, 0, 0.1)'
  })
}

let productModel = ref()
let lastTime = ref()
let monitoringList = ref([])
//柱状图id(②数据监测)
const forId = function (index) {
  return 'geo_' + index;
}
const getListFun = function () {
  openFullScreen()
  router.currentRoute.value.query.isLocal == 2 ? getListLocalLink(qs.stringify({
    id: router.currentRoute.value.query.id
  })).then(res => {
    loadingAdd.value.close();
    //console.log(res);
    if (res.data.ok) {
      lastTime.value = res.data.result.lastTime
      productModel.value = res.data.result.productModel
      monitoringList.value = res.data.result.groupList
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  }) : getList(qs.stringify({
    id: router.currentRoute.value.query.id
  })).then(res => {
    loadingAdd.value.close();
    //console.log(res);
    if (res.data.ok) {
      lastTime.value = res.data.result.lastTime
      productModel.value = res.data.result.productModel
      monitoringList.value = res.data.result.groupList

      // monitoringList.value = res.data.result.list
      // monitoringList.value.forEach(item => {
      //   item.valueList2 = []
      // })
      // monitoringList.value.forEach(item => {
      //   var arr = []
      //   if (item.valueList.length > 0) {
      //     item.valueList.forEach(items => {
      //       arr.push(item.maxValue * 0.02)
      //       item.valueList2 = arr
      //     })
      //   }
      // })
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
const drawLine = function (idx1, idx) {
  var echarts = require('echarts');
  if (idx !== undefined) {
    let myChart = echarts.init(document.querySelector(`.echrtsClass_${idx1}_${idx}`)); //
    myChart.clear(); //切换折线图前要先清除
    myChart.setOption({
      tooltip: {
        trigger: 'axis', // axis   item   none三个值
        show: true,
        /*formatter: function(params) {
    return(
     params[0].name +
     "<br/>" +
     "<span style='display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:rgba(24,144,255)'></span>" +
     params[0].seriesName + "<span style='display:inline-block;margin-right:5px'></span>" + params[0].data);
   }*/
        formatter: function (params) {
          return (params[0].data);
        }
      },
      /*图表位置*/
      grid: {
        x: 0, //左留白
        y: 10, //上留白
        x2: 10, //右留白
        y2: 0 //下留白
      },
      title: {},
      legend: {
        data: [],
        //data: ['销量', 'xxm'],
      },
      //x轴
      xAxis: {
        data: monitoringList.value[idx1].list[idx].valueList,
        axisLine: {
          lineStyle: {
            color: '#ccc',
            width: 0 //x轴线
          }
        },
        //x轴线中的小竖的分割线
        axisTick: {
          show: false
        },
        show: false
      },
      //y轴
      yAxis: {
        axisLine: {
          lineStyle: {
            color: '#999',
          }
        },
        axisTick: {
          show: false
        },
        splitLine: {
          show: false
        }
      },
      series: [{
        name: monitoringList.value[idx1].list[idx].titleName,
        type: 'line',
        // barGap: '-100%',
        stack: 'xxm',
        data: monitoringList.value[idx1].list[idx].valueList,
        color: '#457DFC',
      }]
    });
    myChart.resize();
    window.onresize = () => {
      myChart.resize();
    }
  } else {
    var roseCharts = document.querySelectorAll('.echrtsClass');
    for (var i = 0; i < roseCharts.length; i++) {
      let myChart = echarts.init(roseCharts[i]); //
      myChart.clear(); //切换折线图前要先清除
      myChart.setOption({
        tooltip: {
          trigger: 'axis', // axis   item   none三个值
          show: true,
          /*formatter: function(params) {
      return(
       params[0].name +
       "<br/>" +
       "<span style='display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:rgba(24,144,255)'></span>" +
       params[0].seriesName + "<span style='display:inline-block;margin-right:5px'></span>" + params[0].data);
     }*/
          formatter: function (params) {
            return (params[0].data);
          }
        },
        /*图表位置*/
        grid: {
          x: 0, //左留白
          y: 10, //上留白
          x2: 10, //右留白
          y2: 0 //下留白
        },
        title: {},
        legend: {
          data: [],
          //data: ['销量', 'xxm'],
        },
        //x轴
        xAxis: {
          // data: ["10-01", "10-02", "10-03", "10-04", "10-05", "10-06", "10-01", "10-02", "10-03", "10-04", "10-05", "10-06",],
          data: monitoringList.value[i].valueList,
          axisLine: {
            lineStyle: {
              color: '#ccc',
              width: 0 //x轴线
            }
          },
          //x轴线中的小竖的分割线
          axisTick: {
            show: false
          },
          show: false
        },
        //y轴
        yAxis: {
          axisLine: {
            lineStyle: {
              color: '#999',
            }
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: false
          }
        },
        series: [{
          name: monitoringList.value[i].titleName,
          type: 'line',
          // barGap: '-100%',
          stack: 'xxm',
          data: monitoringList.value[i].valueList,
          color: '#457DFC',
        }]
      });

      myChart.resize();
      window.onresize = () => {
        myChart.resize();
      }
    }
  }

}
const drawLineItem = function (idx1, idx) {
  nextTick(() => {
    drawLine(idx1, idx)
  })
}

/*数据查询*/
let dialogVisible = ref(false)
const dataSearch = function (id, key) {
  equipmentArr.value[0].dictIds = []
  equipmentArr.value[0].dictKeys = []
  dialogVisible.value = true
  equipmentArr.value[0].dictIds.push(id)
  equipmentArr.value[0].dictKeys.push(key)
  plan.value = null
  timeFlag.value = '1H'
  dateTime.value = [moment().subtract(1, "hours").format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
  frequency.value = 1
  timeUnit.value = 'm'
  yom.value = []
  echartsData.value = []
  echartsDataX.value = []
  tableData.value = []
  getTableDataFun()
}
// 搜索
let plan = ref() //选择的搜索方案
let timeFlag = ref('1H') //日期快捷选择
let dateTime = ref([moment().subtract(1, "hours").format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]) //搜索日期
let frequency = ref(1) //频率
let timeUnit = ref('m') //频率单位
let yom = ref([]) //同环比
let equipmentArr = ref([{
  deviceId: router.currentRoute.value.query.id,
  dictIds: [],
  dictKeys: [],
}]) //搜索的设备
// 日期快捷选择事件
const timeFlagSelect = function () {
  if (timeFlag.value === '-1') {
    dateTime.value = []
  } else if (timeFlag.value === '1H') {
    dateTime.value = [moment().subtract(1, "hours").format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
    frequency.value = 1
    timeUnit.value = 'm'
  } else if (timeFlag.value === '12H') {
    dateTime.value = [moment().subtract(12, "hours").format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
    frequency.value = 5
    timeUnit.value = 'm'
  } else if (timeFlag.value === '1D') {
    dateTime.value = [moment().subtract(1, "days").format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
    frequency.value = 10
    timeUnit.value = 'm'
  } else if (timeFlag.value === '3D') {
    dateTime.value = [moment().subtract(3, "days").format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
    frequency.value = 30
    timeUnit.value = 'm'
  } else if (timeFlag.value === '1W') {
    dateTime.value = [moment().subtract(1, "weeks").format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
    frequency.value = 1
    timeUnit.value = 'h'
  } else if (timeFlag.value === 'THIS_WEEK') {
    dateTime.value = [moment().startOf('isoWeek').format('YYYY-MM-DD HH:mm:ss'), moment().endOf('isoWeek').format('YYYY-MM-DD HH:mm:ss')]
    frequency.value = 1
    timeUnit.value = 'h'
  } else if (timeFlag.value === '1M') {
    dateTime.value = [moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'), moment().format('YYYY-MM-DD HH:mm:ss')]
    frequency.value = 2
    timeUnit.value = 'h'
  } else if (timeFlag.value === '1Q') {
    dateTime.value = [moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).startOf('quarter').format("YYYY-MM-DD HH:mm:ss"), moment().format('YYYY-MM-DD HH:mm:ss')]
    frequency.value = 6
    timeUnit.value = 'h'
  }
}
// 获取图表数据
let titleName = ref()
let echartsData = ref([])
let echartsDataX = ref([])
const getEchartsDataFun = async function () {
  echartsDataX.value = []
  echartsData.value = []
  let data = {
    startTime: dateTime.value ? dateTime.value[0] : '',
    endTime: dateTime.value ? dateTime.value[1] : '',
    frequency: frequency.value,
    timeUnit: timeUnit.value,
    params: equipmentArr.value,
    yom: yom.value[0]
  }
  // 计算同环比时间
  if (yom.value && yom.value[0] == 1) {
    data.lastStartTime = moment(data.startTime).subtract(1, 'years').format("YYYY-MM-DD HH:mm:ss")
    data.lastEndTime = moment(data.endTime).subtract(1, 'years').format("YYYY-MM-DD HH:mm:ss")
  } else if (yom.value && yom.value[0] == 2) {
    if (timeFlag.value === '1M') {
      data.lastStartTime = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD HH:mm:ss')
      data.lastEndTime = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD HH:mm:ss')
    } else if (timeFlag.value === '1Q') {
      data.lastStartTime = moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).subtract(1, 'quarter').startOf('quarter').format("YYYY-MM-DD HH:mm:ss")
      data.lastEndTime = moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).subtract(1, 'quarter').endOf('quarter').format("YYYY-MM-DD HH:mm:ss")
    } else {
      let timeLength = moment(data.endTime).diff(data.startTime, 'second')
      data.lastStartTime = moment(data.startTime).subtract(timeLength, 'second').format('YYYY-MM-DD HH:mm:ss')
      data.lastEndTime = moment(data.endTime).subtract(timeLength, 'second').format('YYYY-MM-DD HH:mm:ss')
    }
  }
  let flag = false
  for (let i = 0; i < data.params.length; i++) {
    if (!data.params[i].deviceId || data.params[i].dictKeys) {
      flag = true
    }
    delete data.params[i].dictIds
  }
  if (data.startTime && data.endTime && data.frequency && data.timeUnit) {
    openFullScreen()
    await getEchartsDataApi(data).then(res => {
      loadingAdd.value.close()
      if (res.data.code === 0) {
        echartsDataX.value = res.data.result.xAxis
        echartsData.value = res.data.result.yAxis
        for (let i = 0; i < echartsData.value.length; i++) {
          echartsData.value[i].name = echartsData.value[i].deviceName + '-' + echartsData.value[i].titleName
          echartsData.value[i].type = 'line'
          echartsData.value[i].lineStyle = {
            width: 1
          }
          echartsData.value[i].symbol = 'circle'
          // echartsData.value[i].symbol = 'none'
          echartsData.value[i].sampling = 'lttb'
          for (let j = 0; j < echartsData.value[i].data.length; j++) {
            echartsData.value[i].data[j] = Number(echartsData.value[i].data[j]).toFixed(2)
          }
        }
        nextTick(() => {
          chartLoad()
        })
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message
        })
      }
    }).catch(err => {
      console.log(err);
    })
  } else {
    ElMessage({
      type: 'info',
      message: '请选择筛选条件'
    })
  }
}
// 获取表格数据
let tableData = ref([])
let pageData = ref([])
const getTableDataFun = async function () {
  pageData.value = []
  tableData.value = []
  for (let i = 0; i < equipmentArr.value.length; i++) {
    pageData.value.push({
      pageNum: 1,
      pageSize: 10
    })
    let data = {
      startTime: dateTime.value ? dateTime.value[0] : '',
      endTime: dateTime.value ? dateTime.value[1] : '',
      pageNum: pageData.value[i].pageNum,
      pageSize: 1000,
      frequency: frequency.value,
      timeUnit: timeUnit.value,
      params: [equipmentArr.value[i]]
    }
    // 计算同环比时间
    if (yom.value && yom.value[0] == 1) {
      data.lastStartTime = moment(data.startTime).subtract(1, 'years').format("YYYY-MM-DD HH:mm:ss")
      data.lastEndTime = moment(data.endTime).subtract(1, 'years').format("YYYY-MM-DD HH:mm:ss")
      console.log(data.lastStartTime, data.lastEndTime)
    } else if (yom.value && yom.value[0] == 2) {
      if (timeFlag.value === '1M') {
        let lastStartTime = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD HH:mm:ss')
        let lastEndTime = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD HH:mm:ss')
      } else if (timeFlag.value === '1Q') {
        data.lastStartTime = moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).subtract(1, 'quarter').startOf('quarter').format("YYYY-MM-DD HH:mm:ss")
        data.lastEndTime = moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).subtract(1, 'quarter').endOf('quarter').format("YYYY-MM-DD HH:mm:ss")
      } else {
        let timeLength = moment(data.endTime).diff(data.startTime, 'second')
        data.lastStartTime = moment(data.startTime).subtract(timeLength, 'second').format('YYYY-MM-DD HH:mm:ss')
        data.lastEndTime = moment(data.endTime).subtract(timeLength, 'second').format('YYYY-MM-DD HH:mm:ss')
      }
    }
    let flag = false
    for (let i = 0; i < data.params.length; i++) {
      if (!data.params[i].deviceId || (data.params[i].dictIds.length === 0)) {
        flag = true
      }
    }
    if (data.startTime && data.endTime && data.frequency) {
      openFullScreen()
      await getTableDataApi(data).then(res => {
        loadingAdd.value.close()
        if (res.data.code === 0) {
          echartsDataX.value = []
          echartsData.value = [{
            data: []
          }]
          if (res.data.result.list.length > 0) {
            var arr = res.data.result.list[0].dataMetricsVO.reverse()
            for (var i = 0; i < arr.length; i++) {
              echartsDataX.value.push(arr[i].ts)
              echartsData.value[0].data.push(arr[i].metricDataList[0].val)
            }
            for (let i = 0; i < echartsData.value.length; i++) {
              echartsData.value[i].name = arr[i].metricDataList[0].titleName
              echartsData.value[i].type = 'line'
              echartsData.value[i].lineStyle = {
                width: 1
              }
              echartsData.value[i].symbol = 'circle'
              echartsData.value[i].sampling = 'lttb'
              for (let j = 0; j < echartsData.value[i].data.length; j++) {
                echartsData.value[i].data[j] = Number(echartsData.value[i].data[j]).toFixed(2)
              }
            }
          }
          nextTick(() => {
            chartLoad()
          })
        } else {
          ElMessage({
            type: 'error',
            message: res.data.message
          })
        }
      }).catch(err => {
        console.log(err);
      })
    } else {
      ElMessage({
        type: 'info',
        message: '请选择筛选条件'
      })
    }
  }
}
// 查询
const searchFun = async function () {
  if (timeFlag.value == '1H') {
    getTableDataFun()
  } else {
    getEchartsDataFun()
  }
}
// 加载图表
const chartLoad = function () {
  var chartDom = document.querySelector('.chart');
  var myChart = echarts.init(chartDom);
  var option;

  option = {
    // toolbox: {
    //   feature: {
    //     dataZoom: {
    //       yAxisIndex: 'none'
    //     },
    //     restore: {},
    //     saveAsImage: {}
    //   }
    // },
    grid: {
      left: 50,
      right: 20
    },
    legend: {
      show: true,
      type: 'scroll'
    },
    tooltip: {
      trigger: 'axis', // axis   item   none三个值
      show: true
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: echartsDataX.value
    },
    yAxis: {
      type: 'value',
    },
    dataZoom: [{
      type: 'inside',
      start: 0,
      end: 100,
      disabled: false
    },
      {
        start: 0,
        end: 100
      }
    ],
    series: echartsData.value
  };
  myChart.clear()
  option && myChart.setOption(option);

  myChart.resize();
  window.onresize = () => {
    myChart.resize();
  }
}


// 设备报警----------------------------------------------------------------------------------------
// 分页
let pageNumber = ref(1)
let pageSize = ref(20)
let total = ref()
// 更改每页多少
const handleSizeChange = function (e) {
  pageSize.value = e
  getListFun()
}
// 跳转到某页
const pageChange = function (e) {
  pageNumber.value = e
  getListFun()
}
// 参数表格数据
let tableWarnData = ref([{}])
// 获取告警列表
let loading = ref(false)
const getWarnListFun = function () {
  // loading.value = true
  // getLogApi(qs.stringify({
  //   pageNumber: pageNumber.value,
  //   pageSize: pageSize.value,
  // })).then(res => {
  //   loading.value = false
  //   if (res.data.code === 0) {
  //     tableWarnData.value = res.data.result.list
  //     total.value = Number(res.data.result.total)
  //   } else {
  //     ElMessage({
  //       message: res.data.message,
  //       type: 'error'
  //     })
  //   }
  // }).catch(err => {
  //   console.log(err);
  // })
}


// 转工单
const wordClick = function () {

}


// 结束报警
const endClick = function () {
  ElMessageBox.confirm('', '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '结束报警',
    message: h('p', null, [
      h('img', {
        src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
        style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
      }, ''),
      h('span', {style: 'color:#595959;'}, '请确认是否要结束此报警，结束后数据不可恢复'),
    ])
  }).then(() => {
    // delSearchListApi(id).then(res => {
    //   if (res.data.code === 200) {
    //     getSearchListFun()
    //     ElMessage({
    //       type: 'success',
    //       message: '结束报警成功'
    //     });
    //   } else {
    //     ElMessage({
    //       type: 'error',
    //       message: res.data.message
    //     })
    //   }
    // }).catch(err => {
    //   console.log(err);
    // })
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '已取消结束报警'
    });
  });
}


onMounted(() => {
  getWarnListFun()
})
</script>

<style lang="less" scoped>
</style>
