<template>
  <div class="price">
    <!-- <div class="title">
      <p>
        <span>电费模板管理</span>
      </p>
    </div> -->
    <div class="content">
      <div class="searchBox">
        <div class="liBox">
          <label style="margin-right: 10px">模板名称</label>
          <el-input
            v-model="queryParams.name"
            prefix-icon="Search"
            clearable
            placeholder="请输入模板名称"
          ></el-input>
        </div>
        <div class="liBox">
          <el-button class="searchButton" type="primary" @click="handleQuery"
            >查询</el-button
          >
          <el-button class="searchButton resetButton" @click="resetQuery"
            >重置</el-button
          >
        </div>
      </div>
      <div class="contain">
        <el-button class="addBtn" type="primary" @click="handleAdd">
          <el-icon>
            <Plus />
          </el-icon>
          新建电费模板
        </el-button>
        <el-table v-loading="loading" :data="priceList">
          <template #empty>
            <el-empty
              class="emptyClass"
              description="暂无数据信息"
              image="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png"
            >
              <p>您可以在此页面新建电费模板</p>
            </el-empty>
          </template>
          <el-table-column label="电费模板名称" align="center" prop="name" />
          <el-table-column
            label="尖峰电价"
            align="center"
            prop="electricCharge"
          />
          <el-table-column label="峰电价" align="center" prop="peakPrice" />
          <el-table-column label="平电价" align="center" prop="flatPrice" />
          <el-table-column label="谷电价" align="center" prop="valleyPrice" />
          <el-table-column label="操作" align="center" width="150px">
            <template #default="scope">
              <div class="opacity">
                <p class="btn" @click="handleUpdate(scope.row)">修改</p>
                <p class="btn delClass" @click="handleDelete(scope.row)">
                  删除
                </p>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pag">
          <el-pagination
            v-model:page-size="queryParams.pageSize"
            :page-sizes="[10, 20, 50, 100]"
            :total="total"
            background
            layout="total,sizes,prev, pager, next"
            small
          />
        </div>
      </div>
    </div>

    <!--弹窗-->
    <el-dialog
      :title="title"
      v-model="open"
      :close-on-click-modal="false"
      width="500px"
      append-to-body
    >
      <el-form ref="priceRef" :model="form" :rules="rules" label-width="auto">
        <el-form-item v-if="isShow" label="项目" prop="projectId">
          <el-select
            v-model="form.projectId"
            placeholder="请选择项目"
            clearable
            filterable
            style="width: 100%"
          >
            <el-option
              v-for="item in formLists.projectList"
              :key="item.projectId"
              :label="item.projectName"
              :value="item.projectId"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="电费模板名称" prop="name">
          <el-input
            v-model="form.name"
            placeholder="请输入电费模板名称"
            clearable
          />
        </el-form-item>
        <el-form-item label="尖峰电价" prop="electricCharge">
          <el-input
            v-model="form.electricCharge"
            placeholder="请输入尖峰电价"
            clearable
          />
        </el-form-item>
        <el-form-item label="峰电价" prop="peakPrice">
          <el-input
            v-model="form.peakPrice"
            placeholder="请输入峰电价"
            clearable
          />
        </el-form-item>
        <el-form-item label="平电价" prop="flatPrice">
          <el-input
            v-model="form.flatPrice"
            placeholder="请输入平电价"
            clearable
          />
        </el-form-item>
        <el-form-item label="谷电价" prop="valleyPrice">
          <el-input
            v-model="form.valleyPrice"
            placeholder="请输入谷电价"
            clearable
          />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button class="cancle resetButton" @click="cancel">取消</el-button>
          <el-button class="confrim" type="primary" @click="submitForm"
            >保存</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, reactive, toRefs, h } from 'vue'
import './price.less'
import { ElMessage, ElMessageBox } from 'element-plus'
import {
  listPrice,
  getPrice,
  delPrice,
  addPrice,
  updatePrice,
} from '@/api/modules/price'
import { listLandlordProject } from '@/api/modules/landlord'

const priceList = ref([])
const open = ref(false)
const loading = ref(true)
const total = ref(0)
const title = ref('')
const priceRef = ref(null)

const isShow = ref(false)

const data = reactive({
  form: {},
  queryParams: {
    pageNumber: 1,
    pageSize: 10,
    tenantName: null,
  },
  rules: {
    projectId: [{ required: true, message: '请选择项目', trigger: 'change' }],
    name: [{ required: true, message: '请输入电费模板名称', trigger: 'blur' }],
    electricCharge: [
      { required: true, message: '请输入尖峰电价', trigger: 'blur' },
    ],
    peakPrice: [{ required: true, message: '请输入峰电价', trigger: 'blur' }],
    flatPrice: [{ required: true, message: '请输入平电价', trigger: 'blur' }],
    valleyPrice: [{ required: true, message: '请输入谷电价', trigger: 'blur' }],
  },
})

const { queryParams, form, rules } = toRefs(data)

const formLists = reactive({
  projectList: [],
})

/** 查询电费价格配置列表 */
function getList() {
  loading.value = true
  listPrice(queryParams.value).then((response) => {
    priceList.value = response.data.result.list
    total.value = Number(response.data.result.total)
    loading.value = false
  })
}

// 取消按钮
function cancel() {
  open.value = false
  reset()
}

// 表单重置
function reset() {
  form.value = {
    id: null,
    projectId: null,
    name: null,
    electricCharge: null,
    peakPrice: null,
    flatPrice: null,
    valleyPrice: null,
  }
  priceRef.value?.resetFields()
}

/** 搜索按钮操作 */
function handleQuery() {
  queryParams.value.pageNumber = 1
  getList()
}

/** 重置按钮操作 */
function resetQuery() {
  queryParams.value = {
    pageNumber: 1,
    pageSize: 10,
    name: null,
  }
  handleQuery()
}

/** 新建按钮操作 */
function handleAdd() {
  reset()
  listLandlordProject().then((res) => {
    formLists.projectList = res.data.result
    if (formLists.projectList.length === 1) {
      form.value.projectId = formLists.projectList[0].projectId
      isShow.value = false
    } else {
      isShow.value = true
    }
    open.value = true
    title.value = '新建电费模板'
  })
}

/** 修改按钮操作 */
function handleUpdate(row) {
  reset()
  listLandlordProject().then((res) => {
    formLists.projectList = res.data.result
    getPrice(row.id).then((res) => {
      form.value = res.data.result
      if (
        formLists.projectList.length === 1 &&
        form.value.projectId === formLists.projectList[0].projectId
      ) {
        isShow.value = false
      } else {
        isShow.value = true
      }
      open.value = true
      title.value = '修改电费模板'
    })
  })
}

/** 提交按钮 */
function submitForm() {
  priceRef.value.validate((valid) => {
    if (valid) {
      if (form.value.id != null) {
        updatePrice(form.value).then((response) => {
          if (response.data.code === 0) {
            ElMessage.success('修改成功')
            open.value = false
            getList()
          } else {
            ElMessage({
              type: 'error',
              message: response.data.message,
            })
          }
        })
      } else {
        addPrice(form.value).then((response) => {
          if (response.data.code === 0) {
            ElMessage.success('新建成功')
            open.value = false
            getList()
          } else {
            ElMessage({
              type: 'error',
              message: response.data.message,
            })
          }
        })
      }
    }
  })
}

/** 删除按钮操作 */
function handleDelete(row) {
  ElMessageBox.confirm('确认删除吗，删除后不可恢复，请谨慎操作', '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '删除电费价格配置',
    message: h('p', null, [
      h(
        'img',
        {
          src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
          style:
            'width:20px;height:20px;transform: translateY(4px);margin-right:8px;',
        },
        ''
      ),
      h(
        'span',
        {
          style: 'color:#595959;',
        },
        '请确认是否要删除此电费价格配置，删除后数据不可恢复'
      ),
    ]),
  })
    .then(function () {
      return delPrice(row.id)
    })
    .then((response) => {
      if (response.data.code === 0) {
        getList()
        ElMessage.success('删除成功')
      } else {
        ElMessage({
          type: 'error',
          message: response.data.message,
        })
      }
    })
    .catch(() => {
      ElMessage({
        message: '已取消删除',
        type: 'info',
      })
    })
}

getList()
</script>
